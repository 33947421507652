import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

type OverlayProps = {
  show?: boolean;
  error?: boolean;
  errorMessage?: string;
};

export function LoadingOverlay({ show, error, errorMessage }: OverlayProps) {
  return (
    <div className={'LoadOverlay' + (show ? '' : ' hide')}>
      {error ? (
        <div className="loading-error">
          <div className="load-error-icon">
            <span className="fas fa-exclamation-triangle"></span>
          </div>
          {errorMessage !== '' ? (
            <div className="error-title custom">{errorMessage}</div>
          ) : (
            <h2 className="error-title default">Load Error</h2>
          )}

          <button
            className="button inverted action-button"
            type="button"
            onClick={() => window.location.reload()}
          >
            Reload
          </button>
        </div>
      ) : (
        <>
          <span className="mr-2 animate-spin">
            <FontAwesomeIcon icon={faSpinner} />
          </span>
          <p>Loading...</p>
        </>
      )}
    </div>
  );
}
