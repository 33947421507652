import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const swalert = withReactContent(Swal);

export const ALERT_CONFIG = {
  customClass: {
    confirmButton: 'bg-primary rounded capitalize',
    cancelButton: 'bg-gray-500 rounded capitalize',
    actions: 'flex flex-row-reverse',
  },
};
