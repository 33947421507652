import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, SelectHTMLAttributes, forwardRef } from 'react';

type SelectFieldProps = SelectHTMLAttributes<HTMLSelectElement> & {
  classNameFieldSet?: string;
  label?: string;
  name: string;
  options: Record<string, string>[] | string[]; // { key: label } // [label, label, label]
  defaultLabel?: string;
  enableEmptyOption?: boolean;
  errorMessage?: string;
};

/*
 * Select, a dropdown for an array of options
 * forwardRef is used to pass the ref to the input field, since we are using ReactFormHook
 * @props:
 * extends InputHTMLAttributes<HTMLInputElement>
 * - options: the array of options // { key: label }[]
 * - label: the label of the input field
 * - classNameFieldSet: the class of the fieldset
 * - name: the name of the input field (required)
 */

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>((props, ref) => {
  const {
    id,
    name,
    required,
    options,
    defaultLabel,
    enableEmptyOption,
    classNameFieldSet = ' ',
    className = '',
    label,
    errorMessage,
    ...rest
  } = props;

  function populateOptionElements() {
    const elements: ReactNode[] = [];
    if (enableEmptyOption) {
      elements.push(
        <option key={0} value={''}>
          {defaultLabel ? defaultLabel : '- Select -'}
        </option>
      );
    }
    // transform the data into option elements
    for (const option of options) {
      if (typeof option === 'string') {
        elements.push(
          <option key={`select-option-${name}-${option}`} value={option}>
            {option}
          </option>
        );
        continue;
      }

      const key = Object.keys(option)[0];
      const label = Object.values(option)[0];

      elements.push(
        <option key={`select-option-${name}-${key}`} value={key}>
          {label}
        </option>
      );
    }
    return elements;
  }

  return (
    <fieldset className={`InputFieldBlock ${classNameFieldSet}`}>
      {label && (
        <label className="input-label" htmlFor={id ?? name}>
          {label}
          {required && ' *'}
        </label>
      )}
      <div className="relative">
        <select
          {...rest}
          ref={ref}
          className={'Select input-field ' + className}
          id={id ?? name}
          name={name}
          required={required}
        >
          {populateOptionElements()}
        </select>
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <FontAwesomeIcon icon={faChevronDown} className="h-5 w-5 text-gray-400 sm:h-4 sm:w-4" />
        </div>
      </div>
      {errorMessage && <p className=" text-red-500">{errorMessage}</p>}
    </fieldset>
  );
});

SelectField.displayName = 'SelectField';
