// We have this format of date '2024-03-14T14:57:21:139969' and we want to convert it to a readable format.
export function parseDateString(dateString: string): Date | null {
  // Split the string to get the main part and the milliseconds part
  const parts = dateString.split(':');
  if (parts.length !== 4) {
    console.error('Invalid date format');
    return null;
  }

  // Rebuild the date string correctly
  const mainPart = parts.slice(0, -1).join(':');
  let milliseconds = parts[parts.length - 1];

  // Ensure milliseconds are exactly three digits
  if (milliseconds.length > 3) {
    milliseconds = milliseconds.substring(0, 3);
  } else if (milliseconds.length < 3) {
    milliseconds = milliseconds.padEnd(3, '0');
  }

  // Create the final date string
  const fixedDateString = `${mainPart}.${milliseconds}`;

  // Create the Date object
  const dateObject = new Date(fixedDateString);

  // Check if the date is valid
  if (isNaN(dateObject.getTime())) {
    console.error('Invalid date');
    return null;
  }

  return dateObject;
}
