/*
CustomTable, a generalized table component

A CustomTable consists of the following:
	One or more IconButton components
*/

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import IconButton from './IconButton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

/*
props (component level arguments):

	fields: an array of dictionary for title and attribute names
		(e.g., [{'title': 'Result', 'attribute': 'result'}, {'title': 'Status', 'attribute': 'status'}])
		For any action icon in the last column, 'title' needs to be an empty string ("")
		and 'attribute' needs to be 'faIconName'
	content: an array of data model (object)
	columnWidths: an array of integer in % to indicate the width of each column
	sortable: a boolean to set if the table has one or more sortable columns
	sortableColumn: an array of boolean to indicate which columns are sortable
	components: an array of components used to render the columns data
	parentContext: a reference to the parent component
	supportsDrag: a boolean value: true if drag-and-drop should be allowed; false if not


state (component level variables):
	arrowStateArray: an array of boolean to keep track of sorting direction (ascending or descending)
	currentArray: an array of actual data
	prevSortedColumn: the index number of the previously sorted/clicked column for sorting
*/

function isReactElement(data) {
  let isObject = data !== null && typeof data === 'object';
  return (
    isObject &&
    data.hasOwnProperty('$$typeof') &&
    data['$$typeof'].toString() === 'Symbol(react.element)'
  );
}

class CustomTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arrowStateArray: [],
      currentArray: [],
      prevSortedColumn: -1,
    };
    this.checkAWSFileType = this.checkAWSFileType.bind(this);
  }

  componentDidMount() {
    this.initializeArrowState();
  }

  checkAWSFileType(fileName) {
    if (typeof fileName === 'string') {
      let split = fileName.split('?')[0].split('.');
      split = split[split.length - 1];
      if (split === 'pdf') {
        return 'pdf';
      }
      if (split === 'document' || split === 'msword' || split === 'doc') {
        return 'document';
      }
    }
    return null;
  }

  render() {
    if (this.props.supportsDrag && this.props.content.length > 0) {
      return (
        <DragDropContext onDragEnd={this.props.onDragEnd}>
          <table className="CustomTable">
            <thead>
              <tr>{this.getHeaderRow()}</tr>
            </thead>
            <Droppable droppableId={this.props.droppableId}>
              {function (provider, snapshot) {
                return (
                  <tbody ref={provider.innerRef}>
                    {this.getBodyRows()}
                    {provider.placeholder}
                  </tbody>
                );
              }.bind(this)}
            </Droppable>
          </table>
        </DragDropContext>
      );
    }
    return (
      <table className="CustomTable">
        <thead>
          <tr>{this.getHeaderRow()}</tr>
        </thead>
        <tbody>{this.getBodyRows()}</tbody>
      </table>
    );
  }

  initializeArrowState() {
    let arrowStateArray = [];
    for (let i = 0; i < this.props.fields.length; i++) {
      arrowStateArray.push(false);
    }
    this.setState({
      arrowStateArray: arrowStateArray,
    });
  }

  updateArrowState(index) {
    let currentStateArray = this.state.arrowStateArray;
    // user clicked on the previously clicked column again
    if (this.state.prevSortedColumn === index) {
      // flip the value at the clicked index to reverse the content
      for (let i = 0; i < currentStateArray.length; i++) {
        if (i === index) {
          currentStateArray[i] = !currentStateArray[i];
          break;
        }
      }
    } else {
      // clicked column is true, rest are false.
      for (let i = 0; i < currentStateArray.length; i++) {
        currentStateArray[i] = i === index;
      }
    }

    this.setState({
      arrowStateArray: currentStateArray,
    });
  }

  getArrow(index) {
    let sortableColumn = this.props.sortableColumn;
    if (sortableColumn[index]) {
      if (this.state.arrowStateArray[index]) {
        // down arrow
        return <i className="fas fa-caret-down header-caret-icon"></i>;
      }
      // up arrow
      return <i className="fas fa-caret-up header-caret-icon"></i>;
    }
    return null;
  }

  compareBy(index) {
    let context = this;
    return function (a, b) {
      let field = context.props.fields[index].attribute; // get the field name. (i.e. status, result, patientId)
      if (a[field] === null && b[field] === null) return 0;
      if (a[field] === null) return -1;
      if (b[field] === null) return 1;
      // handle numbers in String format
      if (!isNaN(a[field]) && !isNaN(b[field])) {
        return Number(a[field]) - Number(b[field]);
      }
      // handle dates in String format
      if (!isNaN(Date.parse(a[field])) && !isNaN(Date.parse(b[field]))) {
        return Date.parse(a[field]) - Date.parse(b[field]);
      }
      // handle strings
      return a[field] < b[field] ? -1 : 1;
    };
  }

  sort(index) {
    if (!this.props.sortable) return;
    if (this.props.sortableColumn !== null && this.props.sortableColumn[index] === false) {
      return;
    }

    let arrayCopy = [];
    // reverse the content if user taps on a column that's /just/ sorted
    if (this.state.prevSortedColumn === index) {
      arrayCopy = this.state.currentArray;
      arrayCopy.reverse();
    } else {
      // otherwise, sort the column again
      arrayCopy = this.props.content;
      arrayCopy.sort(this.compareBy(index));
      this.setState({
        prevSortedColumn: index,
        currentArray: arrayCopy,
      });
    }
    this.updateArrowState(index);
    // update the content of the table
    let parentContext = this.props.parentContext;
    parentContext.updateTableContent(parentContext, arrayCopy);
  }

  getHeaderRow() {
    let headerRows = [];
    let fields = this.props.fields;
    if (this.props.supportsDrag) {
      headerRows.push(<th key={-1}></th>);
    }

    for (let i = 0; i < fields.length; i++) {
      let isSortable = this.props.sortableColumn !== null && this.props.sortableColumn[i] === true;
      headerRows.push(
        <th key={i} style={{ width: this.props.columnWidths[i] + '%' }}>
          <div
            className={'header-title-icon-wrapper' + (isSortable ? ' is-sortable' : '')}
            onClick={() => this.sort(i)}
          >
            {fields[i].title}
            {this.getArrow(i)}
          </div>
        </th>
      );
    }
    return headerRows;
  }

  getBodyRows() {
    let bodyRows = [];
    let content = this.props.content;
    for (let i = 0; i < content.length; i++) {
      let rowContent = (
        <tr key={i} onClick={() => this.props.cellClicked(i)}>
          {this.getSingleBodyRow(content[i], i)}
        </tr>
      );
      if (content[i].hasOwnProperty('draggable') && content[i].draggable === true) {
        rowContent = (
          <Draggable key={'draggable-' + i} draggableId={'draggable-' + i} index={i}>
            {function (rowContent, i) {
              return function (provider, snapshot) {
                return (
                  <tr
                    ref={provider.innerRef}
                    {...provider.draggableProps}
                    {...provider.dragHandleProps}
                    key={i}
                    onClick={() => this.props.cellClicked(i)}
                  >
                    {this.getSingleBodyRow(content[i], i)}
                  </tr>
                );
              }.bind(this);
            }.bind(this)(rowContent, i)}
          </Draggable>
        );
      }
      bodyRows.push(rowContent);
    }
    return bodyRows;
  }

  getSingleBodyRow(objectForRow, contentPos) {
    let fields = this.props.fields;
    let row = [];
    if (this.props.supportsDrag) {
      if (objectForRow.hasOwnProperty('draggable') && objectForRow.draggable === true) {
        row.push(
          <td key={-1} style={{ width: '50px' }}>
            <span className="fa fa-grip-vertical"></span>
          </td>
        );
      } else {
        row.push(<td key={-1}></td>);
      }
    }
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].title !== '') {
        let cellData;
        let color = '';
        if (
          !objectForRow.hasOwnProperty(fields[i].attribute) ||
          objectForRow[fields[i].attribute] === null
        ) {
          cellData = (
            <td key={i} style={{ color: color }}>
              -
            </td>
          );
          row.push(cellData);
          continue;
        }
        let data = objectForRow[fields[i].attribute];
        if (fields[i].attribute === 'createdAt' || fields[i].attribute === 'modifiedAt') {
          data = moment(data).format('MM/DD/YYYY');
        }

        let stringColorPair = null;
        if (typeof objectForRow.getStringColorPair === 'function') {
          stringColorPair = objectForRow.getStringColorPair();
        } else if (typeof objectForRow.stringColorPair !== 'undefined') {
          stringColorPair = objectForRow.stringColorPair;
        }
        if (stringColorPair !== null && stringColorPair.hasOwnProperty(data)) {
          color = stringColorPair[data];
        }
        if (fields[i].attribute === 'status') {
          let circleColor = '';
          if (data === 'Incomplete') {
            circleColor = 'yellow';
          } else if (data === 'Complete') {
            circleColor = 'green';
          }
          cellData = (
            <td key={fields[i].attribute}>
              <div className={'status-circle ' + circleColor}></div>
              <span className="status-text">{data}</span>
            </td>
          );
        } else if (fields[i].attribute === 'nickname') {
          cellData = (
            <td key={i} style={{ color: '#747C80' }}>
              {data.toString()}
            </td>
          );
          //if this is an object, then output it as-is without any conversions
        } else if (isReactElement(data)) {
          cellData = <td key={i}>{data}</td>;
        } else {
          if (fields[i].attribute === 'title' && this.props.imageField) {
            if (this.checkAWSFileType(objectForRow[this.props.imageField]) === 'document') {
              cellData = (
                <td key={i} style={{ color: color }}>
                  {' '}
                  <div className="doc-type-icon" onClick={this.downloadFile}>
                    doc
                  </div>
                  {data.toString()}
                </td>
              );
            } else if (this.checkAWSFileType(objectForRow[this.props.imageField]) === 'pdf') {
              cellData = (
                <td key={i} style={{ color: color }}>
                  {' '}
                  <div className="doc-type-icon" onClick={this.downloadFile}>
                    pdf
                  </div>
                  {data.toString()}
                </td>
              );
            } else {
              cellData = (
                <td key={i} style={{ color: color }}>
                  {' '}
                  <img
                    className="imageTitle"
                    alt=""
                    src={objectForRow[this.props.imageField]}
                  ></img>
                  {data.toString()}
                </td>
              );
            }
          } else {
            cellData = (
              <td key={i} style={{ color: color }}>
                {data.toString()}
              </td>
            );
          }
        }
        row.push(cellData);
      } else {
        let iconColor = 'gray';
        let isTrash = false;
        if (fields[i].attribute === 'trashFA') {
          iconColor = 'gray';
          isTrash = true;
        } else if (typeof objectForRow.getIconColor === 'function') {
          iconColor = objectForRow.getIconColor();
        } else if (objectForRow.iconColor !== 'undefined') {
          iconColor = objectForRow.iconColor;
        }
        let iconColorClass = '';
        if (iconColor === 'gray') {
          iconColorClass = 'icon-color-gray';
        } else if (iconColor === 'blue') {
          iconColorClass = 'icon-color-blue';
        }

        row.push(
          <td key={i}>
            <IconButton
              foregroundIcon={objectForRow[fields[i].attribute]}
              backgroundIcon={objectForRow[fields[i].attribute + 'Background']}
              extraClasses="icon-button-click"
              buttonColorType={iconColorClass}
              callback={
                isTrash
                  ? () => this.props.content[contentPos].getDeleteAction()
                  : () => this.props.content[contentPos].getAction()
              }
            />
          </td>
        );
      }
    }

    return row;
  }
}

CustomTable.propTypes = {
  fields: PropTypes.array.isRequired,
  content: PropTypes.array.isRequired,
  columnWidths: PropTypes.array,
  sortable: PropTypes.bool.isRequired,
  sortableColumn: PropTypes.array,
  parentContext: PropTypes.object.isRequired,
  cellClicked: PropTypes.func.isRequired,
  supportsDrag: PropTypes.bool.isRequired,
  droppableId: PropTypes.string.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

CustomTable.defaultProps = {
  columnWidths: [],
  sortableColumn: [],
  cellClicked: function (i) {},
  supportsDrag: false,
  droppableId: 'CustomTable-droppable',
  onDragEnd: function (result) {},
};

export default CustomTable;
