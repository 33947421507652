import React from 'react';
import { toast_error } from '@/libs/toast-wrappers';
import CustomTable from '../lib-medical-portal/components/CustomTable';
import InputSearchBar from '../lib-medical-portal/components/FormInputs/InputSearchBar';
import { parse_cookies } from '../react-utils/src/libformat';
import moment from 'moment';

import { ERROR_MESSAGE_TRY_AGAIN_EMAIL, ERROR_FETCH_USERS } from '@/utils/messages';

import ClinicUser from '../models/ClinicUser';
import LoadOverlay from '../lib-medical-portal/components/LoadOverlay.jsx';
import { logout } from '@/libs/session-management';

//internationalization and translation support
import i18n from '@/libs/i18n.js';
import { getUsersList } from '@/api/users';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userArray: [],
      userArrayFiltered: [],
      load_complete: false,
      load_error: false,
      load_error_message: '',
    };

    this.saveUserList = this.saveUserList.bind(this);
  }

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData() {
    this.setState({
      load_complete: false,
    });

    let cookies = parse_cookies();
    let clinicId = 'all';
    if (cookies.hasOwnProperty('visionquest-user-clinic')) {
      clinicId = cookies['visionquest-user-clinic'];
    }

    getUsersList(clinicId).then((response) => {
      this.saveUserList(response);
      this.setState({
        load_complete: true,
      });
    });
    // aws_api_call.get_rqst(
    //   '/users?clinic_id=' + clinicId,
    //   (function (component) {
    //     return function (xhr) {
    //       let responseObject = JSON.parse(xhr.response);
    //       component.saveUserList(responseObject.users);
    //       component.setState({
    //         load_complete: true,
    //       });
    //     };
    //   })(this),
    //   { 'Content-Type': 'application/json' },
    //   (function (component) {
    //     return function (xhr) {
    //       console.log('Error (' + xhr.status + ') fetchUserData()');
    //       console.log(xhr.responseText);
    //       toast_error(ERROR_FETCH_USERS + ' ' + ERROR_MESSAGE_TRY_AGAIN_EMAIL);
    //       if (xhr.status === 403) {
    //         logout();
    //       } else {
    //         component.setState({
    //           load_complete: true,
    //           load_error: true,
    //           load_error_message: (
    //             <div>
    //               <h2>{ERROR_FETCH_USERS}</h2>
    //               <p>{ERROR_MESSAGE_TRY_AGAIN_EMAIL}</p>
    //             </div>
    //           ),
    //         });
    //       }
    //     };
    //   })(this)
    // );
  }

  saveUserList(userList) {
    let fetchedUserList = [];
    for (let i = 0; i < userList.length; i++) {
      let currentUser = userList[i];
      let dateCreated = currentUser.created;
      let colonCount = 0;
      let indexLastColon = -1;
      // date returned from server contains milliseconds which is incompatible with moment. Thus, remove milliseconds.
      for (let i = 0; i < dateCreated.length; i++) {
        if (dateCreated.charAt(i) === ':') {
          if (colonCount === 2) {
            indexLastColon = i;
            break;
          } else {
            colonCount++;
          }
        }
      }

      if (indexLastColon !== -1) {
        dateCreated = dateCreated.substring(0, indexLastColon);
      }

      let clinicUser = new ClinicUser(new Date(moment(dateCreated).format('MM/DD/YYYY')));
      clinicUser.setName(currentUser.name);
      clinicUser.setEmail(currentUser.email);
      fetchedUserList.push(clinicUser);
    }
    this.setState({
      userArray: fetchedUserList,
    });
  }

  updateTableContent(component, array) {
    component.setState({
      userArray: array,
    });
  }

  onClickSearchIcon(component) {
    console.log('clicked');
  }

  render() {
    let fields = [
      { title: i18n.t('users-user-name'), attribute: 'name' },
      { title: i18n.t('users-email'), attribute: 'email' },
      { title: i18n.t('users-date-added'), attribute: 'createdAt' },
    ];

    let columnWidths = [20, 60, 20];

    return (
      <div className="Users">
        <div className="users-table-wrapper">
          <div className="title-search-button-wrapper">
            <span className="users-table-title">{i18n.t('users-title')}</span>
            <div className="search-bar-button-wrapper">
              <InputSearchBar
                searchClickEvent={() => this.onClickSearchIcon}
                searchContext={this}
                formId="users-search-form"
                fieldId="users-search-bar"
                formClass="users-search-bar"
                fieldHint={i18n.t('users-search')}
              />
              <button className="button button-small button-icon">
                <span className="fa fa-plus"></span>
                {i18n.t('users-new-user')}
              </button>
            </div>
          </div>

          <div className="custom-table-wrapper">
            <CustomTable
              fields={fields}
              content={this.state.userArray}
              columnWidths={columnWidths}
              parentContext={this}
              sortable={true}
              sortableColumn={[true, false, true]}
            />
          </div>
        </div>
        <LoadOverlay
          show={this.state.load_error || !this.state.load_complete}
          error={this.state.load_error}
          errorMessage={this.state.load_error_message}
        />
      </div>
    );
  }
}

export default Users;
