import BaseModel from './BaseModel';

class Clinic extends BaseModel {
  constructor(createdAt) {
    super(createdAt);

    this.clinicId = null;
    this.name = null;
    this.representativeName = null;
    this.email = null;
    this.phoneNum = null;
    this.streetAddress = null;
    this.city = null;
    this.region = null;
    this.postalCode = null;
    this.country = null;
    this.billingId = null;
  }

  getClinicId() {
    return this.clinicId;
  }

  setClinicId(clinicId) {
    return this.genericSetter(this, 'clinicId', clinicId, this.typeString());
  }

  getName() {
    return this.name;
  }

  setName(name) {
    return this.genericSetter(this, 'name', name, this.typeString());
  }

  getRepresentativeName() {
    return this.representativeName;
  }

  setRepresentativeName(representativeName) {
    return this.genericSetter(this, 'representativeName', representativeName, this.typeString());
  }

  getEmail() {
    return this.email;
  }

  setEmail(email) {
    return this.genericSetter(this, 'email', email, this.typeString());
  }

  getPhoneNum() {
    return this.phoneNum;
  }

  setPhoneNum(phoneNum) {
    return this.genericSetter(this, 'phoneNum', phoneNum, this.typeString());
  }

  getStreetAddress() {
    return this.streetAddress;
  }

  setStreetAddress(streetAddress) {
    return this.genericSetter(this, 'streetAddress', streetAddress, this.typeString());
  }

  getCity() {
    return this.city;
  }

  setCity(city) {
    return this.genericSetter(this, 'city', city, this.typeString());
  }

  getRegion() {
    return this.region;
  }

  setRegion(region) {
    return this.genericSetter(this, 'region', region, this.typeString());
  }

  getPostalCode() {
    return this.postalCode;
  }

  setPostalCode(postalCode) {
    return this.genericSetter(this, 'postalCode', postalCode, this.typeString());
  }

  getCountry() {
    return this.country;
  }

  setCountry(country) {
    return this.genericSetter(this, 'country', country, this.typeString());
  }

  getBillingId() {
    return this.billingId;
  }

  setBillingId(billingId) {
    return this.genericSetter(this, 'billingId', billingId, this.typeString());
  }

  deserialize(serializedData) {
    return super.deserialize(Clinic, serializedData);
  }
}

export default Clinic;
