import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faOctagonXmark } from '@fortawesome/pro-solid-svg-icons';

type StatusValueProps = {
  value: boolean;
};

export function StatusValue(props: StatusValueProps) {
  const { value } = props;

  if (value) return <FontAwesomeIcon icon={faCheckCircle} className="text-green-700" />;

  return <FontAwesomeIcon icon={faOctagonXmark} className="text-red-600" />;
}
