import { Case, setCaseClaimed } from '@/api/cases';
import { useUserProfile } from '@/states/user-profile';

import { Switch } from '@/components/form/switch';

type SetCaseClaimedProps = {
  case?: Case;
  claimed: boolean;
  onSetClaimed: (claimed: boolean) => void;
  onSetSaving: (saving: boolean) => void;
  disabled?: boolean;
};

export function SetCaseClaimed(props: SetCaseClaimedProps) {
  const { case: _case, claimed, onSetClaimed, onSetSaving, disabled } = props;
  const { email } = useUserProfile((state) => ({ email: state.profile.email ?? '' }));

  const handleChangeClaimed = async (checked: boolean) => {
    // we are only updating the claimed status when true, we are not unclaiming at this point
    if (!checked || !_case) return;

    onSetSaving(true);

    try {
      await setCaseClaimed(_case.case_id, email);
      onSetClaimed(checked);
      onSetSaving(false);
    } catch (error) {
      console.error('Error setting case claimed', error);
      onSetSaving(false);
    }
  };

  return (
    <div className="border border-slate-50 shadow sm:rounded-lg mb-4">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Claimed Case</h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl ">
            <p className="text-sm text-gray-500">
              {claimed
                ? 'You have claimed this case. You can now grade it.'
                : 'You have not claimed this case. Click the switch to claim it.'}
            </p>
          </div>
          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            <Switch
              disabled={disabled}
              checked={claimed}
              onCheckedChange={handleChangeClaimed}
              className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
            >
              <span
                aria-hidden="true"
                className="inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
