import config from '../../../src/config';

export function get_backend_host() {
  return config.backend_host;
}

export function strip_backend_host(str, path = '') {
  if (str.indexOf(get_backend_host() + path) === 0) {
    return str.substring((get_backend_host() + path).length);
  }
  return str;
}

//get generic information from the back-end
//this requires CORS to be configured on the back-end server
export function fetch_info(
  path,
  have_data_callback,
  headers = null,
  error_callback = function (response) {},
  abort_signal = null,
  no_data_callback = function (response) {}
) {
  let fetch_init = {
    method: 'GET',
    headers: headers,
  };
  if (abort_signal !== null) {
    fetch_init.signal = abort_signal;
  }
  return fetch(get_backend_host() + path, fetch_init).then(function (response) {
    if (response.status === 204) {
      console.log(
        '(info) fetch_info got 204 - no content and is not calling the have_data_callback function'
      );
      no_data_callback(response);
    } else if (response.status === 200 || response.status === 304) {
      return response.json().then((data) => {
        return have_data_callback(data);
      });
    } else {
      error_callback(response);
    }
  }, error_callback);
}

//send generic information to the back-end
//this is done as an AJAX POST request by default
export function send_info(
  path,
  body_data,
  have_data_callback,
  headers = null,
  error_callback = function (response) {},
  abort_signal = null,
  method = 'POST',
  no_data_callback = function (response) {}
) {
  let fetch_init = {
    method: method,
    headers: headers,
    body: body_data,
  };
  if (abort_signal !== null) {
    fetch_init.signal = abort_signal;
  }
  return fetch(get_backend_host() + path, fetch_init).then(function (response) {
    if (response.status === 204) {
      console.log(
        '(info) send_info got 204 - no content and is not calling the have_data_callback function'
      );
      no_data_callback(response);
    } else if (response.status === 200 || response.status === 304) {
      return response.json().then((data) => {
        return have_data_callback(data);
      });
    } else {
      error_callback(response);
    }
  }, error_callback);
}

//get generic information from the back-end OR send generic information to the back-end
//this requires CORS to be configured on the back-end server
export function xml_rqst_info(
  path,
  have_data_callback,
  method = 'GET',
  body_data = null,
  headers = {},
  error_callback = function (xhr) {},
  no_data_callback = function (xhr) {},
  upload_progress_callback = function (e) {},
  backend_host = get_backend_host(),
  async = true,
  response_type = ''
) {
  //create an ajax http request
  let xhr = new XMLHttpRequest();

  //set the response type
  xhr.responseType = response_type;

  //hook up the given callbacks
  if (xhr.upload !== undefined) {
    xhr.upload.onprogress = function (e) {
      upload_progress_callback(e);
    };
  }
  xhr.onreadystatechange = function () {
    if (this.readyState === this.DONE) {
      //204 means no content, so call the no data callback
      if (this.status === 204) {
        no_data_callback(this);
        //2xx and 3xx are forms of success, so call the success callback
        //NOTE: for XMLHttpRequest calls this callback takes in a response object, NOT a json data object
        //this allows us to support binary data as well as json
      } else if (this.status >= 200 && this.status < 400) {
        have_data_callback(this);
        //any other class of return is an error, so call the error callback
      } else {
        error_callback(this);
      }
    }
  };
  //set the request location
  xhr.open(method, backend_host + path, async);

  //set any provided headers
  let hdr_keys = Object.keys(headers);
  for (let i = 0; i < hdr_keys.length; i++) {
    if (headers.hasOwnProperty(hdr_keys[i])) {
      xhr.setRequestHeader(hdr_keys[i], headers[hdr_keys[i]]);
    }
  }

  //send the request and return the result
  return xhr.send(body_data);
}

//get generic information from the back-end
//this requires CORS to be configured on the back-end server
export function xml_fetch_info(
  path,
  have_data_callback,
  headers = {},
  error_callback = function (xhr) {},
  no_data_callback = function (xhr) {},
  upload_progress_callback = function (e) {}
) {
  return xml_rqst_info(
    path,
    have_data_callback,
    'GET',
    null,
    headers,
    error_callback,
    no_data_callback,
    upload_progress_callback,
    get_backend_host()
  );
}

//send generic information to the back-end
//this requires CORS to be configured on the back-end server
export function xml_send_info(
  path,
  body_data,
  have_data_callback,
  method = 'POST',
  headers = {},
  error_callback = function (xhr) {},
  no_data_callback = function (xhr) {},
  upload_progress_callback = function (e) {}
) {
  return xml_rqst_info(
    path,
    have_data_callback,
    method,
    body_data,
    headers,
    error_callback,
    no_data_callback,
    upload_progress_callback,
    get_backend_host()
  );
}
