/**
 * Convert boolean to string 'Yes' or 'No'
 * @param str expect to be 'Yes' or 'No'
 * @returns
 */
export const decisionStrToBool = (str: string) => {
  return str.toLowerCase() === 'yes';
};

export const decisionBoolToStr = (bool: boolean) => {
  return bool ? 'yes' : 'no';
};

export const formatId = (id: unknown) => {
  if (typeof id === 'string') {
    if (id.length > 5) return id ? id.slice(0, 5) + '...' : '';

    return id;
  }
  return '';
};
