import { Outlet } from 'react-router-dom';

import { GlobalHeader } from './global-header';
import { SideMenu } from './navigation/side-menu';

export function MainLayout() {
  return (
    <div className="flex">
      <SideMenu />
      <div className="flex flex-col main-layout">
        <GlobalHeader />
        <div className="main-page-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
