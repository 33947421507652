import useSWR from 'swr';

import { getSitesByClinic } from '@/api/sites';
import { ENDPOINTS } from '@/api/endpoints';

export const useGetSitesByClinic = ({ clinic_id }: { clinic_id?: number }) => {
  const { data, isLoading, mutate } = useSWR([ENDPOINTS.SITES, clinic_id], () =>
    getSitesByClinic({ clinic_id })
  );

  return {
    loading: isLoading,
    sites: data,
    refetchSites: mutate,
  };
};
