export const ENDPOINTS = {
  // auth
  LOGIN: '/users/login',
  SIGNUP: '/users/signup',
  RESET: '/users/reset',
  FORGOT: '/users/forgot',
  CHANGEPASS: '/users/changepass',

  // users
  COUNTRIES: '/users/signup/countries',
  PROFILE: '/users/profile',
  USERS_LIST: '/users',

  // cases
  CASE: '/cases/{caseId}',
  CASES: '/cases',
  CASES_IMAGE: '/cases/{caseId}/images/{imgPath}',
  CASE_FILE: '/cases/{caseId}/{filePath}',
  GENERATE_REPORT: '/cases/{caseId}/{dl_file_name}',
  CASE_CLAIM: '/cases/{caseId}/claim',

  // grades
  GRADES: '/grades',
  GRADE_TELEHEALTH_REPORT: '/grades/{grade_id}/telehealth-report',

  // licenses
  LICENSES: '/licenses',
  // LICENSE_REQUEST: '/licenses/request-license',
  LICENSE_REQUEST: '/users/{doctor_email}/license',
  LICENSE_FILE: '/licenses/{doctor_email}/{file}',

  // cameras
  CAMERAS: '/clinics/cameras',

  // counting
  COUNT_USERS: '/users/count',
  COUNT_CASES: '/cases/count',
  COUNT_CASES_IMAGES: '/cases/images/count',
  COUNT_PATIENTS: '/cases/patients/count',

  // stats for dashboard
  STATS: '/cases/stats',

  // billing
  CURRENT_BILLING: '/billing/plans/current',

  // legal
  LEGAL_DOCS: '/legal/documents',
  LEGAL_DOC_FILE: '/legal/documents/{filePath}',

  // clinics
  CLINIC: '/clinics/{clinicId}',
  CLINICS: '/clinics',

  // sites
  SITES: '/clinics/sites',
  SITE_BY_ID: '/clinics/sites/{siteId}',
} as const;
