class BaseModel {
  constructor(createdAt) {
    // if createdAt is passed, it will use that value, otherwise, new Date();
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Details_of_the_Object_Model#JavaScript_4
    this.createdAt = createdAt || new Date().toISOString();
    this.modifiedAt = this.createdAt;
    this.valid = true;
  }

  getCreatedAt() {
    return new Date(this.createdAt);
  }

  getModifiedAt() {
    return new Date(this.modifiedAt);
  }

  setModifiedAt(modifiedAt) {
    this.modifiedAt = modifiedAt.toISOString();
  }

  getValid() {
    return this.valid;
  }

  setValid(isValid) {
    this.valid = isValid;
  }

  serialize() {
    return JSON.stringify(this);
  }

  deserialize(objectType, serializedData) {
    let data = JSON.parse(serializedData);
    let deserializedData = Object.setPrototypeOf(data, objectType.prototype);
    deserializedData.createdAt = new Date(deserializedData.getCreatedAt()).toISOString();
    deserializedData.modifiedAt = new Date(deserializedData.getModifiedAt()).toISOString();
    return deserializedData;
  }

  typeString() {
    return 'string';
  }

  typeBool() {
    return 'boolean';
  }

  typeNumber() {
    return 'number';
  }

  typeArray() {
    return 'array';
  }

  typeDate() {
    return 'date';
  }

  typeObject() {
    return 'object';
  }

  typeFunction() {
    return 'function';
  }

  typeCheck(value, expectedType) {
    return typeof value === expectedType;
  }

  /** This function sets the given property within the object state; it returns true on success, false on failure (including failure due to type difference)
   *
   * @param child: the Model that called this function
   * @param property: the name of the property
   * @param value: the value to be set
   * @param expectedType: the data type expected from the 'value' parameter
   * @returns {boolean}: true if the given value is successfully set with the correct data type, false otherwise.
   */
  genericSetter(child, property, value, expectedType) {
    // check type then set value, if wrong type -> return false;
    if (this.typeCheck(value, expectedType)) {
      child[property] = value;
      return true;
    } else if (value instanceof Date && expectedType === this.typeDate()) {
      // checking type of Date separately because 'typeof' doesn't work with Date
      child[property] = value.toISOString();
      return true;
    } else if (value instanceof Array && expectedType === this.typeArray()) {
      // checking type of Array separately because 'typeof' doesn't work with Array
      child[property] = value;
      return true;
    }
    return false;
  }
}

export default BaseModel;
