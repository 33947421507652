/*
Legal - A LegalDoc component is a way to get legal information from the eyestar API
	A LegalDoc component consists of a series of html buttons
	A LegalDoc component is a bottom-level component which contains no subcomponents
*/

import React from 'react';

import { toast_error } from '@/libs/toast-wrappers';

import { getLegalDoc, getLegalDocsList } from '@/api/legal';
import { logout } from '@/libs/session-management';

import {
  ERROR_FETCH_LEGAL,
  ERROR_MESSAGE_TRY_AGAIN_EMAIL,
  ERROR_LEGAL_DOCUMENT_DOWNLOAD,
} from '@/utils/messages';

import LoadOverlay from '../lib-medical-portal/components/LoadOverlay.jsx';

//internationalization and translation support
import i18n from '@/libs/i18n.js';

/*
props (component-level arguments):
	A LegalDoc has no props
*/
class LegalDoc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      legal_doc_files: [],
      load_complete: false,
      load_error: false,
      load_error_message: '',
    };

    this.fetch_legal_file_list = this.fetch_legal_file_list.bind(this);
    this.dl_legal_file = this.dl_legal_file.bind(this);
  }

  componentDidMount() {
    this.fetch_legal_file_list();
  }

  fetch_legal_file_list() {
    this.setState({
      load_complete: false,
    });

    //GET /legal/documents
    getLegalDocsList().then((list) => {
      console.log('fetch_legal_file_list success');
      this.setState({
        legal_doc_files: list,
      });
      this.setState({
        load_complete: true,
      });
    });
    // TODO: remove the following code after the above code is tested
    // aws_api_call.get_rqst(
    //   '/legal/documents',
    //   (function (cmpnt) {
    //     return function (xhr) {
    //       console.log('fetch_legal_file_list success');
    //       cmpnt.setState({
    //         legal_doc_files: JSON.parse(xhr.responseText)['documents'],
    //       });
    //       cmpnt.setState({
    //         load_complete: true,
    //       });
    //     };
    //   })(this),
    //   { 'Content-Type': 'application/json' },
    //   (function (component) {
    //     return function (xhr) {
    //       console.log('fetch_legal_file_list error ' + xhr.status);
    //       toast_error(ERROR_FETCH_LEGAL + ' ' + ERROR_MESSAGE_TRY_AGAIN_EMAIL);
    //       if (xhr.status === 403) {
    //         logout();
    //       } else {
    //         component.setState({
    //           load_complete: true,
    //           load_error: true,
    //           load_error_message: (
    //             <div>
    //               <h2>{ERROR_FETCH_LEGAL}</h2>
    //               <p>{ERROR_MESSAGE_TRY_AGAIN_EMAIL}</p>
    //             </div>
    //           ),
    //         });
    //       }
    //     };
    //   })(this)
    // );
  }

  dl_legal_file(file_name) {
    //GET /legal/documents/filename.pdf
    getLegalDoc(file_name).then((response) => {
      console.log('dl_legal_file Success!');
      let pdf_link = document.createElement('a');
      //NOTE: the response is already a blob because we are passing in response_type blob to the request function
      //so it doesn't need any conversion
      let pdf_blob = response;
      pdf_link.setAttribute('href', window.URL.createObjectURL(pdf_blob));
      pdf_link.setAttribute('download', file_name);
      pdf_link.style.display = 'none';
      document.body.appendChild(pdf_link);
      pdf_link.click();
      document.body.removeChild(pdf_link);
    });

    // TODO: remove the following code after the above code is tested
    // aws_api_call.get_rqst(
    //   '/legal/documents/' + encodeURIComponent(file_name),
    //   (function (cmpnt, file_name) {
    //     return function (xhr) {
    //       console.log('dl_legal_file Success!');
    //       let pdf_link = document.createElement('a');
    //       //NOTE: the response is already a blob because we are passing in response_type blob to the request function
    //       //so it doesn't need any conversion
    //       let pdf_blob = xhr.response;
    //       pdf_link.setAttribute('href', window.URL.createObjectURL(pdf_blob));
    //       pdf_link.setAttribute('download', file_name);
    //       pdf_link.style.display = 'none';
    //       document.body.appendChild(pdf_link);
    //       pdf_link.click();
    //       document.body.removeChild(pdf_link);
    //     };
    //   })(this, file_name),
    //   { 'Content-Type': 'application/pdf' },
    //   function (xhr) {
    //     toast_error(ERROR_LEGAL_DOCUMENT_DOWNLOAD + ' ' + ERROR_MESSAGE_TRY_AGAIN_EMAIL);
    //     console.log('dl_legal_file Error ' + xhr.status);
    //     console.log(xhr);
    //   },
    //   function (xhr) {},
    //   function (e) {},
    //   'blob'
    // );
  }

  render() {
    let cmpnt = this;
    return (
      <div className="LegalDoc">
        <div className="grid-x grid-padding-x">
          {this.state.legal_doc_files.map(function (legal_doc_name, doc_idx) {
            return (
              <div className="small-12 cell button-cont" key={'legal-doc-' + doc_idx}>
                <button
                  className="button legal-doc-button"
                  onClick={function (e) {
                    e.preventDefault();
                    cmpnt.dl_legal_file(legal_doc_name);
                    return false;
                  }}
                >
                  {i18n.t('default-download')} {legal_doc_name}
                </button>
              </div>
            );
          })}
        </div>
        <LoadOverlay
          show={this.state.load_error || !this.state.load_complete}
          error={this.state.load_error}
          errorMessage={this.state.load_error_message}
        />
      </div>
    );
  }
}

export default LegalDoc;
