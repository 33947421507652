import BaseModel from './BaseModel';

class AdminUser extends BaseModel {
  constructor(createdAt) {
    super(createdAt);

    this.adminUserId = null;
    this.name = null;
    this.email = null;
  }

  getAdminUserId() {
    return this.adminUserId;
  }

  setAdminUserId(adminUserId) {
    return this.genericSetter(this, 'adminUserId', adminUserId, this.typeString());
  }

  getName() {
    return this.name;
  }

  setName(name) {
    return this.genericSetter(this, 'name', name, this.typeString());
  }

  getEmail() {
    return this.email;
  }

  setEmail(email) {
    return this.genericSetter(this, 'email', email, this.typeString());
  }

  deserialize(serializedData) {
    return super.deserialize(AdminUser, serializedData);
  }
}

export default AdminUser;
