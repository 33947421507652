import { errorlog } from '@/libs/error-logs';
import { toast_error } from '@/libs/toast-wrappers';
import { ERROR_GET_CLINICS } from '@/utils/messages';

import { ENDPOINTS } from './endpoints';
import { GET } from './base';

type GetSitesParams = {
  clinic_id?: number;
  limit?: number;
};

export type Site = {
  site_id: number;
  clinic_id?: number;
  description?: string;
  active?: boolean;
  timezone?: string;
};

type SiteResponse = {
  data: Site[];
};

export async function getSitesByClinic(params?: GetSitesParams): Promise<Site[]> {
  try {
    const query = {
      clinic_id: params?.clinic_id?.toString() ?? 'all',
      limit: encodeURIComponent(params?.limit ?? 100),
    };

    const response = await GET<SiteResponse>(ENDPOINTS.SITES, {
      query,
    });

    return response.data;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/getSites', 'Error fetching sites');
    toast_error(ERROR_GET_CLINICS);
    return Promise.reject(error);
  }
}

type SiteByIdResponse = {
  data: Site;
};

export async function getSiteById(siteId?: string): Promise<Site> {
  try {
    const response = await GET<SiteByIdResponse>(`${ENDPOINTS.SITE_BY_ID}`, {
      parameters: { siteId: siteId ?? '' },
    });

    return response.data;
  } catch (error) {
    errorlog(error as Error, 'src/api/sites/getSiteById', 'Error fetching site by id');
    toast_error(ERROR_GET_CLINICS);
    return Promise.reject(error);
  }
}
