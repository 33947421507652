import { useState } from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-light-svg-icons';

import { NavItem } from '@/hooks/use-navigation-items';

import { SubMenu } from './side-sub-menu';

type SideMenuItemProps = {
  item: NavItem;
  isSubMenuItem?: boolean;
};

export function SideMenuItem({ item, isSubMenuItem = false }: SideMenuItemProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const hasSubMenu = item.subItems && item.subItems.length > 0;

  const toggleSubMenu = () => {
    setIsCollapsed((isOpen) => !isOpen);
  };

  const itemClasses = clsx([isSubMenuItem && 'flex align-middle gap-1', 'w-full']);

  return (
    <li className={itemClasses}>
      {item.icon && (
        <span className="icon-side-menu">
          <FontAwesomeIcon icon={item.icon} color="#8cc3e1" />
        </span>
      )}
      {item.href ? (
        <Link to={item.href}>{item.label}</Link>
      ) : hasSubMenu ? (
        <>
          <button onClick={toggleSubMenu}>
            <span className="mr-2">{item.label}</span>
            <FontAwesomeIcon
              className=" self-end"
              icon={isCollapsed ? faChevronRight : faChevronDown}
            />
          </button>

          {!isCollapsed && item.subItems && <SubMenu items={item.subItems} />}
        </>
      ) : (
        <button className="mb-8" type="button" onClick={item.callback}>
          {item.label}
        </button>
      )}
    </li>
  );
}
