import TestObjects from './TestObjects';
import ModelTest from './ModelTest';

class TestMaster {
  // create test objects for all data models and check their fields for equality
  static runModelTest() {
    let adminUser = TestObjects.createAdminUser();
    ModelTest.equalityCheck(adminUser);

    let clinicUser = TestObjects.createClinicUser();
    ModelTest.equalityCheck(clinicUser);

    let billing = TestObjects.createBilling();
    ModelTest.equalityCheck(billing);

    let tCase = TestObjects.createCase();
    ModelTest.equalityCheck(tCase);

    let clinic = TestObjects.createClinic();
    ModelTest.equalityCheck(clinic);

    let legal = TestObjects.createLegal();
    ModelTest.equalityCheck(legal);

    let plan = TestObjects.createPlan();
    ModelTest.equalityCheck(plan);

    let session = TestObjects.createSession();
    ModelTest.equalityCheck(session);
  }
}

export default TestMaster;
