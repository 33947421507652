/*
AnalyticsGraphTab - An AnalyticsGraphTab is a tab item for AnalyticsGraph
	A AnalyticsGraphTab consists of -------
*/

import React from 'react';
import PropTypes from 'prop-types';

class AnalyticsGraphTab extends React.Component {
  render() {
    let isActive = this.props.isActive;
    let percentageDiff = this.props.percentageDiff;
    let isPositive = percentageDiff >= 0;

    //a percentage difference is good if and only if either
    //	the difference is positive and that's a good thing
    //	OR the difference is negative and that's a good thing
    let isPercDiffGood = isPositive === this.props.positiveDiffGood;

    return (
      <div className={'AnalyticsGraphTab ' + (isActive ? 'active' : 'inactive')}>
        {isActive ? <div className="tab-active-indicator" /> : ''}
        <div className={'title ' + (isActive ? 'active' : 'inactive')}>{this.props.title}</div>
        <div className={'content ' + (isActive ? 'active' : 'inactive')}>{this.props.content}</div>
        {percentageDiff !== undefined && percentageDiff !== null ? (
          <div className={'percentageDiff ' + (isPercDiffGood ? 'positive' : 'negative')}>
            {(isPositive ? '+' : '') + percentageDiff.toFixed(2)}%
            <div className="percentage-diff-fineprint">
              vs last {this.props.intervalValue} {this.props.intervalUnit}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

AnalyticsGraphTab.defaultProps = {
  positiveDiffGood: true,
};

AnalyticsGraphTab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  percentageDiff: PropTypes.number,
  positiveDiffGood: PropTypes.bool.isRequired,
  intervalValue: PropTypes.number.isRequired,
  intervalUnit: PropTypes.string.isRequired,
};
export default AnalyticsGraphTab;
