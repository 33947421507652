import { ROLES } from '@/utils/constants';

export const ROUTES = {
  LOGIN: '/auth/login',
  CHANGE_PASSWORD: '/auth/changepass',
  NEW_PASSWORD: '/auth/reset-password',
  // `Forgot Password` and `Create User` at this moment are the same page for login, we should change this in the future
  // FORGOT_PASSWORD: '/auth/forgot-password',
  // CREATE_ACCOUNT: '/auth/create-account',
  DASHBOARD: '/',
  CASES: '/cases',
  GRADING: '/grading',
  USERS: '/users',
  BILLING: '/billing',
  LEGAL: '/legal',
  KITCHEN_SINK: '/kitchen-sink',
  PROFILE: '/profile',
  LOGOUT: '/logout',
  ADMIN: '/admin',
  ADMIN_USERS: '/admin/users',
  ADMIN_CLINICS: '/admin/clinics',
  ADMIN_ADD_CLINIC: '/admin/clinics/new',
  ADMIN_EDIT_CLINIC: '/admin/clinics/edit',
  ADMIN_ADD_CLINIC_SITE: '/admin/clinics/:clinicId/site/new',
  ADMIN_EDIT_CLINIC_SITE: '/admin/clinics/:clinicId/site/edit/:siteId',
  ADMIN_TELEHEALTH_CLINICIANS: '/admin/telehealth-clinicians',
};

// we have some restricting routes that we should not allow the user to access
export const RESTRICTED_ROUTES: Record<ROLES, string[]> = {
  clinicuser: [ROUTES.DASHBOARD, ROUTES.PROFILE, ROUTES.LEGAL],
  clinicadmin: [ROUTES.DASHBOARD, ROUTES.USERS, ROUTES.BILLING, ROUTES.PROFILE, ROUTES.LEGAL],
  caseadmin: [ROUTES.DASHBOARD, ROUTES.CASES, ROUTES.PROFILE, ROUTES.LEGAL],
  // telehealth_clinician && test_grader have the same restricted routes, but inside the routes they have different permissions
  telehealthclinician: [ROUTES.GRADING, ROUTES.PROFILE, ROUTES.LEGAL],
  testgrader: [ROUTES.GRADING, ROUTES.PROFILE, ROUTES.LEGAL],
  vitaziadmin: Array.from(Object.values(ROUTES)),
};

export const MAIN_ROUTE_BY_ROLE: Record<ROLES, string> = {
  clinicuser: ROUTES.DASHBOARD,
  clinicadmin: ROUTES.DASHBOARD,
  caseadmin: ROUTES.CASES,
  telehealthclinician: ROUTES.GRADING,
  testgrader: ROUTES.GRADING,
  vitaziadmin: ROUTES.CASES,
};

export type ROUTE_BY_ROLE = keyof typeof RESTRICTED_ROUTES;

export const REDIRECT_ROUTES = ['/changepass', '/users/changepass'];
