import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight, faCircleArrowLeft } from '@fortawesome/pro-solid-svg-icons';

type CarouselArrowProps = {
  direction: 'left' | 'right';
  display: boolean;
  onClick: () => void;
};

export function CarouselArrow(props: CarouselArrowProps) {
  const { onClick, display, direction } = props;

  if (!display) return null;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`text-white top-1/2 absolute z-50 ${direction === 'left' ? 'left-4' : 'right-4'}`}
    >
      <span>
        <FontAwesomeIcon
          icon={direction === 'left' ? faCircleArrowLeft : faCircleArrowRight}
          size="xl"
        />
      </span>
    </button>
  );
}
