/*
InputSearchBar, a search bar that takes user input for a given event

An InputCheckbox item is a base level component and has no subcomponents
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component level arguments):
	fieldId: the id of the input tag
  fieldHint: the text placeholder of the input tag
	fieldType: the data type for the input tag
	fieldClass: the class of the input tag
	fieldName: the name attribute of the input tag
	fieldRequired: boolean to see if a user input is mandatory
	searchClickEvent: a function to be called when a user wants to search with the input
state (component level variables):
	inputIsEmpty: boolean value indicating if the search field is empty
*/

export class InputSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputIsEmpty: true,
    };
  }

  gainFocus() {
    document.getElementById(this.props.fieldId).placeholder = '';
    this.setState({
      inputIsEmpty: false,
    });
  }

  detectInputLength() {
    let parentContext = this.props.searchContext;
    let searchBar = document.getElementById(this.props.fieldId);
    if (searchBar !== null) {
      if (
        searchBar.value.length === 0 &&
        document.activeElement !== document.getElementById(this.props.fieldId)
      ) {
        document.getElementById(this.props.fieldId).placeholder = this.props.fieldHint;
        // when the search bar is empty, show the entire table data
        if (typeof parentContext.filterArray === 'function') {
          parentContext.filterArray(parentContext, '');
        }
        this.setState({
          inputIsEmpty: true,
        });
      } else {
        if (this.state.inputIsEmpty) {
          this.setState({
            inputIsEmpty: false,
          });
        }
      }
    }
  }

  render() {
    return (
      <form
        className={'InputSearchBar ' + this.props.formClass}
        id={this.props.formId}
        onChange={this.props.searchClickEvent(this.props.searchContext)}
        onSubmit={
          this.props.submitOverride
            ? this.props.submitOverride(this.props.searchContext)
            : this.props.searchClickEvent(this.props.searchContext)
        }
      >
        <input
          className={'search-bar ' + this.props.fieldClass}
          id={this.props.fieldId}
          type={this.props.fieldType}
          name={this.props.fieldName}
          placeholder={this.props.fieldHint}
          required={this.props.fieldRequired}
          onKeyUp={() => this.detectInputLength()}
          onFocus={() => this.gainFocus()}
          onBlur={() => this.detectInputLength()}
        />
        <button
          className={
            'search-magnifier ' +
            this.props.searchIconClass +
            (this.state.inputIsEmpty ? ' gray' : ' blue')
          }
          type="submit"
        />
      </form>
    );
  }
}

InputSearchBar.defaultProps = {
  formClass: '',
  formId: '',
  fieldId: 'id',
  fieldHint: 'Search...',
  fieldType: 'text',
  fieldClass: '',
  fieldName: '',
  fieldRequired: false,
  searchIconClass: '',
};

InputSearchBar.propTypes = {
  formClass: PropTypes.string,
  formId: PropTypes.string,
  fieldId: PropTypes.string,
  fieldHint: PropTypes.string,
  fieldType: PropTypes.string,
  fieldClass: PropTypes.string,
  fieldName: PropTypes.string,
  fieldRequired: PropTypes.bool,
  searchClickEvent: PropTypes.func.isRequired,
  searchContext: PropTypes.object.isRequired,
  submitOverride: PropTypes.func,
};

export default InputSearchBar;
