import { useCallback, useEffect, useRef } from 'react';

import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import { faEraser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type SignatureProps = {
  width?: number;
  height?: number;
  penColor?: string;
  onChange?: (data: string) => void;
  disabled?: boolean;
  initialSignature?: string;
};

export const Signature = (props: SignatureProps) => {
  const {
    width = 500,
    height = 200,
    onChange,
    penColor = '#101010',
    disabled,
    initialSignature,
  } = props;
  const { t } = useTranslation();

  const _ref = useRef<SignatureCanvas>(null);

  const handleClearPad = useCallback(() => {
    _ref.current?.clear();
  }, [_ref]);

  const handleCompleteSign = useCallback(() => {
    const data = _ref.current?.toDataURL();
    if (data) {
      onChange?.(data);
    }
  }, [_ref, onChange]);

  useEffect(() => {
    if (initialSignature) {
      _ref.current?.fromDataURL(initialSignature);
    }
  }, [initialSignature]);

  useEffect(() => {
    if (disabled) {
      _ref.current?.off();
    } else {
      _ref.current?.on();
    }
  }, [disabled]);

  return (
    <div className="w-fit">
      <div className={`border mb-1 ${disabled ? 'cursor-not-allowed' : ''}`}>
        <SignatureCanvas
          ref={_ref}
          onEnd={handleCompleteSign}
          penColor={penColor}
          canvasProps={{ width, height, className: 'sigCanvas' }}
        />
      </div>
      <button
        type="button"
        onClick={handleClearPad}
        className={`inline-flex-full-center gap-2 ${disabled ? 'cursor-not-allowed' : ''}`}
      >
        <FontAwesomeIcon icon={faEraser} />
        {t('erase')}
      </button>
    </div>
  );
};
