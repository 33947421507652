import { Carousel as CarouselLib } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { CarouselArrow } from './carousel-arrow';

type CarouselProps = {
  images: string[] | { path: string; url: string }[];
  imagesChildren?: (img: string) => JSX.Element;
  initialSlide?: number;
};

export const Carousel = (props: CarouselProps) => {
  const { images, initialSlide = 0, imagesChildren } = props;

  return (
    <CarouselLib
      selectedItem={initialSlide}
      infiniteLoop={false}
      renderArrowPrev={(clickHandler: () => void, hasPrev: boolean) => (
        <CarouselArrow onClick={clickHandler} display={hasPrev} direction="left" />
      )}
      renderArrowNext={(clickHandler: () => void, hasNext: boolean) => (
        <CarouselArrow onClick={clickHandler} display={hasNext} direction="right" />
      )}
    >
      {images.map((img, index) => {
        const isPathImg = typeof img === 'string';
        return (
          <div key={index}>
            <img src={isPathImg ? img : img.url} alt={`slide ${index}`} className="h-4/5" />
            {imagesChildren && !isPathImg ? imagesChildren(img.path) : null}
          </div>
        );
      })}
    </CarouselLib>
  );
};
