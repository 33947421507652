import useSWR from 'swr';

import { ENDPOINTS } from '@/api/endpoints';
import { getClinicById } from '@/api/clinics';

export const useGetClinicById = ({ clinic_id }: { clinic_id?: string }) => {
  const { data, isLoading, mutate } = useSWR([ENDPOINTS.CLINICS, clinic_id], () =>
    getClinicById({ clinic_id })
  );

  return {
    loading: isLoading,
    clinic: data,
    refetchSites: mutate,
  };
};
