import { toast } from 'react-toastify';

export function toast_error(message: string, includeErrorLabel = true) {
  toast.error(`${includeErrorLabel ? 'Error: ' : ''}${message}`, {
    autoClose: 5000,
    position: 'top-right',
  });
}

export function toast_success(message: string) {
  toast.success(message, {
    autoClose: 5000,
    position: 'top-right',
  });
}

export function toast_warning(message: string) {
  toast.warn(message, {
    autoClose: 5000,
    position: 'top-right',
  });
}
