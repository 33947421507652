import { useCallback, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';

import { POST, PUT } from '@/api/base';
import { ENDPOINTS } from '@/api/endpoints';
import { useGetSiteById } from '@/hooks/api/use-get-site-by-id';
import { toast_success } from '@/libs/toast-wrappers';

import { Hr } from '@/components/divider';
import { InputField } from '@/components/form/input-field';
import { Switch } from '@/components/form/switch';

type InputTypes = {
  description: string;
  timezone: string;
  active: boolean;
};

const defaultValues = {
  description: '',
  timezone: '',
  active: false,
};

export function SiteForm() {
  const { siteId, clinicId } = useParams();
  const isEditing = Boolean(siteId);

  const { site, loading } = useGetSiteById({ site_id: siteId });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<InputTypes>({ defaultValues });

  const { onFormClose } = useOutletContext<{
    onFormClose: () => Promise<void>;
  }>();

  const { t } = useTranslation();

  const onSubmitUpdateSite: SubmitHandler<InputTypes> = async (data) => {
    if (isEditing && siteId) {
      const response = await PUT(
        `${ENDPOINTS.SITE_BY_ID}`,
        { clinic_id: site?.clinic_id, ...data },
        { parameters: { siteId } }
      );

      if (response) {
        await onFormClose();
        await mutate([ENDPOINTS.SITES, clinicId]);
        toast_success(t('site-updated').replace('clinicId', siteId.slice(0, 5)));
        return;
      }
    }
    const response = await POST(ENDPOINTS.SITES, { clinic_id: clinicId, ...data });

    if (response) {
      await onFormClose();
      await mutate([ENDPOINTS.SITES, clinicId]);
      toast_success(t('site-saved'));
    }
  };

  const fillForm = useCallback(() => {
    setValue('description', site?.description ?? '');
    setValue('timezone', site?.timezone ?? '');
    setValue('active', site?.active ?? false);
  }, [site, setValue]);

  useEffect(() => {
    fillForm();
  }, [fillForm, site]);

  return (
    <form onSubmit={handleSubmit(onSubmitUpdateSite)}>
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mb-8">
        <div className="sm:col-span-3">
          <InputField
            disabled={loading}
            {...register('description', {
              required: { value: true, message: t('clinic-name-error') },
            })}
            label={t('description-label')}
            errorMessage={errors.description?.message}
          />
        </div>
        <div className="sm:col-span-3">
          <InputField disabled={loading} {...register('timezone')} label={t('timezone-label')} />
        </div>
        <fieldset className={`InputFieldBlock`}>
          <div className="mt-5 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            <label className="input-label mr-4">{t('is-active-label')}</label>
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <Switch checked={field.value} onCheckedChange={field.onChange} disabled={loading} />
              )}
            />
          </div>
        </fieldset>
      </div>
      <Hr />
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          onClick={onFormClose}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t('cancel')}
        </button>
        <button type="submit" className="button">
          {t('save')}
        </button>
      </div>
    </form>
  );
}
