import { useEffect, useState } from 'react';

import {
  faHome,
  faFolderOpen,
  faUsers,
  faFileInvoiceDollar,
  faFileContract,
  faSignOut,
  faFileCheck,
  faUserShield,
  IconDefinition,
  faUserCircle,
  faHospital,
} from '@fortawesome/pro-light-svg-icons';

import { pre_load_translate } from '@/utils/translations';
import { ROUTES, RESTRICTED_ROUTES } from '@/router/routes';
import visionquest_logout from '@/libs/session-management-old';
import { logout } from '@/libs/session-management';
import { useUserProfile } from '@/states/user-profile';
import { ROLES } from '@/utils/constants';

export type NavItem = {
  label: string;
  icon: IconDefinition;
  href?: string;
  subItems?: NavItem[];
  callback?: () => void;
};

const ENV = import.meta.env;
const useAPIGateway = ENV.VITE_USE_API_GATEWAY === 'true';

const getMainNavItems = (role?: ROLES) => {
  const nav_links: NavItem[] = [
    {
      label: pre_load_translate('nav-dashboard'),
      href: ROUTES.DASHBOARD,
      icon: faHome,
    },
    {
      label: pre_load_translate('nav-cases'),
      href: ROUTES.CASES,
      icon: faFolderOpen,
    },
    {
      label: pre_load_translate('nav-users'),
      href: ROUTES.USERS,
      icon: faUsers,
    },
    {
      label: pre_load_translate('nav-grading'),
      href: ROUTES.GRADING,
      icon: faFileCheck,
    },
    {
      label: pre_load_translate('nav-billing'),
      href: ROUTES.BILLING,
      icon: faFileInvoiceDollar,
    },
    {
      label: pre_load_translate('nav-admin'),
      icon: faUserShield,
      subItems: [
        {
          label: pre_load_translate('nav-admin-users'),
          href: ROUTES.ADMIN_USERS,
          icon: faUsers,
        },
        {
          label: pre_load_translate('nav-admin-clinics'),
          href: ROUTES.ADMIN_CLINICS,
          icon: faHospital,
        },
        {
          label: pre_load_translate('nav-admin-telehealth-clinicians'),
          href: ROUTES.ADMIN_TELEHEALTH_CLINICIANS,
          icon: faUserShield,
        },
      ],
    },
    {
      label: pre_load_translate('nav-profile'),
      href: ROUTES.PROFILE,
      icon: faUserCircle,
    },
    {
      label: pre_load_translate('nav-legal'),
      href: ROUTES.LEGAL,
      icon: faFileContract,
    },
  ].filter((navItem) => {
    if (!role) return true;

    const allowed = RESTRICTED_ROUTES[role];
    if (!allowed) return true;

    if (navItem.href) {
      return allowed.includes(navItem.href);
    }

    if (navItem.subItems) {
      return navItem.subItems.some((subItem) => allowed.includes(subItem.href ?? ''));
    }

    return false;
  });

  return nav_links;
};

export const useNavigationItems = () => {
  const { profile, clear } = useUserProfile();
  const [navItems, setNavItems] = useState<NavItem[]>(getMainNavItems(profile.role));

  const bottomNavLinks: NavItem[] = [
    {
      label: pre_load_translate('nav-logout'),
      icon: faSignOut,
      callback: () => {
        // on logout, we need to delete all the cookies that get set on login
        // This is a helper function which signs the user out of vision_quest
        clear();
        if (useAPIGateway) {
          visionquest_logout();
        } else {
          logout();
        }
        return false;
      },
    },
  ];

  useEffect(() => {
    if (!profile.role) return;
    setNavItems(getMainNavItems(profile.role));
  }, [profile.role]);

  return {
    navItems,
    bottomNavLinks,
  };
};
