export type ROLES =
  | 'clinicadmin'
  | 'clinicuser'
  | 'caseadmin'
  | 'telehealthclinician'
  | 'testgrader'
  | 'vitaziadmin';

export const ROLES_LABELS = [
  { clinicadmin: 'Clinic Admin' },
  { clinicuser: 'Clinic User' },
  { caseadming: 'Case Admin' },
  { telehealthclinician: 'Telehealth Clinician' },
  { vitaziadmin: 'Vitazi Admin' },
];
