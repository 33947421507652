/*
KitchenSink - A KitchenSink of our react components.
*/

import React from 'react';
import aws_api_call from '../react-utils/src/aws_api_call.js';

import InputCheckbox from '../lib-medical-portal/components/FormInputs/InputCheckbox.jsx';
import InputFieldBlock from '../lib-medical-portal/components/FormInputs/InputFieldBlock.jsx';
import InputDropdownBlock from '../lib-medical-portal/components/FormInputs/InputDropdownBlock.jsx';
import InputRadioBlock from '../lib-medical-portal/components/FormInputs/InputRadioBlock.jsx';
import InputSearchBar from '../lib-medical-portal/components/FormInputs/InputSearchBar.jsx';
import StatBlock from '../lib-medical-portal/components/StatBlock/StatBlock.jsx';
import IconButton from '../lib-medical-portal/components/IconButton.jsx';
import FileManager from '../lib-medical-portal/components/FileManager/FileManager.jsx';
import CreateCaseForm from '../components/CreateCaseForm.jsx';
import FlyoutMenu from '../lib-medical-portal/components/FlyoutMenu.jsx';
import CustomTable from '../lib-medical-portal/components/CustomTable';

import TestMaster from '../models/__tests__/TestMaster';
import TestObjects from '../models/__tests__/TestObjects';

//test files

const blank_64x64_jpeg = atob(
  '/9j/4AAQSkZJRgABAQEASABIAAD//gATQ3JlYXRlZCB3aXRoIEdJTVD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCABAAEADAREAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAj/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAGqQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAf//EABQQAQAAAAAAAAAAAAAAAAAAAGD/2gAIAQEAAQUCAf/EABQRAQAAAAAAAAAAAAAAAAAAAGD/2gAIAQMBAT8BAf/EABQRAQAAAAAAAAAAAAAAAAAAAGD/2gAIAQIBAT8BAf/EABQQAQAAAAAAAAAAAAAAAAAAAGD/2gAIAQEABj8CAf/EABQQAQAAAAAAAAAAAAAAAAAAAGD/2gAIAQEAAT8hAf/aAAwDAQACAAMAAAAQkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk/8QAFBEBAAAAAAAAAAAAAAAAAAAAYP/aAAgBAwEBPxAB/8QAFBEBAAAAAAAAAAAAAAAAAAAAYP/aAAgBAgEBPxAB/8QAFBABAAAAAAAAAAAAAAAAAAAAYP/aAAgBAQABPxAB/9k='
);

//const blank_black_64x64_png=atob('iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAIAAAAlC+aJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4gIaEiU3ndD2RAAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAiSURBVGje7cEBDQAAAMKg909tDwcUAAAAAAAAAAAAAADwbjBAAAESnZL4AAAAAElFTkSuQmCC');

/*
props (component-level arguments):
	
*/
class KitchenSink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [],
      case_id: '',
    };

    this.create_case_id = this.create_case_id.bind(this);
  }

  create_case_id() {
    //POST /cases
    aws_api_call.post_rqst(
      '/cases',
      JSON.stringify({ patientID: 'N/A' }),
      (function (cmpnt) {
        return function (xhr) {
          console.log('create_case_id Success!');

          let response = xhr.response;
          console.log(response);
          let data = JSON.parse(response);

          if (data.hasOwnProperty('case')) {
            let out_elem = document.getElementById('case-id');
            if (out_elem !== null) {
              out_elem.innerText = data['case'];
              cmpnt.setState({
                case_id: data['case'],
              });
            }
            //debug
            let file_name = 'blank_64x64.jpg';
            let file_data = blank_64x64_jpeg;
            let file_type = 'image/jpeg';
            cmpnt.put_case_file(data['case'], file_name, file_type, file_data);
          }
        };
      })(this),
      { 'Content-Type': 'application/json' },
      function (xhr) {
        console.log('create_case_id Error ' + xhr.status);
        console.log(xhr.response);
      },
      function (xhr) {
        console.log('create_case_id No Data');
      },
      function (e) {
        console.log('create_case_id Progress ' + (e.loaded / Math.max(e.total, 1)) * 100 + '%');
      }
    );
  }

  put_case_file(case_id, file_name, file_type, file_data) {
    //PUT /cases/{case_id}/{file_name}
    aws_api_call.put_rqst(
      '/cases/' + case_id + '/' + file_name,
      file_data,
      (function (cmpnt, case_id) {
        return function (xhr) {
          console.log('put_case_file Success!');

          let response = xhr.response;
          console.log(response);

          //debug
          let case_type = 'zip';
          switch (file_type) {
            case 'application/zip':
              case_type = 'zip';
              break;
            case 'image/jpeg':
              case_type = 'jpeg';
              break;
            default:
              case_type = 'list';
              break;
          }
          cmpnt.eyestar_init(case_id, case_type, 1);
        };
      })(this, case_id),
      { 'Content-Type': file_type, Accept: file_type },
      function (xhr) {
        console.log('put_case_file Error ' + xhr.status);
        console.log(xhr.response);
      },
      function (xhr) {
        console.log('put_case_file No Data');
      },
      function (e) {
        console.log('put_case_file Progress ' + (e.loaded / Math.max(e.total, 1)) * 100 + '%');
      }
    );
  }

  eyestar_init(case_id, case_type, quality = 1) {
    //POST /cases/{case_id}?type={case_type}&quality={quality}
    aws_api_call.post_rqst(
      '/cases/' + case_id + '?type=' + case_type + '&quality=' + quality,
      '',
      (function (cmpnt, case_id) {
        return function (xhr) {
          console.log('eyestar_init Success!');
          let response = xhr.response;
          console.log(response);

          //debug
          cmpnt.eyestar_generate_report(case_id, 'score');
        };
      })(this, case_id),
      {},
      function (xhr) {
        console.log('eyestar_init Error ' + xhr.status);
        console.log(xhr.response);
      }
    );
  }

  eyestar_generate_report(case_id, case_type = 'score') {
    //GET /cases/{case_id}?type={case_type}
    aws_api_call.get_rqst(
      '/cases/' + case_id + '?type=' + case_type,
      (function (cmpnt, case_id) {
        return function (xhr) {
          console.log('eyestar_generate_report Success!');
          let response = xhr.response;
          console.log(response);

          //debug
          cmpnt.eyestar_dl_report(case_id);
        };
      })(this, case_id),
      {},
      function (xhr) {
        console.log('eyestar_generate_report Error ' + xhr.status);
        console.log(xhr.response);
      }
    );
  }

  eyestar_dl_report(case_id) {
    //GET /cases/{case_id}/report.pdf
    aws_api_call.get_rqst(
      '/cases/' + case_id + '/report.pdf',
      (function (cmpnt, case_id) {
        return function (xhr) {
          console.log('eyestar_dl_report Success!');
          let response = xhr.response;
          console.log(response);
        };
      })(this, case_id),
      {},
      function (xhr) {
        console.log('eyestar_dl_report Error ' + xhr.status);
        console.log(xhr.response);
      }
    );
  }

  render_stat_block() {
    return (
      <StatBlock
        stat_lines={[
          {
            label: 'Total Users',
            value: 16,
          },
          {
            label: 'Total Cases Created',
            value: 128,
          },
          {
            label: 'Total Photos Uploaded',
            value: 1024,
          },
        ]}
        title="Overall Stats"
      />
    );
  }

  render_buttons() {
    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x">
          <div className="small-12 cell button-row-default">
            <button className="button">default</button>
            <button className="button" disabled>
              default
            </button>
          </div>
          <div className="small-12 cell button-row-inverted">
            <button className="button inverted">inverted</button>
            <button disabled className="button inverted">
              inverted
            </button>
          </div>
          <div className="small-12 cell button-row-outline">
            <button className="button outline">outline</button>
          </div>
          <div className="small-12 cell button-row-plus-outline">
            <button className="button button-small button-icon">
              <span className="fa fa-plus"></span>small
            </button>
            <button disabled className="button button-small button-icon">
              <span className="fa fa-plus"></span>small
            </button>
          </div>
          <div className="small-12 cell button-row-plus">
            <button className="button inverted button-small">small</button>
            <button disabled className="button inverted button-small">
              small
            </button>
          </div>
          <div className="small-12 cell button-icon-danger">
            <IconButton buttonColorType="danger" foregroundIcon="far fa-trash-alt" />
            <IconButton
              disabled={true}
              buttonColorType="danger"
              foregroundIcon="far fa-trash-alt"
            />
          </div>
          <div className="small-12 cell button-icon-primary">
            <IconButton buttonColorType="primary" foregroundIcon="far fa-arrow-alt-circle-down" />
            <IconButton
              disabled={true}
              buttonColorType="primary"
              foregroundIcon="far fa-arrow-alt-circle-down"
              callback={null}
            />
          </div>
          <div className="small-12 cell button-icon">
            <IconButton foregroundIcon="far fa-edit" />
            <IconButton disabled={true} foregroundIcon="far fa-edit" />
          </div>
          <div className="small-12 cell large-close-button-icon">
            <IconButton
              extraClasses="primaryHover"
              foregroundIcon="fas fa-times"
              backgroundIcon="fa fa-square-full"
              iconSize="fa-2x"
            />
          </div>
          <div className="small-12 cell button-row-times-subtle">
            <button className="button button-small button-icon button-subtle">
              <span className="fa fa-times"></span>small
            </button>
            <button disabled className="button button-small button-icon button-subtle">
              <span className="fa fa-times"></span>small
            </button>
          </div>
          <div className="small-12 cell button-row-times-subtle">
            <button className="button button-small button-icon icon-right button-subtle">
              small<span className="fa fa-times"></span>
            </button>
            <button disabled className="button button-small button-icon icon-right button-subtle">
              small<span className="fa fa-times"></span>
            </button>
          </div>
          <div className="small-12 cell link-row">
            <a href="#primaryButton">link text</a>
            <br />
            <a className="grey-color" href="#secondaryButton">
              link text
            </a>
          </div>
        </div>
      </div>
    );
  }

  render_file_upload() {
    return (
      <div className="grid-container">
        <div className="grid-x">
          <FileManager
            label="Retinal Images"
            direction="Upload up to 10 images. Minimum of 2 images required to submit case."
            idUniqueIdentifier="test"
          />
        </div>
      </div>
    );
  }

  test() {
    console.log('this is a function passed down to < InputSearchBar >');
  }

  render_input_components() {
    var array = [{ m: 'Male' }, { f: 'Female' }, { o: 'Other' }];

    let radioArray = [{ a: 'A' }, { b: 'B' }, { c: 'C' }];

    return (
      <div className="cell small-12">
        <InputFieldBlock title="Text Input" inputHint="..." fieldName="inputFieldTest" />
        <InputDropdownBlock title="Dropdown" optionData={array} />
        <InputRadioBlock
          title="Radio buttons"
          data={radioArray}
          radioName="yes-no-name"
          radioId="yes-no-name-id"
        />
        <InputSearchBar fieldHint="Search..." fieldId="abc" searchClickEvent={this.test} />
        <InputCheckbox id="checkboxtest" required={false} value="Yes" desc="Do you want this?" />
      </div>
    );
  }

  actionOne() {
    console.log('one');
  }

  actionTwo() {
    console.log('two');
  }

  render() {
    // This is called here only for testing purposes. This will/should be moved later.
    TestMaster.runModelTest();

    let pair = {
      Invalid: '#808080',
      'No Action Needed': '#14C474',
      'See Ophthalmologist': '#E34B2D',
      Incomplete: '#FBB040',
      Complete: '#14C474',
    };

    let iconColorEdit = 'gray';
    let iconColorDownload = 'blue';

    let caseArray = [];
    let case1 = TestObjects.createCase();
    case1.setResult('Invalid');
    case1.setAction(this.actionOne);
    case1.setFaIconName('far fa-edit');
    case1.setStringColorPair(pair);
    case1.setIconColor(iconColorEdit);
    let case2 = TestObjects.createCase();
    case2.setResult('No Action Needed');
    case2.setAction(this.actionTwo);
    case2.setFaIconName('far fa-arrow-alt-circle-down');
    case2.setStatus('Complete');
    case2.setStringColorPair(pair);
    case2.setIconColor(iconColorDownload);
    let case3 = TestObjects.createCase();
    case3.setAction(this.actionOne);
    case3.setFaIconName('far fa-arrow-alt-circle-down');
    case3.setStatus('Complete');
    case3.setStringColorPair(pair);
    case3.setIconColor(iconColorDownload);
    caseArray.push(case1);
    caseArray.push(case2);
    caseArray.push(case3);

    let fields = [
      { title: 'Patient ID', attribute: 'patientId' },
      { title: 'Created By', attribute: 'createdBy' },
      { title: 'Date Created', attribute: 'createdAt' },
      { title: 'Result', attribute: 'result' },
      { title: 'Status', attribute: 'status' },
      { title: '', attribute: 'faIconName' },
    ];

    return (
      <div className="KitchenSink">
        <main>
          <div className="grid-container">
            <div className="grid-x grid-padding-x">
              <div className="small-12 large-4 cell">{this.render_stat_block()}</div>
            </div>
            <br />
            <br />
            {this.render_buttons()}
            <br />
            <br />
            {this.render_file_upload()}
            <br />
            <br />
            {this.render_input_components()}
            <br />
            <br />
            <div className="grid-x grid-padding-x">
              <div className="small-12 cell button-row-default">
                <button
                  className="button"
                  id="create-case-button"
                  onClick={(function (cmpnt) {
                    return function (e) {
                      cmpnt.create_case_id();
                    };
                  })(this)}
                >
                  Create a new Case ID
                </button>
                <p>
                  Case ID: <span id="case-id">- No Case Created -</span>
                </p>
              </div>
            </div>
            <FlyoutMenu>
              <CreateCaseForm />
            </FlyoutMenu>
            <CustomTable fields={fields} content={caseArray} sortable={true} parentContext={this} />
          </div>
        </main>
      </div>
    );
  }
}

export default KitchenSink;
