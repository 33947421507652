import { Outlet } from 'react-router-dom';

// hooks
import { useNavigationListener } from '@/hooks/use-navigation-listener';

// Here we can wrap all the routes with providers or enable hooks for navigation
export function RouterWrapper() {
  useNavigationListener();

  return (
    <>
      <Outlet />
    </>
  );
}
