const ENV = import.meta.env;

type ENV = string | undefined;

export const API_BASE_ENDPOINT: ENV = ENV.VITE_API_BASE_ENDPOINT ?? '';
export const AWS_HOST: ENV = ENV.VITE_AWS_HOST ?? '';
export const AWS_REGION_ID: ENV = ENV.VITE_AWS_REGION_ID ?? '';
export const AWS_SERVICE: ENV = ENV.VITE_AWS_SERVICE ?? '';
export const AWS_BASE_PATH: ENV = ENV.VITE_AWS_BASE_PATH ?? '';
export const AWS_ACCESS_KEY_ID: ENV = ENV.VITE_AWS_ACCESS_KEY_ID ?? '';
export const AWS_SECRET_ACCESS_KEY: ENV = ENV.VITE_AWS_SECRET_ACCESS_KEY ?? '';
