import { create } from 'zustand';
import { produce } from 'immer';
import { jwtDecode } from 'jwt-decode';

import { DecodeTokenInfo, setSessionToken } from '@/libs/session-management';

import { UserProfileState } from './types';

export const useUserProfile = create<UserProfileState>((set) => ({
  profile: {
    email: undefined,
  },
  setAuthenticationData: (token) =>
    set(
      produce((state: UserProfileState) => {
        const decodedPayload: DecodeTokenInfo = jwtDecode(token);

        state.profile.email = decodedPayload.email;
        state.profile.clinic_name = decodedPayload.clinic_name;
        state.profile.clinic = decodedPayload.clinic;
        state.profile.role = decodedPayload.role;
        state.profile.account_id = decodedPayload.account_id;

        setSessionToken(token);
      })
    ),
  setEmail: (email) =>
    set(
      produce((state: UserProfileState) => {
        state.profile.email = email;
      })
    ),
  setClinic: (clinic) =>
    set(
      produce((state: UserProfileState) => {
        state.profile.clinic = clinic;
      })
    ),
  setRole: (role) =>
    set(
      produce((state: UserProfileState) => {
        state.profile.role = role;
      })
    ),
  setUserProfile: (profile) =>
    set(
      produce((state: UserProfileState) => {
        if (!profile.email) return;
        state.profile = { ...profile, ...state.profile };
      })
    ),
  clear: () =>
    set(
      produce((state: UserProfileState) => {
        state.profile = { email: undefined };
      })
    ),
}));
