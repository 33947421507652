import { useCallback } from 'react';

import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type FetchStatesProps = {
  loading?: boolean;
  error?: string;
  children?: React.ReactNode;
  empty?: boolean;
  reloadOnError?: boolean;
  refetch?: () => void;
};

export const FetchStates = (props: FetchStatesProps) => {
  const { error, loading, children, empty = false, reloadOnError, refetch } = props;

  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  if (loading) {
    return (
      <section className="flex-full-center">
        <span className="mr-2 animate-spin">
          <FontAwesomeIcon icon={faSpinner} />
        </span>
        <p>Loading...</p>
      </section>
    );
  }

  if (error) {
    return (
      <section className="flex-full-center">
        <p>Error: {error}</p>
        <button onClick={reloadOnError ? handleReload : refetch}>Retry</button>
      </section>
    );
  }

  if (empty) return <div className="flex-full-center">No data to display</div>;

  return <>{children}</>;
};
