/*
InputTextarea, an input field and its title

An InputTextarea item consists of a title and an input field
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component level arguments):
	title: the title of the input field
	titleClass: an additional class for the title
	fieldClass: the class of the input tag
	fieldName: the for attribute of the label as well as the id and name of the input field 
	fieldHint: the text placeholder of the input tag
	fieldRequired: boolean to see if a user input is mandatory
	defaultValue: default value for this input field
*/

export class InputTextarea extends React.Component {
  render() {
    return (
      <div className="InputTextarea">
        <label className={'input-title ' + this.props.titleClass} htmlFor={this.props.fieldName}>
          {this.props.title}
        </label>
        <textarea
          className={'input-field ' + this.props.fieldClass}
          id={this.props.fieldName}
          defaultValue={this.props.defaultValue}
          name={this.props.fieldName}
          placeholder={this.props.fieldHint}
          required={this.props.fieldRequired}
          onChange={this.props.callback}
          readOnly={this.props.readOnly}
        />
      </div>
    );
  }
}

InputTextarea.defaultProps = {
  defaultValue: '',
  titleClass: '',
  fieldHint: '...',
  fieldClass: '',
  fieldRequired: false,
};

InputTextarea.propTypes = {
  title: PropTypes.string.isRequired,
  fieldRequired: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
};

export default InputTextarea;
