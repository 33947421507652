/*
StatBlock, a display to show summaries of statistics for user information

A stat block display consists of the following:
	A title, directly in this component
	One or more StatLineItem components
*/

import React from 'react';
import PropTypes from 'prop-types';

import StatLineItem from './StatLineItem.jsx';

/*
props (component level arguments):
	title: the title for this display
	stat_lines: a list of stat objects, each of which is expected to contain the following
		label: the label for this line item (as a string)
		value: the value for this line item (numeric)
	timeframe_select: a callback for interaction with the "All Time" select item
*/
class StatBlock extends React.Component {
  render() {
    if (this.props.stat_lines.length === 0) {
      return null;
    }

    return (
      <div className="StatBlock">
        <h2 className="statblock-title">{this.props.title}</h2>
        <div className="statblock-data">
          {this.props.stat_lines.map(function (stat_line, idx, stat_lines) {
            return (
              <StatLineItem
                key={'stat-line-' + idx}
                label={stat_line.label}
                value={stat_line.value.toString()}
                extraClasses={idx === stat_lines.length - 1 ? 'bottom-margin-0' : ''}
              />
            );
          })}
          {this.props.timeframe_select !== null ? (
            <select className="timeframe-select" onChange={this.props.timeframe_select}>
              <option value="all:all">{this.props.time_title_all}</option>
              <option value="hours:24">{this.props.time_title_hours}</option>
              <option value="days:7">{this.props.time_title_week}</option>
              <option value="days:30">{this.props.time_title_month}</option>
            </select>
          ) : null}
        </div>
      </div>
    );
  }
}

StatBlock.defaultProps = {
  title: 'Statistics',
  stat_lines: [],
  timeframe_select: null,
  time_title_all: 'All Time',
  time_title_hours: 'Past 24 Hours',
  time_title_week: 'Past Week',
  time_title_month: 'Past Month',
};

StatBlock.propTypes = {
  title: PropTypes.string.isRequired,
  stat_lines: PropTypes.array.isRequired,
  timeframe_select: PropTypes.func.isRequired,
  time_title_all: PropTypes.string.isRequired,
  time_title_hours: PropTypes.string.isRequired,
  time_title_week: PropTypes.string.isRequired,
  time_title_month: PropTypes.string.isRequired,
};

export default StatBlock;
