export const errorlog = (error: Error, path?: string, devmessage?: string) => {
  if (import.meta.env.DEV) {
    console.error('errorlog', {
      error,
      path,
      'dev-message': devmessage,
    });
  } else {
    // here we can set up a tool to track errors
    // at this moment we are not using any tool to
  }
};
