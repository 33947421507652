import type { HTMLAttributes, ReactNode } from 'react';

import clsx from 'clsx';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type AlertProps = HTMLAttributes<HTMLDivElement> & {
  title: string;
  type: 'warning' | 'success' | 'error';
  children?: ReactNode;
};

const MAP_TYPE_TO_COLOR = {
  warning: {
    bg: 'bg-yellow-50',
    title: 'text-yellow-800',
    icon: 'text-yellow-400',
    body: 'text-yellow-700',
  },
  success: {
    bg: 'bg-green-50',
    title: 'text-green-800',
    icon: 'text-green-400',
    body: 'text-green-700',
  },
  error: {
    bg: 'bg-red-50',
    title: 'text-red-800',
    icon: 'text-red-400',
    body: 'text-red-700',
  },
};

export function Alert(props: AlertProps) {
  const { title, type, children, className, ...rest } = props;

  const styles = MAP_TYPE_TO_COLOR[type];

  return (
    <div className={clsx('rounded-md p-4', styles.bg, className)} {...rest}>
      <div className="flex">
        <div className={clsx('flex-shrink-0', styles.icon)}>
          <FontAwesomeIcon icon={faExclamationCircle} className="h-5 w-5" />
        </div>
        <div className="ml-3">
          <h3 className={clsx('text-sm font-medium', styles.title)}>{title}</h3>
          <div className={clsx('mt-2 text-sm', styles.body)}>{children}</div>
        </div>
      </div>
    </div>
  );
}
