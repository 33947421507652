import { InputHTMLAttributes, forwardRef } from 'react';

type RadioGroupInputProps = InputHTMLAttributes<HTMLInputElement> & {
  id: string;
  label: string;
};

export const RadioGroupOption = forwardRef<HTMLInputElement, RadioGroupInputProps>((props, ref) => {
  const { id, label, ...rest } = props;

  return (
    <div className="inline-flex-full-center ">
      <input {...rest} ref={ref} id={id} type="radio" className="m-0" />
      <label htmlFor={id} className="font-medium text-gray-500">
        {label}
      </label>
    </div>
  );
});

RadioGroupOption.displayName = 'RadioGroupOption';
