import React from 'react';

export const Copyright = () => {
  return (
    <div className="footer-row">
      <p>
        &copy; {new Date().getFullYear()}
        <a className="footer-link mx-1" href="https://www.vitazi.ai/">
          Vitazi.ai
        </a>
        Inc. All Rights reserved. |
        <a className="footer-link mx-1" href="https://www.vitazi.ai/terms-of-use">
          Terms of Use
        </a>
        {'|'}
        <a className="footer-link mx-1" href="https://www.vitazi.ai/privacy-policy">
          Privacy Policy
        </a>
      </p>
    </div>
  );
};
