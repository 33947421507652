import { useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';

import { useUserProfile } from '@/states/user-profile';
import { toast_error, toast_success } from '@/libs/toast-wrappers';
import { postLicenseRequest } from '@/api/licenses';
import { errorlog } from '@/libs/error-logs';
import {
  ERROR_FORM_INCOMPLETE_OR_INCORRECT,
  ERROR_PDF_FILE_UPLOAD,
  SUCCESS_LICENSE_REQUESTED,
} from '@/utils/messages';

import { InputField } from '@/components/form/input-field';

type InputTypes = {
  license: string;
  state: string;
  expiration: string;
  file: FileList;
};

type NewLicenseFormProps = {
  onSuccessSave: () => void;
};

export function NewLicenseForm(props: NewLicenseFormProps) {
  const { onSuccessSave } = props;
  const [saving, setSaving] = useState(false);
  const { profile } = useUserProfile((state) => ({ profile: state.profile }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InputTypes>();

  const { t } = useTranslation();

  const TODAY = new Date().toISOString().split('T')[0];

  const onSaveNewLicense: SubmitHandler<InputTypes> = async (data) => {
    if (!profile) return;

    if (Array.from(Object.values(errors)).some((value) => value)) {
      toast_error(ERROR_FORM_INCOMPLETE_OR_INCORRECT);
      return;
    }

    try {
      const license_file = data.file[0];
      if (license_file.type !== 'application/pdf') {
        toast_error(ERROR_PDF_FILE_UPLOAD);
        return;
      }

      // save the file
      setSaving(true);
      if (!profile?.email) return;

      await postLicenseRequest({
        email: profile.email,
        country: profile.country ?? '',
        state: data.state,
        license: data.license,
        expiration: data.expiration,
        license_file,
      }).then((response) => {
        if (response) {
          toast_success(SUCCESS_LICENSE_REQUESTED);
          onSuccessSave();
          return;
        }
      });
    } catch (error) {
      errorlog(error as Error, 'src/components/profile/new-license-form.tsx');
    }
    setSaving(false);
  };

  return (
    <form className="grid grid-cols-3 gap-4" onSubmit={handleSubmit(onSaveNewLicense)}>
      <InputField
        required
        disabled={saving}
        label={t('profile-license-code')}
        {...register('license', { required: true })}
      />
      <InputField
        required
        disabled={saving}
        label={t('profile-license-state')}
        {...register('state', { required: true })}
      />
      <InputField
        required
        disabled={saving}
        label={t('profile-license-exp')}
        type="date"
        min={TODAY}
        {...register('expiration', { required: true })}
      />
      <input
        required
        type="file"
        className="col-span-2"
        accept="application/pdf"
        {...register('file', { required: true })}
      />
      <div className="flex justify-end">
        <button type="submit" className="button inline-flex-full-center gap-2" disabled={saving}>
          <span>{t('submit')}</span>
          <FontAwesomeIcon icon={faFloppyDisk} size="xl" />
        </button>
      </div>
    </form>
  );
}
