import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

export function SimpleError({ errorMessage }: { errorMessage: string }) {
  const { t } = useTranslation();
  return (
    <section className="flex-full-center">
      <p>{t(errorMessage)}</p>
      <Link to={'/'}>{t('back-home')}</Link>
    </section>
  );
}
