import { useCallback } from 'react';

import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

import { ALERT_CONFIG, swalert } from '@/libs/sweetalert';
import { SignGradeForm } from '@/hooks/use-grade-form-logic';
import { devlog } from '@/libs/dev-logs';

import { RadioGroup } from '@/components/form/radio-group';
import { RadioGroupOption } from '@/components/form/radio-group-opt';

type GradeFormUrgentConfirmProps = {
  register: UseFormRegister<SignGradeForm>;
  watch: UseFormWatch<SignGradeForm>;
  setValue: UseFormSetValue<SignGradeForm>;
  disabled?: boolean;
};

/**
 * The sub questions for the grading form
 */
export function GradeFormUrgentConfirm(props: GradeFormUrgentConfirmProps) {
  const { register, watch, setValue, disabled } = props;
  const { t } = useTranslation();

  const handleActiveUrgentFollowUp = useCallback(async () => {
    try {
      const result = await swalert.fire({
        ...ALERT_CONFIG,
        title: t('grade-urgency-confirm-title'),
        text: `${t('grade-urgency-confirm-text')} ${t('grade-urgency-tooltip')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('yes'),
        cancelButtonText: t('no'),
      });
      if (result.isConfirmed) {
        setValue('urgent_str', 'yes');
      }
    } catch (error) {
      devlog(error);
    }
  }, [t, setValue]);

  return (
    <>
      <RadioGroup
        className="my-8"
        label={
          <>
            {t('grade-urgency-button')}
            <span className="text-red-500 ml-1" data-tooltip-id="urgent-button-label">
              <FontAwesomeIcon icon={faCircleExclamation} size="lg" />
            </span>
            <Tooltip id="urgent-button-label" place="top">
              {t('grade-urgency-tooltip')}
            </Tooltip>
          </>
        }
      >
        <RadioGroupOption
          label={t('yes')}
          id="urgent-yes"
          disabled={disabled}
          checked={watch('urgent_str') === 'yes'}
          onClick={handleActiveUrgentFollowUp}
          onChange={() => devlog('Fake onChange function: Changing urgent button')}
        />
        <RadioGroupOption
          label={t('no')}
          id="urgent-no"
          value="no"
          required={watch('urgent_str') !== 'yes'}
          disabled={disabled}
          {...register('urgent_str')}
        />
      </RadioGroup>
    </>
  );
}
