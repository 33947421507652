/*
StatValue, a single numeric value for a statistic display

A stat value is a base level component, and contains no subcomponents
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component level arguments):
	value: the value for this statistic (numeric)
*/
class StatValue extends React.Component {
  render() {
    return <div className="StatValue">{this.props.value}</div>;
  }
}

StatValue.defaultProps = {
  value: '-',
};

StatValue.propTypes = {
  value: PropTypes.string.isRequired,
};

export default StatValue;
