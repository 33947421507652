import React from 'react';
import PropTypes from 'prop-types';

// import loading_icon from '../../assets/images/assets/In Progress Icon.png';

class LoadOverlay extends React.Component {
  render() {
    return (
      <div className={'LoadOverlay' + (this.props.show ? '' : ' hide')}>
        {this.props.error ? (
          <div className="loading-error">
            <div className="load-error-icon">
              <span className="fas fa-exclamation-triangle"></span>
            </div>
            {this.props.errorMessage !== '' ? (
              <div className="error-title custom">{this.props.errorMessage}</div>
            ) : (
              <h2 className="error-title default">Load Error</h2>
            )}

            <button
              className="button inverted action-button"
              type="button"
              onClick={() => window.location.reload()}
            >
              Reload
            </button>
          </div>
        ) : (
          // <img className="loading-image" src={loading_icon} alt="Loading..." />
          <p>Loading...</p>
        )}
      </div>
    );
  }
}

LoadOverlay.defaultProps = {
  show: false,
  error: false,
  errorMessage: '',
};

LoadOverlay.propTypes = {
  show: PropTypes.bool.isRequired,
  error: PropTypes.bool,
};

export default LoadOverlay;
