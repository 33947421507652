import { errorlog } from '@/libs/error-logs';
import { toast_error } from '@/libs/toast-wrappers';
import { ERROR_FETCH_USERS } from '@/utils/messages';

import { GET } from './base/index';
import { ENDPOINTS } from './endpoints';

export async function getUsersList(clinic_id: string) {
  try {
    const response = await GET<{ users: unknown[] }>(ENDPOINTS.USERS_LIST, {
      query: { clinic_id },
    });
    return response.users;
  } catch (error) {
    errorlog(error as Error, 'src/api/user-profile/getProfile', 'Error fetching profile');
    toast_error(ERROR_FETCH_USERS);
    return Promise.reject(error);
  }
}
