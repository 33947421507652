import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faOctagonXmark } from '@fortawesome/pro-solid-svg-icons';
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons';

type RequestStateValueProps = {
  value?: number;
};

export function GradeStateValue(props: RequestStateValueProps) {
  const { value } = props;
  const { t } = useTranslation();

  return value === 1 ? (
    <>
      <FontAwesomeIcon icon={faOctagonXmark} className="text-red-600" />
      <span className="m-2">{t('rejected')}</span>
    </>
  ) : value === 2 ? (
    <>
      <FontAwesomeIcon icon={faCheckDouble} className="text-green-700" />
      <span className="m-2">{t('approved')}</span>
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faClipboardListCheck} className="text-cyan-600" />
      <span className="m-2">{t('pending')}</span>
    </>
  );
}
