/*
AnalyticsGraph - An AnalyticsGraph is to show clinic related data in a graph form with some filters
	A AnalyticsGraph consists of -------
*/

import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import AnalyticsGraphTab from './AnalyticsGraphTab';
import InputDropdown from '../FormInputs/InputDropdown';

const mTabPatientCount = 'tab-one';
const mTabReferralRate = 'tab-two';
const mTabInadequateRate = 'tab-three';
const mTabProcessingTime = 'tab-four';
const mTabMRDetectionRate = 'tab-five';

class AnalyticsGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: mTabPatientCount,
    };

    this.dateTickFormatter = this.dateTickFormatter.bind(this);
    this.tooltipValueFormatter = this.tooltipValueFormatter.bind(this);
    this.renderPatientCount = this.renderPatientCount.bind(this);
    this.renderReferralHistory = this.renderReferralHistory.bind(this);
    this.renderInadequateHistory = this.renderInadequateHistory.bind(this);
    this.renderProcessingTimeHistory = this.renderProcessingTimeHistory.bind(this);
  }

  updateLayout(activeTab) {
    this.setState({
      activeTab: activeTab,
    });
  }

  dateTickFormatter(date_str) {
    const date = new Date(date_str);
    const year = date.getFullYear();

    //NOTE: the +1 here is because in the internal javascript date object months are 0-indexed
    //but humans read them in a 1-indexed manner
    let month = date.getMonth() + 1 + '';
    while (month.length < 2) {
      month = '0' + month;
    }
    let dom = date.getDate() + '';
    while (dom.length < 2) {
      dom = '0' + dom;
    }
    return year + '-' + month + '-' + dom;
  }

  tooltipValueFormatter(value, name) {
    switch (name) {
      case 'patient_count':
        return value;
      case 'avg_time':
        return (value - 0).toFixed(2) + ' sec';
      case 'refer_perc':
      case 'inad_perc':
      case 'mr_detected_perc':
        return (value - 0).toFixed(2) + '%';
      default:
        //intentionally do nothing
        //leading to the execution of the return after the switch statement
        break;
    }
    return value;
  }

  renderPatientCount() {
    return (
      <div className="chart-cont">
        <div className="hide-for-large">
          <AnalyticsGraphTab
            isActive={true}
            title="# Patients Submitted"
            content={this.props.total_patients}
            percentageDiff={this.props.patient_diff}
            intervalValue={this.props.interval_value}
            intervalUnit={this.props.interval_unit}
          />
        </div>
        <ResponsiveContainer width="100%" aspect={2.0 / 1.0}>
          <LineChart
            data={this.props.patient_count_history}
            margin={{
              top: 5,
              right: 36,
              left: 16,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="date"
              orientation="bottom"
              axisLine={true}
              tickLine={false}
              interval={5}
              padding={{ left: 20, right: 20 }}
              tick={{ fill: '#414042', fontWeight: 600, fontSize: '12px' }}
              label="Date"
              tickFormatter={this.dateTickFormatter}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              mirror
              orientation="right"
              ticks={[0, 5, 10, 15]}
              tick={{ fill: '#414042', fontSize: '12px', fontWeight: 600, opacity: '0.6' }}
              tickMargin={-30}
            >
              <Label value="Count" angle={90} position="right"></Label>
            </YAxis>
            <CartesianGrid vertical={false} stroke="#EBF0F5" />
            <Tooltip
              position={{ y: 200 }}
              labelFormatter={this.dateTickFormatter}
              formatter={this.tooltipValueFormatter}
            />

            <Line
              type="linear"
              dataKey="inad_count"
              stroke="#C43F3F"
              strokeWidth={1}
              strokeDasharray="5 5"
            />
            {!this.props.hide_referral_history && (
              <Line
                type="linear"
                dataKey="refer_count"
                stroke="#8cc3e1"
                strokeWidth={1}
                strokeDasharray="5 5"
              />
            )}
            {!this.props.hide_mr_detection_history &&
              this.props.patient_count_history.length > 0 &&
              this.props.patient_count_history[0].hasOwnProperty('mr_detected_count') && (
                <Line
                  type="linear"
                  dataKey="mr_detected_count"
                  stroke="#8cc3e1"
                  strokeWidth={1}
                  strokeDasharray="5 5"
                />
              )}
            <Line type="linear" dataKey="patient_count" stroke="#FBB040" strokeWidth={3} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  renderReferralHistory() {
    if (this.props.hide_referral_history) {
      return null;
    }
    return (
      <div className="chart-cont">
        <div className="hide-for-large">
          <AnalyticsGraphTab
            isActive={true}
            title="Referral Rate"
            content={this.props.referral_rate}
            percentageDiff={this.props.referral_diff}
            intervalValue={this.props.interval_value}
            intervalUnit={this.props.interval_unit}
          />
        </div>
        <ResponsiveContainer width="100%" aspect={2.0 / 1.0}>
          <LineChart
            data={this.props.referral_history}
            margin={{
              top: 5,
              right: 36,
              left: 16,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="date"
              orientation="bottom"
              axisLine={true}
              tickLine={false}
              interval={5}
              padding={{ left: 20, right: 20 }}
              tick={{ fill: '#414042', fontWeight: 600, fontSize: '12px' }}
              label="Date"
              tickFormatter={this.dateTickFormatter}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              mirror
              orientation="right"
              ticks={[0, 25, 50, 75, 100]}
              tick={{ fill: '#414042', fontSize: '12px', fontWeight: 600, opacity: '0.6' }}
              tickMargin={-30}
            >
              <Label value="Percent" angle={90} position="right"></Label>
            </YAxis>
            <CartesianGrid vertical={false} stroke="#EBF0F5" />
            <Tooltip
              position={{ y: 200 }}
              labelFormatter={this.dateTickFormatter}
              formatter={this.tooltipValueFormatter}
            />

            <Line type="linear" dataKey="refer_perc" stroke="#FBB040" strokeWidth={3} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  renderMRDetectedHistory() {
    if (this.props.hide_mr_detection_history) {
      return null;
    }
    return (
      <div className="chart-cont">
        <div className="hide-for-large">
          <AnalyticsGraphTab
            isActive={true}
            title="MR Detection Rate"
            content={this.props.mr_detection_rate}
            percentageDiff={this.props.mr_detection_diff}
            intervalValue={this.props.interval_value}
            intervalUnit={this.props.interval_unit}
          />
        </div>
        <ResponsiveContainer width="100%" aspect={2.0 / 1.0}>
          <LineChart
            data={this.props.mr_detection_history}
            margin={{
              top: 5,
              right: 36,
              left: 16,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="date"
              orientation="bottom"
              axisLine={true}
              tickLine={false}
              interval={5}
              padding={{ left: 20, right: 20 }}
              tick={{ fill: '#414042', fontWeight: 600, fontSize: '12px' }}
              label="Date"
              tickFormatter={this.dateTickFormatter}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              mirror
              orientation="right"
              ticks={[0, 25, 50, 75, 100]}
              tick={{ fill: '#414042', fontSize: '12px', fontWeight: 600, opacity: '0.6' }}
              tickMargin={-30}
            >
              <Label value="Percent" angle={90} position="right"></Label>
            </YAxis>
            <CartesianGrid vertical={false} stroke="#EBF0F5" />
            <Tooltip
              position={{ y: 200 }}
              labelFormatter={this.dateTickFormatter}
              formatter={this.tooltipValueFormatter}
            />

            <Line type="linear" dataKey="mr_detected_perc" stroke="#FBB040" strokeWidth={3} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  renderInadequateHistory() {
    return (
      <div className="chart-cont">
        <div className="hide-for-large">
          {/* NOTE: when it comes to rates of inadequacy, lower numbers are better */}
          <AnalyticsGraphTab
            isActive={true}
            title="Inadequate Rate"
            content={this.props.inadequate_rate}
            percentageDiff={this.props.inadequate_diff}
            positiveDiffGood={false}
            intervalValue={this.props.interval_value}
            intervalUnit={this.props.interval_unit}
          />
        </div>
        <ResponsiveContainer width="100%" aspect={2.0 / 1.0}>
          <LineChart
            data={this.props.inadequate_history}
            margin={{
              top: 5,
              right: 36,
              left: 16,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="date"
              orientation="bottom"
              axisLine={true}
              tickLine={false}
              interval={5}
              padding={{ left: 20, right: 20 }}
              tick={{ fill: '#414042', fontWeight: 600, fontSize: '12px' }}
              label="Date"
              tickFormatter={this.dateTickFormatter}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              mirror
              orientation="right"
              ticks={[0, 25, 50, 75, 100]}
              tick={{ fill: '#414042', fontSize: '12px', fontWeight: 600, opacity: '0.6' }}
              tickMargin={-30}
            >
              <Label value="Percent" angle={90} position="right"></Label>
            </YAxis>
            <CartesianGrid vertical={false} stroke="#EBF0F5" />
            <Tooltip
              position={{ y: 200 }}
              labelFormatter={this.dateTickFormatter}
              formatter={this.tooltipValueFormatter}
            />

            <Line type="linear" dataKey="inad_perc" stroke="#FBB040" strokeWidth={3} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  renderProcessingTimeHistory() {
    return (
      <div className="chart-cont">
        <div className="hide-for-large">
          {/* NOTE: when it comes to processing times, lower numbers are better */}
          <AnalyticsGraphTab
            isActive={true}
            title="Average Case Processing Time"
            content={this.props.avg_processing_time}
            percentageDiff={this.props.avg_processing_diff}
            positiveDiffGood={false}
            intervalValue={this.props.interval_value}
            intervalUnit={this.props.interval_unit}
          />
        </div>
        <ResponsiveContainer width="100%" aspect={2.0 / 1.0}>
          <LineChart
            data={this.props.processing_history}
            margin={{
              top: 5,
              right: 36,
              left: 16,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="date"
              orientation="bottom"
              axisLine={true}
              tickLine={false}
              interval={5}
              padding={{ left: 20, right: 20 }}
              tick={{ fill: '#414042', fontWeight: 600, fontSize: '12px' }}
              label="Date"
              tickFormatter={this.dateTickFormatter}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              mirror
              orientation="right"
              ticks={[0, 25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300]}
              tick={{ fill: '#414042', fontSize: '12px', fontWeight: 600, opacity: '0.6' }}
              tickMargin={-30}
            >
              <Label value="Seconds" angle={90} position="right"></Label>
            </YAxis>
            <CartesianGrid vertical={false} stroke="#EBF0F5" />
            <Tooltip
              position={{ y: 200 }}
              labelFormatter={this.dateTickFormatter}
              formatter={this.tooltipValueFormatter}
            />

            <Line type="linear" dataKey="avg_time" stroke="#FBB040" strokeWidth={3} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  render() {
    let activeTab = this.state.activeTab;
    return (
      <div className="AnalyticsGraph">
        <div className="analytics-title">{this.props.graph_title}</div>

        <div className="graph-filter-container hide-for-large">
          <InputDropdown
            dropdownClass="graph-filter-dropdown"
            dropdownId="graphRangeFilter"
            optionData={this.props.date_range_dropdown_data}
            callback={this.props.timeframe_callback}
            defaultValue="days:7"
            defaultSelectText={this.props.default_select_text}
          />
        </div>

        <div className="grid-container full show-for-large">
          <div className="grid-x">
            <div
              className="cell small-12 medium-3 large-3 analytics-tab"
              onClick={() => this.updateLayout(mTabPatientCount)}
            >
              <AnalyticsGraphTab
                isActive={activeTab === mTabPatientCount}
                title={this.props.patients_title}
                content={this.props.total_patients}
                percentageDiff={this.props.patient_diff}
                intervalValue={this.props.interval_value}
                intervalUnit={this.props.interval_unit}
              />
            </div>
            {!this.props.hide_mr_detection_history && (
              <div
                className="cell small-12 medium-3 large-3 analytics-tab"
                onClick={() => this.updateLayout(mTabMRDetectionRate)}
              >
                <AnalyticsGraphTab
                  isActive={activeTab === mTabMRDetectionRate}
                  title={this.props.mr_detection_title}
                  content={this.props.mr_detection_rate}
                  percentageDiff={this.props.mr_detection_diff}
                  intervalValue={this.props.interval_value}
                  intervalUnit={this.props.interval_unit}
                />
              </div>
            )}
            {!this.props.hide_referral_history && (
              <div
                className="cell small-12 medium-3 large-3 analytics-tab"
                onClick={() => this.updateLayout(mTabReferralRate)}
              >
                <AnalyticsGraphTab
                  isActive={activeTab === mTabReferralRate}
                  title={this.props.refer_title}
                  content={this.props.referral_rate}
                  percentageDiff={this.props.referral_diff}
                  intervalValue={this.props.interval_value}
                  intervalUnit={this.props.interval_unit}
                />
              </div>
            )}
            <div
              className="cell small-12 medium-3 large-3 analytics-tab"
              onClick={() => this.updateLayout(mTabInadequateRate)}
            >
              {/* NOTE: when it comes to rates of inadequacy, lower numbers are better */}
              <AnalyticsGraphTab
                isActive={activeTab === mTabInadequateRate}
                title={this.props.inad_title}
                content={this.props.inadequate_rate}
                percentageDiff={this.props.inadequate_diff}
                positiveDiffGood={false}
                intervalValue={this.props.interval_value}
                intervalUnit={this.props.interval_unit}
              />
            </div>
            <div
              className="cell small-12 medium-3 large-3 analytics-tab"
              onClick={() => this.updateLayout(mTabProcessingTime)}
            >
              {/* NOTE: when it comes to processing times, lower numbers are better */}
              <AnalyticsGraphTab
                isActive={activeTab === mTabProcessingTime}
                title={this.props.time_title}
                content={this.props.avg_processing_time}
                percentageDiff={this.props.avg_processing_diff}
                positiveDiffGood={false}
                intervalValue={this.props.interval_value}
                intervalUnit={this.props.interval_unit}
              />
            </div>
          </div>
        </div>

        <div className="show-for-large">
          {/*
					NOTE: graphs must be separate rather than being overlayed because they have different y-axis values
					and since they don't share both axes, overlaying them could lead to confusion
					*/}
          {activeTab === mTabPatientCount ? this.renderPatientCount() : null}
          {activeTab === mTabReferralRate ? this.renderReferralHistory() : null}
          {activeTab === mTabInadequateRate ? this.renderInadequateHistory() : null}
          {activeTab === mTabProcessingTime ? this.renderProcessingTimeHistory() : null}
          {activeTab === mTabMRDetectionRate ? this.renderMRDetectedHistory() : null}
        </div>
        {/*
				on mobile graphs are stacked
				this is done using CSS classes rather than javascript so that dynamic resizing of the display works correctly
				*/}
        <div className="hide-for-large">
          {this.renderPatientCount()}
          {this.renderReferralHistory()}
          {this.renderInadequateHistory()}
          {this.renderProcessingTimeHistory()}
        </div>

        <div className="graph-filter-container show-for-large">
          <InputDropdown
            dropdownClass="graph-filter-dropdown"
            dropdownId="graphRangeFilter"
            optionData={this.props.date_range_dropdown_data}
            callback={this.props.timeframe_callback}
            defaultValue="days:7"
            defaultSelectText={this.props.default_select_text}
          />
        </div>
      </div>
    );
  }
}

AnalyticsGraph.defaultProps = {
  total_patients: '-',
  patient_diff: null,
  referral_rate: '-',
  referral_diff: null,
  mr_detection_rate: '-',
  mr_detection_diff: null,
  inadequate_rate: '-',
  inadequate_diff: null,
  avg_processing_time: '-',
  avg_processing_diff: null,
  patient_count_history: [],
  referral_history: [],
  inadequate_history: [],
  processing_history: [],
  timeframe_callback: function (e) {},
  interval_unit: 'days',
  interval_value: 7,
  graph_title: 'Analytics',
  patients_title: '# Patients Submitted',
  refer_title: 'Referral Rate',
  mr_detection_title: 'MR Detection Rate',
  inad_title: 'Inadequate Rate',
  time_title: 'Average Case Processing Time',
  date_range_dropdown_data: [
    { 'hours:24': 'Last 24 hours' },
    { 'days:7': 'Last Week' },
    { 'days:30': 'Last Month' },
  ],
  default_select_text: '- Select -',
  hide_referral_history: false,
  hide_mr_detection_history: true,
};

AnalyticsGraph.propTypes = {
  total_patients: PropTypes.string.isRequired,
  patient_diff: PropTypes.number,
  referral_rate: PropTypes.string.isRequired,
  referral_diff: PropTypes.number,
  inadequate_rate: PropTypes.string.isRequired,
  inadequate_diff: PropTypes.number,
  avg_processing_time: PropTypes.string.isRequired,
  avg_processing_diff: PropTypes.number,
  patient_count_history: PropTypes.array.isRequired,
  referral_history: PropTypes.array.isRequired,
  inadequate_history: PropTypes.array.isRequired,
  processing_history: PropTypes.array.isRequired,
  timeframe_callback: PropTypes.func.isRequired,
  interval_unit: PropTypes.string.isRequired,
  interval_value: PropTypes.number.isRequired,
  graph_title: PropTypes.string.isRequired,
  patients_title: PropTypes.string.isRequired,
  refer_title: PropTypes.string.isRequired,
  inad_title: PropTypes.string.isRequired,
  time_title: PropTypes.string.isRequired,
  date_range_dropdown_data: PropTypes.array.isRequired,
  default_select_text: PropTypes.string.isRequired,
  hide_referral_history: PropTypes.bool.isRequired,
};

export default AnalyticsGraph;
