import { useCallback, useEffect, useState } from 'react';

import { UseFormSetValue, UseFormWatch } from 'react-hook-form';

export type SignGradeForm = {
  // first question: images readable
  readable_str: string; // yes or no
  imagesReadable_string: string; // JSON object with an array of { img: string, isReadable: 'yes' or 'no' }

  // second question: evidence of diabetic macular edema?
  evidenceOfDme_str: string; // yes or no

  // third question: Diabetic Retinopathy Grade scale
  drgrade_string: string;

  // fourth question: Referral for Follow Up (AAO PPP)
  referral_string: string;

  // fifth question: options
  otherDiseaseCustom_bool: boolean;
  otherDiseaseNone_bool: boolean;
  otherDiseaseCustomInput_string: string; // custom string from input, this will be passed to the otherDiseaseCustom_string in the save form
  otherDiseaseConcernForGlaucoma_bool: boolean;
  otherDiseaseMacularDrusen_bool: boolean;
  otherDiseaseMacularPigmentaryDisturbance_bool: boolean;
  otherDiseaseEpiretinalMembrane_bool: boolean;
  otherDiseaseChoroidalLesion_bool: boolean;

  // conditional question based on the fifth question
  otherDiseaseFollowUp_string?: string;

  // related to the third question
  // if none selected
  noRetinopathy_bool: boolean;

  // if at least mild selected
  microaneurysms_bool: boolean;
  hardExudates_bool: boolean;
  cws_bool: boolean;
  mildRhs_bool: boolean;

  // if at least moderate selected
  mildRhsIn4Quadrants_bool: boolean;
  mildIrmaIn1to3Quadrants_bool: boolean;
  moderateRhsPlusMildIrma_bool: boolean;
  mildIrmaIn4Quadrants_bool: boolean;
  severeRhIn2To3Quadrants_bool: boolean;
  venousBeadingIn1Quadrant_bool: boolean;

  // if at least severe selected
  severeRhIn4Quadrants_bool: boolean;
  moderateToSevereIrmaIn1PlusQuadrants_bool: boolean;
  venousBeadingIn2PlusQuadrants_bool: boolean;

  // if proliferative selected
  nveGreaterThanHalfDaIn1PlusQuadrants_61_bool: boolean;
  nveGreaterThanHalfDaIn1PlusQuadrants_65_bool: boolean;
  nvdLessThanThreeQuarters_bool: boolean;
  largerNvdOrNveGreaterThanOrEqualToHalfDa_bool: boolean;
  viewPartiallyObscuredByVh_bool: boolean;

  // the signature
  // signatureDataUri_string: boolean;
  urgent_str?: string;
};

export const defaultSignGradeForm: SignGradeForm = {
  readable_str: '',
  imagesReadable_string: '[]',
  evidenceOfDme_str: '',
  drgrade_string: '',
  referral_string: '',
  otherDiseaseFollowUp_string: '',
  otherDiseaseCustomInput_string: '',

  otherDiseaseCustom_bool: false,
  otherDiseaseNone_bool: false,
  otherDiseaseConcernForGlaucoma_bool: false,
  otherDiseaseMacularDrusen_bool: false,
  otherDiseaseMacularPigmentaryDisturbance_bool: false,
  otherDiseaseEpiretinalMembrane_bool: false,
  otherDiseaseChoroidalLesion_bool: false,

  noRetinopathy_bool: false,

  microaneurysms_bool: false,
  hardExudates_bool: false,
  cws_bool: false,
  mildRhs_bool: false,

  mildRhsIn4Quadrants_bool: false,
  mildIrmaIn1to3Quadrants_bool: false,
  moderateRhsPlusMildIrma_bool: false,
  mildIrmaIn4Quadrants_bool: false,
  severeRhIn2To3Quadrants_bool: false,
  venousBeadingIn1Quadrant_bool: false,

  severeRhIn4Quadrants_bool: false,
  moderateToSevereIrmaIn1PlusQuadrants_bool: false,
  venousBeadingIn2PlusQuadrants_bool: false,

  nveGreaterThanHalfDaIn1PlusQuadrants_61_bool: false,
  nveGreaterThanHalfDaIn1PlusQuadrants_65_bool: false,
  nvdLessThanThreeQuarters_bool: false,
  largerNvdOrNveGreaterThanOrEqualToHalfDa_bool: false,
  viewPartiallyObscuredByVh_bool: false,
  // signatureDataUri_string: false,

  urgent_str: '',
};

type GradeFormLogicProps = {
  watch: UseFormWatch<SignGradeForm>;
  setValue: UseFormSetValue<SignGradeForm>;
};

export function useGradeFormLogic(props: GradeFormLogicProps) {
  const { watch, setValue } = props;
  const [disabledFields, setDisabledFields] = useState<string[]>([]);

  // clean up the values when the user selects a lower grade
  const clearProliferativeValues = useCallback(() => {
    setValue('nveGreaterThanHalfDaIn1PlusQuadrants_61_bool', false);
    setValue('nveGreaterThanHalfDaIn1PlusQuadrants_65_bool', false);
    setValue('nvdLessThanThreeQuarters_bool', false);
    setValue('largerNvdOrNveGreaterThanOrEqualToHalfDa_bool', false);
    setValue('viewPartiallyObscuredByVh_bool', false);
  }, [setValue]);

  const clearSevereValues = useCallback(() => {
    setValue('severeRhIn4Quadrants_bool', false);
    setValue('moderateToSevereIrmaIn1PlusQuadrants_bool', false);
    setValue('venousBeadingIn2PlusQuadrants_bool', false);

    clearProliferativeValues();
  }, [setValue, clearProliferativeValues]);

  const clearModerateValues = useCallback(() => {
    setValue('mildRhsIn4Quadrants_bool', false);
    setValue('mildIrmaIn1to3Quadrants_bool', false);
    setValue('moderateRhsPlusMildIrma_bool', false);
    setValue('mildIrmaIn4Quadrants_bool', false);
    setValue('severeRhIn2To3Quadrants_bool', false);
    setValue('venousBeadingIn1Quadrant_bool', false);

    clearSevereValues();
  }, [setValue, clearSevereValues]);

  const clearMildValues = useCallback(() => {
    setValue('microaneurysms_bool', false);
    setValue('hardExudates_bool', false);
    setValue('cws_bool', false);
    setValue('mildRhs_bool', false);

    clearModerateValues();
  }, [setValue, clearModerateValues]);

  const addSevereDefaultDisable = useCallback(() => {
    setDisabledFields((current) => {
      if (current.includes('severeRhIn4Quadrants_bool')) {
        return current;
      }
      return [
        ...current,
        'severeRhIn4Quadrants_bool',
        'moderateToSevereIrmaIn1PlusQuadrants_bool',
        'venousBeadingIn2PlusQuadrants_bool',
      ];
    });
  }, []);

  // watch for changes in the drgrade_string, se we can clean up the values
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'drgrade_string') {
        // avoid to have this value set to true when the user selects a grade
        if (value.drgrade_string !== 'none' && value.noRetinopathy_bool) {
          setValue('noRetinopathy_bool', false);
        }

        // clean up the values when the user selects a lower grade
        switch (value.drgrade_string) {
          case 'none':
            setValue('noRetinopathy_bool', true);
            clearMildValues();
            break;
          case 'mild':
            clearModerateValues();
            break;
          case 'moderate':
            clearSevereValues();
            break;
          case 'severe':
            clearProliferativeValues();
            break;
          default:
            break;
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [
    watch,
    setValue,
    clearMildValues,
    clearModerateValues,
    clearSevereValues,
    clearProliferativeValues,
  ]);

  /**
   * SUB QUESTION LOGIC
   * We have some validations and logic to implement
   */
  useEffect(() => {
    const subscription = watch((state, { name }) => {
      /** 1. If Moderate (43, 47) selection:
       * if select Mild RHs in 4 quadrants OR severe in 1”
       * Disable “Moderate RHs (4 quadrants or 1 severe) + mild IRMA (1 to 3 quadrants)”
       */
      if (name === 'mildRhsIn4Quadrants_bool') {
        // if not moderateRhsPlusMildIrma_bool then add it to the disabledFields
        if (!state.moderateRhsPlusMildIrma_bool) {
          setDisabledFields((current) => {
            if (
              state.mildRhsIn4Quadrants_bool &&
              !current.includes('moderateRhsPlusMildIrma_bool')
            ) {
              return [...current, 'moderateRhsPlusMildIrma_bool'];
            }
            return current.filter((field) => field !== 'moderateRhsPlusMildIrma_bool');
          });
        }
        // if moderateRhsPlusMildIrma_bool and mildRhsIn4Quadrants_bool is unchecked then uncheck moderateRhsPlusMildIrma_bool
        if (state.moderateRhsPlusMildIrma_bool && !state.mildRhsIn4Quadrants_bool) {
          setValue('moderateRhsPlusMildIrma_bool', false);
        }
      }

      /** 1.1
       * if select “Moderate RHs (4 quadrants or 1 severe) + mild IRMA (1 to 3 quadrants)”
       * Then auto select Mild RH
       */
      if (name === 'moderateRhsPlusMildIrma_bool' && state.moderateRhsPlusMildIrma_bool) {
        setValue('mildRhsIn4Quadrants_bool', true);
      }

      /** 1.2
       * if select “Mild IRMA in 1 to 3 quadrants”
       * disable “Mild IRMA 4 quadrants”
       */
      if (name === 'mildIrmaIn1to3Quadrants_bool') {
        // if not mildIrmaIn4Quadrants_bool then add it to the disabledFields
        if (!state.mildIrmaIn4Quadrants_bool) {
          setDisabledFields((current) => {
            if (
              state.mildIrmaIn1to3Quadrants_bool &&
              !current.includes('mildIrmaIn4Quadrants_bool')
            ) {
              return [...current, 'mildIrmaIn4Quadrants_bool'];
            }
            return current.filter((field) => field !== 'mildIrmaIn4Quadrants_bool');
          });
        }
        // if mildIrmaIn4Quadrants_bool and mildIrmaIn1to3Quadrants_bool is unchecked then uncheck mildIrmaIn4Quadrants_bool
        if (state.mildIrmaIn4Quadrants_bool && !state.mildIrmaIn1to3Quadrants_bool) {
          setValue('mildIrmaIn4Quadrants_bool', false);
        }
      }

      /** 1.3
       * Or, if select “Mild IRMA 4 quadrants”
       * Then auto select “Mild IRMA in 1 to 3 quadrants”
       */
      if (name === 'mildIrmaIn4Quadrants_bool' && state.mildIrmaIn4Quadrants_bool) {
        setValue('mildIrmaIn1to3Quadrants_bool', true);
      }

      /** 2 .If Severe (53) selection:
       * Disable “Severe RH in 4 quadrants” AND “Moderate to severe IRMA in 1+ quadrant” AND “Venous Beading in 2+ quadrants” to start with conditional logic.
       * Allow “2 of the above criteria from 47 to remain as is, but if checked, then the remaining 3 options above become available
       */
      if (name === 'drgrade_string' && state.drgrade_string === 'severe') {
        addSevereDefaultDisable();
        return;
      }

      const criteriaFrom47List = [
        'moderateRhsPlusMildIrma_bool',
        'mildIrmaIn4Quadrants_bool',
        'severeRhIn2To3Quadrants_bool',
        'venousBeadingIn1Quadrant_bool',
      ];

      if (name && criteriaFrom47List.includes(name)) {
        const criteriaFrom47Selected = criteriaFrom47List.filter(
          (field) => state[field as keyof typeof state]
        ).length;

        if (criteriaFrom47Selected > 1) {
          setDisabledFields((current) => {
            return current.filter(
              (field) =>
                field !== 'severeRhIn4Quadrants_bool' &&
                field !== 'moderateToSevereIrmaIn1PlusQuadrants_bool' &&
                field !== 'venousBeadingIn2PlusQuadrants_bool'
            );
          });
        } else {
          addSevereDefaultDisable();
        }
      }

      /** 2.1
       * If select “Severe RH in 4 quadrants” then auto select “Severe RH in 2-3 quadrants”.
       */
      if (name === 'severeRhIn4Quadrants_bool' && state.severeRhIn4Quadrants_bool) {
        setValue('severeRhIn2To3Quadrants_bool', true);
        return;
      }
      /** 2.2
       * If select “Moderate to severe IRMA in 1+ quadrant” then auto select “Mild IRMA in 4 quadrants.”
       */
      if (
        name === 'moderateToSevereIrmaIn1PlusQuadrants_bool' &&
        state.moderateToSevereIrmaIn1PlusQuadrants_bool
      ) {
        setValue('mildIrmaIn4Quadrants_bool', true);
        return;
      }
      /** 2.3
       * If select “Venous Beading in 2+ quadrants” then auto select “Venous beading in 1 quadrant.”
       */
      if (
        name === 'venousBeadingIn2PlusQuadrants_bool' &&
        state.venousBeadingIn2PlusQuadrants_bool
      ) {
        setValue('venousBeadingIn1Quadrant_bool', true);
        return;
      }

      /** 3.If Proliferative (61, 65, 71, 75, 81, 85) selection(s):
       * 65 Moderate PDR: If Select EITHER nveGreaterThanHalfDaIn1PlusQuadrants_65_bool OR nvdLessThanThreeQuarters_bool OR BOTH
       * then automatically check 61 Mild PDR “NVE > 0.5 DA in 1+ quadrant”
       */
      if (
        name === 'nveGreaterThanHalfDaIn1PlusQuadrants_65_bool' ||
        name === 'nvdLessThanThreeQuarters_bool'
      ) {
        // if some of this are checked disabled the 61
        if (
          Boolean(state.nveGreaterThanHalfDaIn1PlusQuadrants_65_bool) ||
          Boolean(state.nvdLessThanThreeQuarters_bool)
        ) {
          setValue('nveGreaterThanHalfDaIn1PlusQuadrants_61_bool', true);
          setDisabledFields((current) => {
            if (!current.includes('nveGreaterThanHalfDaIn1PlusQuadrants_61_bool')) {
              return [...current, 'nveGreaterThanHalfDaIn1PlusQuadrants_61_bool'];
            }
            return current;
          });
        } else {
          setDisabledFields((current) => {
            return current.filter(
              (field) => field !== 'nveGreaterThanHalfDaIn1PlusQuadrants_61_bool'
            );
          });
        }
      }
      /** 3.1
       * If select largerNvdOrNveGreaterThanOrEqualToHalfDa_bool
       * THEN automatically check 65 Moderate PDR both options and 61 MIld PDR
       */
      if (name === 'largerNvdOrNveGreaterThanOrEqualToHalfDa_bool') {
        if (state.largerNvdOrNveGreaterThanOrEqualToHalfDa_bool) {
          setValue('nveGreaterThanHalfDaIn1PlusQuadrants_65_bool', true);
          setValue('nvdLessThanThreeQuarters_bool', true);
          setValue('nveGreaterThanHalfDaIn1PlusQuadrants_61_bool', true);
          setDisabledFields((current) => {
            if (!current.includes('nveGreaterThanHalfDaIn1PlusQuadrants_65_bool')) {
              return [...current, 'nveGreaterThanHalfDaIn1PlusQuadrants_65_bool'];
            }
            return current;
          });
          setDisabledFields((current) => {
            if (!current.includes('nvdLessThanThreeQuarters_bool')) {
              return [...current, 'nvdLessThanThreeQuarters_bool'];
            }
            return current;
          });
          setDisabledFields((current) => {
            if (!current.includes('nveGreaterThanHalfDaIn1PlusQuadrants_61_bool')) {
              return [...current, 'nveGreaterThanHalfDaIn1PlusQuadrants_61_bool'];
            }
            return current;
          });
        } else {
          setDisabledFields((current) => {
            return current.filter(
              (field) =>
                field !== 'nveGreaterThanHalfDaIn1PlusQuadrants_65_bool' &&
                field !== 'nvdLessThanThreeQuarters_bool'
            );
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, addSevereDefaultDisable]);

  return { disabledFields };
}
