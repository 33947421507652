import { ERROR_FETCH_COUNTRIES } from '@/utils/messages';
import { toast_error } from '@/libs/toast-wrappers';
import { errorlog } from '@/libs/error-logs';

import { ENDPOINTS } from './endpoints';
import { GET } from './base/index';

export type CountryOption = Record<string, string>;
type ResponseCountries = { countries: [] | string };

export async function getCountries(): Promise<CountryOption[]> {
  try {
    const data = await GET<ResponseCountries>(ENDPOINTS.COUNTRIES);
    const countries = data.countries;
    const countryOptionArray: CountryOption[] = [];

    if (typeof countries === 'string') {
      const countryObj: Record<string, string> = {};
      countryObj[countries] = countries;
      countryOptionArray.push(countryObj);
    } else {
      for (let countryIdx = 0; countryIdx < countries.length; countryIdx++) {
        const countryObj = {};
        countryObj[countries[countryIdx]] = countries[countryIdx];
        countryOptionArray.push(countryObj);
      }
    }
    return countryOptionArray;
  } catch (error) {
    errorlog(error as Error, 'src/api/countries/getCountries', 'Error fetching countries');
    toast_error(ERROR_FETCH_COUNTRIES);
    return Promise.reject(error);
  }
}
