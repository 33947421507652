import BaseModel from './BaseModel';

class Legal extends BaseModel {
  constructor(createdAt) {
    super(createdAt);

    this.legalId = null;
    this.clinicId = null;
    this.title = null;
    this.legalPdfS3Path = null;
  }

  getLegalId() {
    return this.legalId;
  }

  setLegalId(legalId) {
    return this.genericSetter(this, 'legalId', legalId, this.typeString());
  }

  getClinicId() {
    return this.clinicId;
  }

  setClinicId(clinicId) {
    return this.genericSetter(this, 'clinicId', clinicId, this.typeString());
  }

  getTitle() {
    return this.title;
  }

  setTitle(title) {
    return this.genericSetter(this, 'title', title, this.typeString());
  }

  getLegalPdfS3Path() {
    return this.legalPdfS3Path;
  }

  setLegalPdfS3Path(legalPdfS3Path) {
    return this.genericSetter(this, 'legalPdfS3Path', legalPdfS3Path, this.typeString());
  }

  deserialize(serializedData) {
    return super.deserialize(Legal, serializedData);
  }
}

export default Legal;
