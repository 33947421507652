import BaseModel from './BaseModel';

class Session extends BaseModel {
  constructor(createdAt) {
    super(createdAt);

    this.sessionId = null;
    this.clinicUserId = null;
    this.adminUserId = null;
    this.sessionType = null;
  }

  getSessionId() {
    return this.sessionId;
  }

  setSessionId(sessionId) {
    return this.genericSetter(this, 'sessionId', sessionId, this.typeString());
  }

  getClinicUserId() {
    return this.clinicUserId;
  }

  setClinicUserId(clinicUserId) {
    return this.genericSetter(this, 'clinicUserId', clinicUserId, this.typeString());
  }

  getAdminUserId() {
    return this.adminUserId;
  }

  setAdminUserId(adminUserId) {
    return this.genericSetter(this, 'adminUserId', adminUserId, this.typeString());
  }

  getSessionType() {
    return this.sessionType;
  }

  setSessionType(sessionType) {
    return this.genericSetter(this, 'sessionType', sessionType, this.typeString());
  }

  deserialize(serializedData) {
    return super.deserialize(Session, serializedData);
  }
}

export default Session;
