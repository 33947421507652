import { redirect, LoaderFunctionArgs } from 'react-router-dom';

import { authValidation } from '@/utils/auth';
import { ENDPOINTS } from '@/api/endpoints';
import { getCase } from '@/api/cases';
import { toast_error } from '@/libs/toast-wrappers';

import { ROUTES } from './routes';

export function authRouteLoader() {
  if (authValidation()) {
    return redirect('/');
  }
  return null;
}

export function redirectHomeLoader() {
  return redirect('/');
}

export function protectedLoader({ request }: LoaderFunctionArgs) {
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  const isAuth = authValidation();
  if (!isAuth) {
    const url = new URL(request.url);
    const params = new URLSearchParams();
    if (url.pathname !== ROUTES.LOGOUT) params.set('from', url.pathname);
    return redirect(ROUTES.LOGIN + '?' + params.toString());
  }
  return null;
}

export async function gradeLoader({ params }: LoaderFunctionArgs) {
  const caseId = params.caseId;

  if (!caseId) {
    return redirect(ROUTES.GRADING);
  }

  // Load the data for the case
  const response = await getCase(caseId, 'list');

  if (!response) {
    toast_error('Error fetching case data');
    return redirect(ROUTES.GRADING);
  }

  // if there are no images we can return an empty array
  if (typeof response === 'string' || !Array.isArray(response)) return { caseImgs: [] };

  const caseImgs = response?.map((img) =>
    ENDPOINTS.CASES_IMAGE.replace('{caseId}', caseId).replace('{imgPath}', img)
  );

  return {
    caseImgs,
  };
}
