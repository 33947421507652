import { useCallback, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';

import { POST, PUT } from '@/api/base';
import { ENDPOINTS } from '@/api/endpoints';
import { useCountriesOptions } from '@/hooks/api/use-countries-options';
import { useGetClinicById } from '@/hooks/api/use-get-clinic-by-id';
import { toast_success } from '@/libs/toast-wrappers';

import { Hr } from '@/components/divider';
import { InputField } from '@/components/form/input-field';
import { SelectField } from '@/components/form/select-field';

type InputTypes = {
  clinic_name: string;
  description: string;
  contact_url: string;
  contact_phone: string;
  street_address: string;
  city: string;
  province_or_state: string;
  postal_code: string;
  country: string;
};

export function ClinicForm() {
  const { COUNTRIES } = useCountriesOptions();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<InputTypes>();
  const { clinicId } = useParams();
  const { clinic, loading } = useGetClinicById({ clinic_id: clinicId ?? '' });
  const isEditing = Boolean(clinicId);
  const { onFormClose } = useOutletContext<{
    onFormClose: () => Promise<void>;
  }>();
  const { t } = useTranslation();

  const onSubmitUpdateClinic: SubmitHandler<InputTypes> = async (data) => {
    if (isEditing && clinicId) {
      const response = await PUT(`${ENDPOINTS.CLINIC}`, data, {
        parameters: { clinicId },
      });

      if (response) {
        await onFormClose();
        toast_success(t('clinic-updated').replace('clinicId', clinicId.slice(0, 5)));
        return;
      }
    }
    const response = await POST(ENDPOINTS.CLINICS, data, { query: { add_site: 'True' } });

    if (response) {
      await onFormClose();
      toast_success(t('clinic-saved'));
    }
  };

  const fillForm = useCallback(() => {
    setValue('clinic_name', clinic?.clinic_name ?? '');
    setValue('description', clinic?.description ?? '');
    setValue('contact_phone', clinic?.contact_phone ?? '');
    setValue('contact_url', clinic?.contact_url ?? '');
    setValue('country', clinic?.country ?? '');
    setValue('street_address', clinic?.street_address ?? '');
    setValue('province_or_state', clinic?.province_or_state ?? '');
    setValue('city', clinic?.city ?? '');
    setValue('postal_code', clinic?.postal_code ?? '');
  }, [clinic, setValue]);

  useEffect(() => {
    fillForm();
  }, [fillForm, clinic]);

  return (
    <form onSubmit={handleSubmit(onSubmitUpdateClinic)}>
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mb-8">
        <div className="sm:col-span-3">
          <InputField
            disabled={loading}
            {...register('clinic_name', {
              required: { value: true, message: t('clinic-name-error') },
            })}
            label={t('clinic-name-label')}
            errorMessage={errors.clinic_name?.message}
          />
        </div>
        <div className="sm:col-span-3">
          <InputField
            disabled={loading}
            {...register('description')}
            label={t('description-label')}
          />
        </div>

        <div className="col-span-full">
          <InputField
            disabled={loading}
            {...register('contact_url', {
              required: { value: true, message: t('contact-url-error') },
            })}
            label={t('url-label')}
            errorMessage={errors.contact_url?.message}
          />
        </div>

        <div className="sm:col-span-3">
          <InputField
            disabled={loading}
            {...register('contact_phone', {
              required: { value: true, message: t('contact-phone-error') },
            })}
            label={t('phone-label')}
            errorMessage={errors.contact_phone?.message}
            type="phone"
          />
        </div>

        <div className="sm:col-span-3">
          <SelectField
            disabled={loading}
            {...register('country', { required: { value: true, message: t('country-error') } })}
            label={t('country-label')}
            errorMessage={errors.country?.message}
            options={COUNTRIES ?? []}
          />
        </div>

        <div className="col-span-full">
          <InputField
            disabled={loading}
            {...register('street_address', {
              required: { value: true, message: t('street-address-error') },
            })}
            label={t('address-label')}
            errorMessage={errors.street_address?.message}
          />
        </div>

        <div className="sm:col-span-2 sm:col-start-1">
          <InputField
            disabled={loading}
            {...register('city', { required: { value: true, message: t('city-error') } })}
            label={t('city-label')}
            errorMessage={errors.city?.message}
          />
        </div>

        <div className="sm:col-span-2">
          <InputField
            disabled={loading}
            {...register('province_or_state', {
              required: { value: true, message: t('province-or-state-error') },
            })}
            label={t('province-or-state-label')}
            errorMessage={errors.province_or_state?.message}
          />
        </div>

        <div className="sm:col-span-2">
          <InputField
            disabled={loading}
            {...register('postal_code', {
              required: { value: true, message: t('postal-code-error') },
            })}
            label={t('postal-code-label')}
            type="number"
            errorMessage={errors.postal_code?.message}
          />
        </div>
      </div>
      <Hr />
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          onClick={() => onFormClose()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t('cancel')}
        </button>
        <button type="submit" className="button">
          {t('save')}
        </button>
      </div>
    </form>
  );
}
