/*
InputRadioBlock, a navigation link display (sidebar for large screen sizes)

An InputRadioBlock consists of the following:
	One or more InputRadio components
*/

import React from 'react';
import { InputRadio } from './InputRadio';
import PropTypes from 'prop-types';

/*
props (component level arguments):
	title: the title of the InputRadioBlock
	extraClasses: an additional class for the InputRadioBlock
	data: the data in array for InputRadio
	radioName: the name of InputRadio
	callback: The onchange event handler
*/

export class InputRadioBlock extends React.Component {
  constructor(props) {
    super(props);
    this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
  }

  handleRadioButtonChange(event) {
    if (this.props.callback) {
      this.props.callback(event);
    }
  }

  static populateRadioButtons(component) {
    let data = component.props.data;
    let array = [];
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        array.push(
          <InputRadio
            key={key}
            value={Object.keys(Object.values(data)[key])[0]}
            desc={Object.values(Object.values(data)[key]).toString()}
            radioName={component.props.radioName}
            callback={component.handleRadioButtonChange}
            required={component.props.required}
            radioId=""
          />
        );
      }
    }
    return array;
  }

  render() {
    return (
      <div className={`InputRadioBlock ${this.props.extraClasses}`}>
        <label className={'input-title'} htmlFor={this.props.radioId}>
          {this.props.title}
        </label>
        <div id={this.props.radioId} className="radio-option-group">
          {InputRadioBlock.populateRadioButtons(this)}
        </div>
      </div>
    );
  }
}

InputRadioBlock.defaultProps = {
  extraClasses: '',
  required: false,
};

InputRadioBlock.propTypes = {
  title: PropTypes.string.isRequired,
  radioId: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  radioName: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
  callback: PropTypes.func,
  required: PropTypes.bool,
};

export default InputRadioBlock;
