import { API_BASE_ENDPOINT } from '@/api/constants';
import { ENDPOINTS } from '@/api/endpoints';
import { errorlog } from '@/libs/error-logs';
import { toast_error } from '@/libs/toast-wrappers';
import { logout, getSessionToken } from '@/libs/session-management';
import { isDevEnv } from '@/libs/dev-logs';

import { readFileAsArrayBuffer } from './data-handlers';

type RequestOptions = {
  headers?: Record<string, string | undefined>;
  query?: Record<string, string>;
  parameters?: Record<string, string>;
  isFileRequest?: boolean;
};
type ENDPOINT = (typeof ENDPOINTS)[keyof typeof ENDPOINTS];

/**
 * Fetches data from the API, this is the base function for all requests
 * @param endpoint one of the listed endpoints in the ENDPOINTS object
 * @param method the method of the request
 * @param options some options for the request
 * @param @optional body of the request
 * @returns the response from the API typed as Generic T
 */
async function FETCH<T>(
  endpoint: ENDPOINT,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  options: RequestOptions = {},
  body?: Record<string, unknown> | Uint8Array | FormData
) {
  const headers = options.headers ?? {};
  const query = options.query ?? {};
  const endpoint_parameters = options.parameters
    ? Object.keys(options.parameters).reduce(
        (acc, key) => acc.replace(`{${key}}`, encodeURIComponent(options.parameters![key])),
        endpoint
      )
    : endpoint;

  const REQUEST_URL = `${API_BASE_ENDPOINT}${endpoint_parameters}`;
  const QUERY_STRING = options.query ? '?' + new URLSearchParams(query).toString() : '';

  const isFileBody = body instanceof Uint8Array;
  const isFormData = body instanceof FormData;
  const body_output = isFileBody || isFormData ? body : JSON.stringify(body);

  if (!isFormData) {
    headers['Content-Type'] = 'application/json';
  }

  const authentication: { authorization?: string } = {};
  const token = getSessionToken();
  if (token) {
    authentication.authorization = 'Bearer ' + token;
  }

  const response = await fetch(REQUEST_URL + QUERY_STRING, {
    method,
    headers: {
      'Access-Control-Request-Method': method,
      ...headers,
      ...authentication,
    },
    body: body_output ?? undefined,
  });

  if (!response.ok) {
    const errorResponse = (await response.json()) as Record<string, string>;
    const errorDetails = errorResponse?.detail ?? response.statusText;

    if (response.status === 401) {
      errorlog(new Error('Forbidden 401'), method, 'Error FETCH data 401');
      toast_error(
        errorDetails.includes('Access Denied')
          ? errorDetails
          : 'Invalid Session - Please login again'
      );
      if (!isDevEnv()) {
        logout();
      }
      throw new Error('Forbidden 401' + errorDetails);
    }

    if (response.status === 403) {
      errorlog(new Error('Forbidden 403'), method, 'Error FETCH data 403');
      toast_error('Unauthorized access');

      if (!isDevEnv()) {
        logout();
      }

      throw new Error('Forbidden 403');
    }

    errorlog(new Error(errorDetails), method, 'Error FETCH data !ok');
    toast_error(errorDetails, Boolean(errorResponse?.detail));
    throw new Error(errorDetails);
  }

  if (options.isFileRequest) {
    const file = await response.blob();
    return file as T;
  }

  const data = (await response.json()) as { errorMessage: string };

  if (data.errorMessage) {
    errorlog(new Error(data.errorMessage), method, 'Error FETCH data with error message');
    throw new Error(data.errorMessage);
  }

  return data as T;
}

/**
 * Makes a GET request to the API
 * @param endpoint the endpoint to the endpoint
 * @param options the options for the request
 * @returns the response from the API
 * @throws an error if the response is not ok or if there is an error signing the request
 */
export async function GET<T>(endpoint: ENDPOINT, options: RequestOptions = {}) {
  const METHOD = 'GET';
  return FETCH<T>(endpoint, METHOD, options);
}

/**
 * Makes a POST request to the API
 * @param endpoint the endpoint to the endpoint
 * @param body the body of the request
 * @param options the options for the request
 * @returns the response from the API
 * @throws an error if the response is not ok or if there is an error signing the request
 */
export async function POST<T>(
  endpoint: ENDPOINT,
  body: Record<string, unknown> | FormData,
  options: RequestOptions = {}
) {
  const METHOD = 'POST';
  return FETCH<T>(endpoint, METHOD, options, body);
}

/**
 * Makes a PUT request to the API
 * @param endpoint the endpoint to the endpoint
 * @param body the body of the request
 * @param options the options for the request
 * @returns the response from the API
 * @throws an error if the response is not ok or if there is an error signing the request
 */
export async function PUT<T>(
  endpoint: ENDPOINT,
  body: Record<string, unknown> | File,
  options: RequestOptions = {}
) {
  const METHOD = 'PUT';

  // check if the body is a file
  if (body instanceof File) {
    const arrayBuffer = await readFileAsArrayBuffer(body);
    const uint8Array = new Uint8Array(arrayBuffer as ArrayBufferLike);

    return FETCH<T>(endpoint, METHOD, options, uint8Array);
  }

  return FETCH<T>(endpoint, METHOD, options, body);
}

export async function GET_FILE(endpoint: string, options: RequestOptions = {}) {
  const METHOD = 'GET';
  const headers = options?.headers ?? {};
  const query = options?.query;

  const REQUEST_URL = `${API_BASE_ENDPOINT}${endpoint}`;
  const QUERY_STRING = query ? '?' + new URLSearchParams(query).toString() : '';

  const authentication: { authorization?: string } = {};
  const token = getSessionToken();
  if (token) {
    authentication.authorization = 'Bearer ' + token;
  }

  const response = await fetch(REQUEST_URL + QUERY_STRING, {
    method: METHOD,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      ...authentication,
    },
  });

  if (response.status === 403) {
    errorlog(new Error('Forbidden 403'), 'GET', 'Error GET data');
    toast_error('Unauthorized access');
    logout();
    throw new Error('Forbidden 403');
  }

  if (!response.ok) {
    errorlog(new Error(response.statusText), 'GET', 'Error GET data');
    throw new Error(response.statusText);
  }

  const data = (await response.blob()) as File;

  return data;
}

export async function DELETE(endpoint: ENDPOINT, options: RequestOptions = {}) {
  const METHOD = 'DELETE';
  return FETCH(endpoint, METHOD, options);
}

export async function PATCH(
  endpoint: ENDPOINT,
  body: Record<string, unknown>,
  options: RequestOptions = {}
) {
  const METHOD = 'PATCH';
  return FETCH(endpoint, METHOD, options, body);
}
