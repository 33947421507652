import { ButtonHTMLAttributes, useCallback, useState } from 'react';

import { faSpinner, faFileArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type DownloadButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick' | 'type'> & {
  onDownload?: () => Promise<File | undefined>;
  fileName?: string;
};

export function DownloadButton(props: DownloadButtonProps) {
  const { fileName, onDownload, disabled, className = '', ...rest } = props;
  const [loading, setLoading] = useState(false);

  const handleDownload = useCallback(async () => {
    if (!onDownload) return;
    setLoading(true);

    try {
      const file = await onDownload?.();
      if (file) {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName ?? 'download';
        a.click();
      }
    } catch (error) {
      console.error('Error downloading file', error);
    }
    setLoading(false);
  }, [onDownload, fileName]);

  return (
    <button
      {...rest}
      className={`${className} bg-primary rounded-full h-8 w-8 disabled:opacity-50`}
      disabled={loading || disabled}
      onClick={handleDownload}
    >
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} color="white" className="animate-spin" />
      ) : (
        <FontAwesomeIcon icon={faFileArrowDown} color="white" />
      )}
    </button>
  );
}
