import useSWR from 'swr';

import { getClinics } from '@/api/clinics';
import { ENDPOINTS } from '@/api/endpoints';

export const useGetClinics = () => {
  const { data, isLoading, mutate } = useSWR(ENDPOINTS.CLINICS, () => getClinics());

  return {
    loading: isLoading,
    clinics: data,
    refetchClinics: mutate,
  };
};
