import logo from '@/assets/images/vitazi-logo-transparent.png';
import { useNavigationItems } from '@/hooks/use-navigation-items';

import { SideMenuItem } from './side-menu-link';

export const SideMenu = () => {
  const { navItems, bottomNavLinks } = useNavigationItems();

  return (
    <div className="side-menu" data-testid="navigation">
      <div className="logo-wrapper">
        <img className="header-logo" alt="Vitazi.ai Logo" src={logo} />
      </div>
      <nav>
        <div>
          <ul className="main-side-menu-items my-8">
            {navItems
              .filter((nav) => !nav.href?.includes('admin'))
              .map((nav_link, index) => (
                <SideMenuItem key={`${nav_link.href}-${index}`} item={nav_link} />
              ))}
          </ul>
        </div>
        <ul className="bottom-side-menu-items">
          {bottomNavLinks.map((nav_link, index) => (
            <SideMenuItem key={`${nav_link.href}-${index}`} item={nav_link} />
          ))}
        </ul>
      </nav>
    </div>
  );
};
