//get all cookies currently set as a javascript object
export function parse_cookies() {
  const cookies: Record<string, unknown> = {};

  if (typeof document !== 'undefined') {
    const cookie_strings = document.cookie.split(';');
    for (let i = 0; i < cookie_strings.length; i++) {
      const cookie_parts = cookie_strings[i].trim().split('=');
      const cookie_name = cookie_parts[0];
      cookies[cookie_name] = cookie_parts[1];
    }
  }

  return cookies;
}
