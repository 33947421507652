/*
ChangePass - A change password page with a splash screen.

A ChangePass page consists of InputFieldBlocks and an InputCheckbox
*/

import React from 'react';
import { Link } from 'react-router-dom';

// utils
import config from '../config.js';
import { changePassword, resetPassword } from '@/api/auth';
import { toast_error, toast_success } from '@/libs/toast-wrappers';
import { parse_get_data } from '../react-utils/src/libgetdata.js';
import {
  ERROR_PASSWORDS_DONT_MATCH,
  ERROR_RESETTING_PASSWORD,
  ERROR_MESSAGE_TRY_AGAIN_EMAIL,
  SUCCESS_PASSWORD_CHANGE,
} from '@/utils/messages';

// components
import InputCheckbox from '../lib-medical-portal/components/FormInputs/InputCheckbox.jsx';
import InputFieldBlock from '../lib-medical-portal/components/FormInputs/InputFieldBlock.jsx';
import { Copyright } from './footer/copyright.jsx';
import { ROUTES } from '@/router/routes';
import { router } from '@/router/root';

//internationalization and translation support
//eslint-disable-next-line no-unused-vars
import i18n from '@/libs/i18n.js';
import { Trans, withTranslation } from 'react-i18next';

// assets
import logo from '../assets/images/vitazi-logo-transparent.png';
import { AuthSplash } from './auth/splash';

/*
state:
isForgotPass: a boolean which determines whether or not the user is changing their password (value is not true) (they know their current password)

props (component-level arguments):
	isForgotPass: a boolean which determines whether or not the user is changing their password (value is not true) (they know their current password)
		or if they are resetting it via and email reset link (value is true).
*/

class ChangePass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isForgotPass: Boolean(this.props.isForgotPass),
      token: '',
    };
    this.onSubmitChangePass = this.onSubmitChangePass.bind(this);
    this.submitChangePassRequest = this.submitChangePassRequest.bind(this);
  }

  componentDidMount() {
    // we are getting the token from the URL and assign the state isForgotPass to true
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      this.setState({ isForgotPass: true, token });
    }
  }

  onSubmitChangePass() {
    let cmpnt = this;
    return function (event) {
      event.preventDefault();
      cmpnt.submitChangePassRequest();
      return false;
    };
  }

  // This function now accounts for both forgot password and change password. A prop variable called isForgotpass is now passed when the user navigates here
  submitChangePassRequest() {
    const newPassword = document.getElementById('newPassword').value;
    const confirmPassword = document.getElementById('confirmNewPassword').value;
    const isPasswordEqual = newPassword === confirmPassword;

    // Then the user is entering a new password from the forgot password email link they received
    if (!isPasswordEqual) {
      // Generically display all the response values when the result is not successful
      toast_error(ERROR_PASSWORDS_DONT_MATCH);
      return false;
    }

    const email = document.getElementById('email').value;
    const token = parse_get_data(window.location.href.toString())['token'];

    // By default we will assume we are passing data to the users/reset endpoint and so current password will not be sent
    if (this.state.isForgotPass) {
      void resetPassword(email, newPassword, confirmPassword, token).then(() => {
        toast_success(SUCCESS_PASSWORD_CHANGE);
        setTimeout(function () {
          router.navigate(ROUTES.LOGIN);
        }, 5000);
      });
      return;
    }

    const currentPassword = document.getElementById('currentPassword').value;

    void changePassword(email, newPassword, confirmPassword, currentPassword).then(() => {
      toast_success(SUCCESS_PASSWORD_CHANGE);
      setTimeout(function () {
        router.navigate(ROUTES.LOGIN);
      }, 5000);
    });
  }

  // This function contains some logic that determines if it will display the current password InputFieldBlock or not
  // If so pass a boolean to onSubmitChangePass so we know to send to the users/changepass and not the users/reset endpoint
  renderForm() {
    return (
      <form className="login-form" onSubmit={this.onSubmitChangePass(this)}>
        <InputFieldBlock
          title={i18n.t('login-email')}
          fieldName="email"
          titleClass="login-email-label"
          fieldClass="login-email-input"
          fieldType="email"
          fieldRequired={true}
        />
        {!this.state.isForgotPass && (
          <div>
            <InputFieldBlock
              title={i18n.t('changepass-existing')}
              fieldName="currentPassword"
              titleClass="login-password-label"
              fieldClass="login-password-input"
              fieldHint="****"
              fieldType="password"
              fieldRequired={true}
            />
          </div>
        )}
        <InputFieldBlock
          title={i18n.t('changepass-new')}
          fieldName="newPassword"
          titleClass="login-password-label"
          fieldClass="login-password-input"
          fieldHint="****"
          fieldType="password"
          fieldRequired={true}
        />
        <InputFieldBlock
          title={i18n.t('changepass-confirm')}
          fieldName="confirmNewPassword"
          titleClass="login-password-label"
          fieldClass="login-password-input"
          fieldHint="****"
          fieldType="password"
          fieldRequired={true}
        />
        <InputCheckbox inputId="login-terms-accept" desc={i18n.t('login-terms')} required />
        <div className="login-button-row">
          <button type="submit" className="button">
            {i18n.t('changepass-submit')}
          </button>
          <div className="wrapper-new-user-sign-up">
            <p className="signup-cta inline-block-mobile-margin">or</p>
            <Link
              to={ROUTES.LOGIN}
              className="inline-block-mobile-margin login-page-blue-clickable-text"
            >
              {i18n.t('changepass-login')}
            </Link>
          </div>
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className="ChangePass Login">
        <div className="grid-container full">
          <div className="grid-x">
            <AuthSplash />

            <div className="cell small-12 large-6 large-offset-1 form-right-panel">
              <div className="login-form-row">
                <img className="logo" src={logo} alt="Vitazi.ai Logo" />
                <div className="page-title">{i18n.t('changepass-title')}</div>
                {this.renderForm()}
              </div>
              <Copyright />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ChangePass);
