/*
FlyoutMenu - A drawer style menu


*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	child: A default prop that is used to allow any code to be inserted into the FlyoutMenu
	buttonText: The text of the button to open the menu
	
*/
const FLYOUT_OPEN_CLASS = 'flyout-menu-is-open';
class FlyoutMenu extends React.Component {
  componentDidUpdate(prevProps) {
    let body = document.getElementsByTagName('body')[0];
    if (!prevProps.menuOpen && this.props.menuOpen) {
      if (body) {
        body.classList.add(FLYOUT_OPEN_CLASS);
      }
    } else if (prevProps.menuOpen && !this.props.menuOpen) {
      if (body) {
        body.classList.remove(FLYOUT_OPEN_CLASS);
      }
    }
  }
  render() {
    return (
      <div
        id={this.props.id}
        className={'FlyoutMenu ' + (this.props.menuOpen ? 'is-open' : 'is-closed')}
      >
        <button
          className="button button-small button-icon add-case-button"
          onClick={() => this.props.openMenu()}
        >
          {this.props.showFA && <span className="fa fa-plus"></span>}
          {this.props.buttonText}
        </button>
        <div className={`menu ${this.props.menuOpen ? 'open' : 'closed'}`}>
          <div className="grid-x bg-edit">
            <div className="cell small-12">
              <button
                className="button button-small button-icon button-subtle cancel-button"
                onClick={() => this.props.closeMenu()}
              >
                <span className="fa fa-times"></span>
                {this.props.closeButtonText ? this.props.closeButtonText : 'Cancel'}
              </button>
            </div>
            <div className="cell small-12">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

FlyoutMenu.propTypes = {
  buttonText: PropTypes.string,
};

FlyoutMenu.defaultProps = {
  buttonText: 'Open Menu',
  id: 'flyoutID',
  showFA: true,
};

export default FlyoutMenu;
