import { useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { formatId } from '@/utils/strings';
import { ROUTES } from '@/router/routes';
import { useGetClinics } from '@/hooks/api/use-get-clinics';
import { Clinic } from '@/api/clinics';

import { BasicDrawer as Drawer } from '@/components/basic-drawer';
import { FetchStates } from '@/components/fetch-states';
import { Table } from '@/components/table';
import { Hr } from '@/components/divider';

import { ClinicSitesTable } from './clinic-sites-table';

export function ClinicsAdminPage() {
  const { clinicId, siteId } = useParams();
  const path = useLocation();

  const isSiteRoute = path.pathname.includes('site');

  // if clinicId is present, open the drawer
  const [isOpen, setIsOpen] = useState(() => Boolean(clinicId));
  const { t } = useTranslation();
  const { clinics, loading, refetchClinics } = useGetClinics();
  const navigate = useNavigate();

  const sitesFormTitle = siteId
    ? `${t('site-drawer-title-edit')} ${formatId(siteId)}`
    : t('site-drawer-title-add');

  const clinicFormTitle = clinicId
    ? `${t('clinic-drawer-title-edit')} ${formatId(clinicId)}`
    : t('clinic-drawer-title-add');

  const drawerTitle = isSiteRoute ? sitesFormTitle : clinicFormTitle;

  const clinicsTableHeaders = useMemo(
    () => [
      {
        key: 'clinic_id',
        label: t('clinic-id'),
        type: 'value' as const,
        format: formatId,
      },
      { key: 'clinic_name', label: t('clinic-name') },
      { key: 'description', label: t('description') },
      {
        key: 'country',
        label: t('country'),
      },
      {
        key: 'city',
        label: t('city'),
      },
      {
        key: 'sites',
        type: 'collapsible' as const,
        label: t('sites'),
      },
      {
        key: 'actions',
        type: 'actions' as const,
        edit: (clinic: Clinic) => {
          setIsOpen((open) => !open);
          navigate(`${ROUTES.ADMIN_EDIT_CLINIC}/${clinic.clinic_id}`);
        },
        delete: (clinic: Clinic) => {
          console.log('Delete clinic:', clinic.clinic_id);
        },
      },
    ],
    [t, navigate]
  );

  const onClose = () => {
    setIsOpen(false);
    navigate(-1);
  };

  const onFormClose = async () => {
    onClose();
    await refetchClinics();
  };

  return (
    <>
      <Drawer title={drawerTitle} open={isOpen || Boolean(clinicId)} onClose={onClose}>
        <Outlet
          context={
            { onFormClose } satisfies {
              onFormClose: () => Promise<void>;
            }
          }
        />
      </Drawer>

      <div className="page-content-wrapper paper">
        <header className="page-header">
          <h1 className="page-title">{t('clinics-title')}</h1>
          <Link
            onClick={() => setIsOpen((open) => !open)}
            to={'new'}
            className="button button-small button-icon link"
          >
            <span>
              <FontAwesomeIcon icon={faPlus} />
            </span>
            {t('new-clinic-button')}
          </Link>
        </header>

        <FetchStates loading={loading} refetch={refetchClinics} empty={!clinics?.length}>
          <div className="page-content">
            <Table
              headers={clinicsTableHeaders}
              data={clinics ?? []}
              CollapsibleComponent={ClinicSitesTable}
            />
          </div>
        </FetchStates>

        <Hr />
      </div>
    </>
  );
}
