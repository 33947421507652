import { InputHTMLAttributes, forwardRef } from 'react';

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  classNameFieldSet?: string;
  label?: string;
  name: string;
  errorMessage?: string;
};

/**
 * InputField, consists of a fieldset with label and an input field
 * forwardRef is used to pass the ref to the input field, since we are using ReactFormHook
 * @props:
 * extends InputHTMLAttributes<HTMLInputElement>
 * - label: the label of the input field
 * - classNameFieldSet: the class of the fieldset
 * - name: the name of the input field (required)
 */
export const InputField = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const {
    id,
    name,
    label,
    required,
    classNameFieldSet = '',
    className = '',
    type = 'text',
    errorMessage,
    ...rest
  } = props;

  return (
    <fieldset className={`InputFieldBlock ${classNameFieldSet}`}>
      {label && (
        <label className="input-label" htmlFor={id ?? name}>
          {label}
          {required && ' *'}
        </label>
      )}
      <input
        {...rest}
        ref={ref}
        className={`input-field ${className}`}
        id={id ?? name}
        type={type}
        name={name}
        required={required}
      />
      {errorMessage && <p className=" text-red-500">{errorMessage}</p>}
    </fieldset>
  );
});

InputField.displayName = 'InputField';
