import {
  ERROR_LOGIN_REQUEST,
  ERROR_INVALID_LOGIN_CREDENTIALS,
  ERROR_SIGN_UP_EMAIL_IN_USE,
  ERROR_SIGN_UP_REQUEST,
  ERROR_SIGN_UP_API_EMAIL_IN_USE,
  ERROR_SENDING_RESET_PASSWORD_EMAIL,
  ERROR_RESETTING_PASSWORD,
} from '@/utils/messages';
import { errorlog } from '@/libs/error-logs';
import { toast_error, toast_warning } from '@/libs/toast-wrappers';
import { router } from '@/router/root';
import { ROUTES } from '@/router/routes';

import { ENDPOINTS } from './endpoints';
import { POST } from './base/index';

const ENV = import.meta.env;

type LoginResponseAPI = { 'Access Key': string; 'Access Denied'?: string };
type LoginResponseAPIGateway = {
  'Access Key': string;
  'Secret Key': string;
  'Security Token': string;
  'Session Name': string;
  'Clinic Name': string;
};

export const login = async (email: string, password: string) => {
  try {
    if (ENV.VITE_USE_API_GATEWAY === 'true') {
      const response = await POST<LoginResponseAPIGateway>(ENDPOINTS.LOGIN, {
        email,
        password,
      });
      return response;
    }
    const response = await POST<LoginResponseAPI>(ENDPOINTS.LOGIN, {
      email,
      password,
    });
    if ('Access Denied' in response && response['Access Denied']) {
      if (response['Access Denied'].includes('change your password')) {
        void router.navigate(ROUTES.CHANGE_PASSWORD);
        toast_warning(response['Access Denied']);
        return;
      }
      // default error message
      toast_error(ERROR_INVALID_LOGIN_CREDENTIALS);
      throw new Error(response['Access Denied']);
    }
    return { token: response['Access Key'] };
  } catch (error) {
    errorlog(error as Error, 'src/api/auth/login', 'Error logging in');
    toast_error(ERROR_LOGIN_REQUEST);
    return Promise.reject(error);
  }
};

type SignUpForm = {
  name: string;
  email: string;
  phone: string;
  city: string;
  provinceState: string;
  country: string;
  requestedRole: string;
  address?: string;
  postalZip?: string;
  clinic?: string;
};

export const signUp = async (form: SignUpForm) => {
  try {
    const response = await POST<{ result: string }>(ENDPOINTS.SIGNUP, form);
    if (response.result === ERROR_SIGN_UP_API_EMAIL_IN_USE) {
      toast_error(ERROR_SIGN_UP_EMAIL_IN_USE);
      return;
    }
    return response.result;
  } catch (error) {
    errorlog(error as Error, 'src/api/auth/signup', 'Error signing up');
    toast_error(ERROR_SIGN_UP_REQUEST);
    return Promise.reject(error);
  }
};

export const forgotPassword = async (email: string, passResetURL: string) => {
  try {
    const response = await POST(ENDPOINTS.FORGOT, {
      email,
      passResetURL,
    });
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/auth/forgotPassword', 'Error sending password reset email');
    toast_error(ERROR_SENDING_RESET_PASSWORD_EMAIL);
    return Promise.reject(error);
  }
};

export const resetPassword = async (
  email: string,
  newPassword: string,
  confirmNewPassword: string,
  token: string
) => {
  try {
    const response = await POST(
      ENDPOINTS.RESET,
      {
        email,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword,
      },
      { query: { token } }
    );
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/auth/resetPassword', 'Error resetting password');
    toast_error(ERROR_RESETTING_PASSWORD);
    return Promise.reject(error);
  }
};

export const changePassword = async (
  email: string,
  newPassword: string,
  confirmNewPassword: string,
  currentPassword: string
) => {
  try {
    const response = await POST(ENDPOINTS.CHANGEPASS, {
      email,
      newPassword,
      confirmNewPassword,
      currentPassword,
    });
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/auth/changePassword', 'Error changing password');
    toast_error(ERROR_RESETTING_PASSWORD);
    return Promise.reject(error);
  }
};
