/*
FileUpload - A file upload component with drag and drop functionality
	A FileUpload contains a IconButton

*/

import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../IconButton';

/*
props (component-level arguments):
	fileID: a ID for the image, incrementing by one for every image uploaded
					One note, when we add the delete image functionality, this might cause a problem, but probably not
	fileData: The dataTransfer file that was uploaded to the server
	deleteCallback: the callback to trigger when this file is deleted
	fileMeta: metadata about the file being uploaded; consists of the following:
		uploadCompleted: whether or not this upload has completed, as a boolean value
		progressPerc: what percentage of this file has been uploaded
		uploadError: whether or not there was an error during upload
		uploadErrorMsg: if there was an error, what that error was
*/
class FileUpload extends React.Component {
  componentDidMount() {
    this.setImageUrl(this.props.fileData);
    this.renderErrors = this.renderErrors.bind(this);
  }

  setImageUrl(file) {
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      //this is needed as the onload function does not have access to 'this'
      const id = this.props.fileID;
      if (this.props.fileData.type.startsWith('image/')) {
        reader.onload = function (e) {
          document.getElementById(id).setAttribute('src', e.target.result);
        };
      }
    }
  }

  humanReadableFileSize(size) {
    const KB_bytes = Math.pow(10, 3);
    const MB_bytes = Math.pow(10, 6);
    const GB_bytes = Math.pow(10, 9);
    let display_number = '';
    if (size >= GB_bytes) {
      display_number = (size / GB_bytes).toFixed(1).replace(/\.0+$/, '') + 'GB';
    } else if (size >= MB_bytes) {
      display_number = (size / MB_bytes).toFixed(1).replace(/\.0+$/, '') + 'MB';
    } else if (size >= KB_bytes) {
      display_number = (size / KB_bytes).toFixed(1).replace(/\.0+$/, '') + 'KB';
    } else {
      display_number = size + 'B';
    }

    return display_number;
  }

  renderErrors() {
    if (this.props.fileMeta.uploadError) {
      return <div className="upload-error">Error: {this.props.fileMeta.uploadErrorMsg}</div>;
    }
    return null;
  }

  render() {
    return (
      <div className={`FileUpload grid-x`}>
        <div className="cell small-2 image-col">
          {this.props.fileData.type.startsWith('image/') ? (
            <img id={this.props.fileID} src="" alt={this.props.fileData.name} />
          ) : null}
        </div>
        <div className="cell small-8 stats-col">
          <div className="file-statistics">
            <p className="file-meta-data">
              <span className="file-name">{this.props.fileData.name}</span>
              <span className="file-size">
                {this.humanReadableFileSize(this.props.fileData.size)}
              </span>
            </p>
          </div>
          {this.props.fileMeta.uploadError ? (
            this.renderErrors()
          ) : this.props.fileMeta.progressPerc < 100 ? (
            <div className="progress-bar-wrapper">
              <div
                className="progress-bar-inner"
                style={{ width: this.props.fileMeta.progressPerc + '%' }}
              ></div>
              <div className="progress-bar-label">
                {this.props.fileMeta.progressPerc.toFixed(2)}%
              </div>
            </div>
          ) : (
            <div className="progress-complete-wrapper">100% Complete</div>
          )}
        </div>
        <div className="cell small-1 small-offset-1 icon-col">
          <IconButton
            buttonColorType="danger"
            foregroundIcon="far fa-trash-alt"
            callback={(function (cmpnt) {
              return function (e) {
                e.preventDefault();
                cmpnt.props.deleteCallback(cmpnt.props.fileID);
                return false;
              };
            })(this)}
          />
        </div>
      </div>
    );
  }
}

FileUpload.defaultProps = {
  deleteCallback: function (fileID) {},
  fileMeta: {
    name: null,
    key: null,
    progressPerc: null,
    uploadCompleted: false,
    uploadError: false,
    uploadErrorMsg: '',
  },
};

FileUpload.propTypes = {
  fileID: PropTypes.string.isRequired,
  fileData: PropTypes.object.isRequired,
  deleteCallback: PropTypes.func,
  fileMeta: PropTypes.object,
};

export default FileUpload;
