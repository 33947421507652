import { ERROR_FETCH_PROFILE } from '@/utils/messages';
import { toast_error } from '@/libs/toast-wrappers';
import { errorlog } from '@/libs/error-logs';

import { GET } from './base/index';
import { ENDPOINTS } from './endpoints';

export type UserProfile = {
  email?: string;
  clinic_id?: string;
  clinic_name?: string;
  full_name?: string;
  contact_phone?: string;
  street_address?: string;
  city?: string;
  province_or_state?: string;
  postal_code?: string;
  country?: string;
  licenses?: {
    license: string;
    state: string;
    expiration: string;
    approved: string;
  }[];
};

export async function getProfile(email: string) {
  try {
    const response = await GET<{ body: UserProfile }>(ENDPOINTS.PROFILE, {
      query: { email },
    });
    return response.body;
  } catch (error) {
    errorlog(error as Error, 'src/api/user-profile/getProfile', 'Error fetching profile');
    toast_error(ERROR_FETCH_PROFILE);
    return Promise.reject(error);
  }
}
