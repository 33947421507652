/* eslint-disable */
import i18n from '../libs/i18n.js';
import { parse_get_data } from '../react-utils/src/libgetdata';

import en from '../lang/en.json';
import es from '../lang/es.json';

export function get_language_setting() {
  //determine the configured language
  //English is the default
  let lang: string | undefined = 'en';

  //if a language setting was explicitly specified in GET data then use that
  const getData: { lang?: string } = parse_get_data(window.location.href);
  if (getData.hasOwnProperty('lang')) {
    lang = getData.lang;
    //otherwise attempt to determine if a language setting has been saved
    //and if so, use that
  } else {
    const storedLang = localStorage.getItem('i18nextLng');
    const navigatorLang = navigator.language.split('-')[0];

    //if a language has been manually selected, then use that
    if (storedLang !== null) {
      lang = storedLang.split('-')[0];
      //if a language can be detected from the browser settings
      //and it's not our default
      //then use that
    } else if (navigatorLang !== lang) {
      lang = navigatorLang;
    }
  }

  //otherwise use the default (english)

  return lang;
}

export function pre_load_translate(key: keyof typeof en) {
  let lang = get_language_setting();

  //select the correct translation for the language in use
  switch (lang) {
    case 'en':
      return en[key] as string;
    case 'es':
      return es[key] as string;
    default:
      break;
  }

  //if no translation was found then return the original key
  return key;
}

export function change_language(lang: string, is_splash_page: boolean) {
  i18n.changeLanguage(lang);

  if (!is_splash_page) {
    window.location.reload();
  }
}
