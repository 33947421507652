import { ERROR_REQUESTING_LICENSE } from '@/utils/messages';
import { toast_error } from '@/libs/toast-wrappers';
import { errorlog } from '@/libs/error-logs';

import { POST } from './base/index';
import { ENDPOINTS } from './endpoints';

export type NewLicenseForm = {
  email: string;
  expiration: string;
  country: string;
  state: string;
  license: string;
  license_file: File;
};

export async function postLicenseRequest(data: NewLicenseForm) {
  try {
    const form = new FormData();
    form.append('file', data.license_file);
    form.append('country', data.country ?? '');
    form.append('stateProvince', data.state);
    form.append('licenseNumber', data.license);
    form.append('expiration', data.expiration);
    form.append('requestType', 'add_license');

    const response = await POST(ENDPOINTS.LICENSE_REQUEST, form, {
      parameters: { doctor_email: data.email },
    });
    return response;
  } catch (error) {
    errorlog(
      error as Error,
      'src/api/licenses/postLicenseRequest',
      'Error sending license request'
    );
    toast_error(ERROR_REQUESTING_LICENSE);
    return Promise.reject(error);
  }
}
