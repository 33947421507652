//internationalization and translation support
import i18n from '@/libs/i18n.js';

import BaseModel from './BaseModel';

export const CASE_STATUS = {
  NOT_SUBMITTED: -4,
  NOT_STARTED: -3,
  PENDING: -2,
  INSUFFICIENT_QUALITY: -1,
  SEE_DOCTOR_ASAP: 0,
  RETURN_6_MO: 6,
  RETURN_12_MO: 12,
  IN_PROGRESS: 77,
  DATA_NOT_PROCESSED: 88,
  NO_DATA_FOUND: 99,
};

export let CASE_STATUS_TEXT = {};
CASE_STATUS_TEXT[CASE_STATUS.NOT_SUBMITTED] = i18n.t('case-sts-not-submitted');
CASE_STATUS_TEXT[CASE_STATUS.NOT_STARTED] = i18n.t('case-sts-not-started');
CASE_STATUS_TEXT[CASE_STATUS.PENDING] = i18n.t('case-sts-pending');
CASE_STATUS_TEXT[CASE_STATUS.INSUFFICIENT_QUALITY] = i18n.t('case-sts-insufficient-quality');
CASE_STATUS_TEXT[CASE_STATUS.SEE_DOCTOR_ASAP] = i18n.t('case-sts-see-doctor-asap');
CASE_STATUS_TEXT[CASE_STATUS.RETURN_6_MO] = i18n.t('case-sts-return-6-mo');
CASE_STATUS_TEXT[CASE_STATUS.RETURN_12_MO] = i18n.t('case-sts-return-12-mo');
CASE_STATUS_TEXT[CASE_STATUS.COMPLETE] = i18n.t('case-sts-complete');
CASE_STATUS_TEXT[CASE_STATUS.IN_PROGRESS] = i18n.t('case-sts-in-progress');
CASE_STATUS_TEXT[CASE_STATUS.DATA_NOT_PROCESSED] = i18n.t('case-sts-data-not-processed');
CASE_STATUS_TEXT[CASE_STATUS.NO_DATA_FOUND] = i18n.t('case-sts-no-data-found');

export class Case extends BaseModel {
  constructor(createdAt) {
    super(createdAt);

    this.caseId = null;
    this.clinicId = null;
    this.createdBy = null;
    this.patientId = null;
    this.patientName = null;
    this.siteId = null;
    this.camera = null;
    this.gender = null;
    this.race = null;
    this.hasDiabetes = null;
    this.yearDiagnosed = null;
    this.diabetesType = null;
    this.eyeDiseaseHistory = null;
    this.notes = null;
    this.result = null;
    this.status = null;
    this.imageQuantity = null;
    this.casePdfS3Path = null;
    this.retinalImagePaths = null;
    this.action = null;
    this.faIconName = null;
    this.stringColorPair = null;
    this.iconColor = null;
    this.report = null;
  }

  getIconColor() {
    return this.iconColor;
  }

  setIconColor(iconColor) {
    return this.genericSetter(this, 'iconColor', iconColor, this.typeString());
  }

  getStringColorPair() {
    return this.stringColorPair;
  }

  setStringColorPair(stringColorPair) {
    return this.genericSetter(this, 'stringColorPair', stringColorPair, this.typeObject());
  }

  getAction() {
    if (typeof this.action === 'function') {
      return this.action();
    }
    return null;
  }

  setAction(action) {
    return this.genericSetter(this, 'action', action, this.typeFunction());
  }

  getFaIconName() {
    return this.faIconName;
  }

  setFaIconName(faIconName) {
    return this.genericSetter(this, 'faIconName', faIconName, this.typeString());
  }

  getCaseId() {
    return this.caseId;
  }

  setCaseId(caseId) {
    return this.genericSetter(this, 'caseId', caseId, this.typeString());
  }

  getClinicId() {
    return this.clinicId;
  }

  setClinicId(clinicId) {
    return this.genericSetter(this, 'clinicId', clinicId, this.typeString());
  }

  getCreatedBy() {
    return this.createdBy;
  }

  setCreatedBy(createdBy) {
    return this.genericSetter(this, 'createdBy', createdBy, this.typeString());
  }

  getPatientId() {
    return this.patientId;
  }

  setPatientId(patientId) {
    return this.genericSetter(this, 'patientId', patientId, this.typeString());
  }

  getPatientName() {
    return this.patientName;
  }

  setPatientName(patientName) {
    return this.genericSetter(this, 'patientName', patientName, this.typeString());
  }

  getSiteId() {
    return this.siteId;
  }

  setSiteId(siteId) {
    return this.genericSetter(this, 'siteId', siteId, this.typeString());
  }

  getCamera() {
    return this.camera;
  }

  setCamera(camera) {
    return this.genericSetter(this, 'camera', camera, this.typeString());
  }

  getGender() {
    return this.gender;
  }

  setGender(gender) {
    return this.genericSetter(this, 'gender', gender, this.typeString());
  }

  getRace() {
    return this.race;
  }

  setRace(race) {
    return this.genericSetter(this, 'race', race, this.typeString());
  }

  getHasDiabetes() {
    return this.hasDiabetes;
  }

  setHasDiabetes(hasDiabetes) {
    return this.genericSetter(this, 'hasDiabetes', hasDiabetes, this.typeBool());
  }

  getYearDiagnosed() {
    return this.yearDiagnosed;
  }

  setYearDiagnosed(yearDiagnosed) {
    return this.genericSetter(this, 'yearDiagnosed', yearDiagnosed, this.typeNumber());
  }

  getDiabetesType() {
    return this.diabetesType;
  }

  setDiabetesType(diabetesType) {
    return this.genericSetter(this, 'diabetesType', diabetesType, this.typeString());
  }

  getEyeDiseaseHistory() {
    return this.eyeDiseaseHistory;
  }

  setEyeDiseaseHistory(eyeDiseaseHistory) {
    return this.genericSetter(this, 'eyeDiseaseHistory', eyeDiseaseHistory, this.typeString());
  }

  getNotes() {
    return this.notes;
  }

  setNotes(notes) {
    return this.genericSetter(this, 'notes', notes, this.typeString());
  }

  getResult() {
    return this.result;
  }

  setResult(result) {
    return this.genericSetter(this, 'result', result, this.typeString());
  }

  getStatus() {
    return this.status;
  }

  setStatus(status) {
    return this.genericSetter(this, 'status', status, this.typeString());
  }

  getImageQuantity() {
    return this.imageQuantity;
  }

  setImageQuantity(imageQuantity) {
    return this.genericSetter(this, 'imageQuantity', imageQuantity, this.typeNumber());
  }

  getCasePdfS3Path() {
    return this.casePdfS3Path;
  }

  setCasePdfS3Path(casePdfS3Path) {
    return this.genericSetter(this, 'casePdfS3Path', casePdfS3Path, this.typeString());
  }

  getRetinalImagePaths() {
    return this.retinalImagePaths;
  }

  setRetinalImagePaths(retinalImagePaths) {
    return this.genericSetter(this, 'retinalImagePaths', retinalImagePaths, this.typeArray());
  }

  getReportDownload() {
    return this.report;
  }

  setReportDownload(reportDownload) {
    return (this.report = reportDownload);
  }

  deserialize(serializedData) {
    return super.deserialize(Case, serializedData);
  }
}

export default Case;
