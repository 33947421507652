import BaseModel from '../BaseModel';
import _ from 'lodash/core';

class ModelTest {
  // checks for equality of the serialized and deserialized data to assure the object is in good shape.
  static equalityCheck(data) {
    if (data instanceof BaseModel) {
      let serializedData = data.serialize();
      if (_.isEqual(data, data.deserialize(serializedData))) {
        console.log(data.constructor.name + ': Test returned no errors.');
        return true;
      } else {
        console.log(
          'Serialized and deserialized objects are not the same. Check the object fields again.'
        );
      }
    } else {
      console.log('wrong input type.');
    }
    return false;
  }
}

export default ModelTest;
