import AdminUser from '../AdminUser';
import ClinicUser from '../ClinicUser';
import Legal from '../Legal';
import Billing from '../Billing';
import Plan from '../Plan';
import Session from '../Session';
import Clinic from '../Clinic';
import Case from '../Case';

// create example objects for tests including the test called from TestMaster.js
class TestObjects {
  static createAdminUser() {
    let adminUser = new AdminUser();
    adminUser.setAdminUserId('132');
    adminUser.setEmail('test@test.com');
    adminUser.setName('TestClinic TestUser');
    adminUser.setModifiedAt(new Date());
    return adminUser;
  }

  static createClinicUser() {
    let clinicUser = new ClinicUser();
    clinicUser.setClinicUserId('1');
    clinicUser.setClinicId('132');
    clinicUser.setEmail('test@test.com');
    clinicUser.setName('TestClinic TestUser');
    clinicUser.setModifiedAt(new Date());
    return clinicUser;
  }

  static createLegal() {
    let legal = new Legal();
    legal.setLegalId('12');
    legal.setClinicId('132');
    legal.setTitle('Temp Title');
    legal.setLegalPdfS3Path('https://www.path.com');
    return legal;
  }

  static createBilling() {
    let billing = new Billing();
    billing.setBillingId('12');
    billing.setPlanId('132');
    billing.setPlanStart(new Date());
    billing.setPlanEnd(new Date());
    return billing;
  }

  static createPlan() {
    let plan = new Plan();
    plan.setPlanId('11');
    plan.setDesc('temp desc');
    return plan;
  }

  static createSession() {
    let session = new Session();
    session.setSessionId('11');
    session.setClinicUserId('22');
    session.setSessionType('temp session type');
    return session;
  }

  static createClinic() {
    let clinic = new Clinic();
    clinic.setBillingId('1142');
    clinic.setClinicId('4040');
    clinic.setCountry('Canada');
    clinic.setCity('London');
    clinic.setEmail('test@test.com');
    clinic.setPhoneNum('226-226-2266');
    clinic.setRegion('Ontario');
    clinic.setPostalCode('L1N1B1');
    clinic.setStreetAddress('205 Horton St.');
    clinic.setName('RA Clinic');
    clinic.setRepresentativeName('Rhino Rhino');
    return clinic;
  }

  static createCase() {
    let tCase = new Case();
    tCase.setCaseId('1234');
    tCase.setClinicId('4040');
    tCase.setCreatedBy('RhinoActive');
    tCase.setPatientId('3030');
    tCase.setPatientName('Rhino Patient');
    tCase.setSiteId('4988');
    tCase.setCamera('Digital Camera 204');
    tCase.setGender('Male');
    tCase.setRace('Asian');
    tCase.setHasDiabetes(true);
    tCase.setYearDiagnosed(2019);
    tCase.setDiabetesType('Type 2');
    tCase.setEyeDiseaseHistory('Lorem ipsum');
    tCase.setNotes('Dangerous');
    tCase.setResult('See Ophthalmologist');
    tCase.setStatus('Incomplete');
    tCase.setImageQuantity(3);
    tCase.setCasePdfS3Path('https://www.s3.image.path.com/');
    let paths = ['https://www.s3.image.path.com/23', 'https://www.s3.image.path.com/24'];
    tCase.setRetinalImagePaths(paths);
    return tCase;
  }
}

export default TestObjects;
