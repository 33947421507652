import { errorlog } from '@/libs/error-logs';
import { toast_error } from '@/libs/toast-wrappers';

import { POST, GET } from './base/index';
import { ENDPOINTS } from './endpoints';

/**
 export const GradeValues = {
    drgrade_string: {
        None: 'None',
        Mild: 'Mild',
        Moderate: 'Moderate',
        Severe: 'Severe',
        Proliferative: 'Proliferative',
    },
    referral_string: {
        "now": "0 months (NOW)",
        "return-1-mo": "1 month",
        "return-1-to-3-mo": "1-3 month",
        "return-3-to-6-mo": "3-6 months",
        "return-6-to-12-mo": "6-12 months",
        "return-12-to-24-mo": "12-24 months",
    }
}
 */

export type Grade = {
  createdUtc: string;
  case_id: string;
  user_email: string;
  readable_bool: boolean;
  evidenceOfDme_bool: boolean;
  drgrade_string: string;
  referral_string: string;
  otherDiseaseCustom_string: string;
  otherDiseaseFollowUp_string: string;
  otherDiseaseConcernForGlaucoma_bool: boolean;
  otherDiseaseMacularDrusen_bool: boolean;
  otherDiseaseMacularPigmentaryDisturbance_bool: boolean;
  otherDiseaseEpiretinalMembrane_bool: boolean;
  otherDiseaseChoroidalLesion_bool: boolean;
  noRetinopathy_bool: boolean;
  microaneurysms_bool: boolean;
  hardExudates_bool: boolean;
  cws_bool: boolean;
  mildRhs_bool: boolean;
  twoCriteriaFrom47_bool: boolean;
  mildRhsIn4Quadrants_bool: boolean;
  mildIrmaIn1to3Quadrants_bool: boolean;
  moderateRhsPlusMildIrma_bool: boolean;
  mildIrmaIn4Quadrants_bool: boolean;
  severeRhIn2To3Quadrants_bool: boolean;
  venousBeadingIn1Quadrant_bool: boolean;
  severeRhIn4Quadrants_bool: boolean;
  moderateToSevereIrmaIn1PlusQuadrants_bool: boolean;
  venousBeadingIn2PlusQuadrants_bool: boolean;
  nveGreaterThanHalfDaIn1PlusQuadrants_61_bool: boolean;
  nveGreaterThanHalfDaIn1PlusQuadrants_65_bool: boolean;
  nvdLessThanThreeQuarters_bool: boolean;
  largerNvdOrNveGreaterThanOrEqualToHalfDa_bool: boolean;
  viewPartiallyObscuredByVh_bool: boolean;
  signatureDataUri_string: string;
  urgent_bool?: boolean;
};

type SignGrade = {
  case_id: string;
  user_email: string;
  readable_bool: boolean;
  imagesReadable_string: string;
  evidenceOfDme_bool: boolean;
  drgrade_string: string;
  referral_string: string;
  otherDiseaseCustom_string: string;
  noRetinopathy_bool: boolean;
  microaneurysms_bool: boolean;
  hardExudates_bool: boolean;
  cws_bool: boolean;
  mildRhs_bool: boolean;
  twoCriteriaFrom47_bool: boolean;
  mildRhsIn4Quadrants_bool: boolean;
  mildIrmaIn1to3Quadrants_bool: boolean;
  moderateRhsPlusMildIrma_bool: boolean;
  mildIrmaIn4Quadrants_bool: boolean;
  severeRhIn2To3Quadrants_bool: boolean;
  venousBeadingIn1Quadrant_bool: boolean;
  severeRhIn4Quadrants_bool: boolean;
  moderateToSevereIrmaIn1PlusQuadrants_bool: boolean;
  venousBeadingIn2PlusQuadrants_bool: boolean;
  nveGreaterThanHalfDaIn1PlusQuadrants_61_bool: boolean;
  nveGreaterThanHalfDaIn1PlusQuadrants_65_bool: boolean;
  nvdLessThanThreeQuarters_bool: boolean;
  largerNvdOrNveGreaterThanOrEqualToHalfDa_bool: boolean;
  viewPartiallyObscuredByVh_bool: boolean;
  signatureDataUri_string: string;
  urgent_bool?: boolean;
};

export async function signGradeCase(grade: SignGrade) {
  try {
    const response = await POST(ENDPOINTS.GRADES, grade);
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/grade/signGradeCase', 'Error signing case');
    toast_error('Error signing case');
    return Promise.reject(error);
  }
}

export async function getTelehealthReport(grade_id: string) {
  try {
    const response = await GET<File>(ENDPOINTS.GRADE_TELEHEALTH_REPORT, {
      parameters: { grade_id },
      isFileRequest: true,
    });
    return response;
  } catch (error) {
    errorlog(
      error as Error,
      'src/api/grade/getTelehealthReport',
      'Error getting telehealth report'
    );
    toast_error('Error getting telehealth report');
    return Promise.reject(error);
  }
}
