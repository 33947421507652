/*
InputCheckbox, a check box and its title

An InputCheckbox item is a base level component and has no subcomponents
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component level arguments):
	inputId: the id of the check box and label
	desc: the description of the check box
*/

export class InputCheckbox extends React.Component {
  render() {
    return (
      <div className="InputCheckbox">
        <input
          checked={this.props.checked}
          disabled={this.props.disabled}
          onChange={this.props.onCheckChange}
          type="checkbox"
          id={'checkbox_' + this.props.inputId}
          required={this.props.required}
        />
        <label htmlFor={'checkbox_' + this.props.inputId}>
          <span>{this.props.desc}</span>
        </label>
      </div>
    );
  }
}

InputCheckbox.propTypes = {
  inputId: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

export default InputCheckbox;
