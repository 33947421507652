/*
InputRadio, a radio button that takes user input

An InputRadio item is a base level component and has no subcomponents
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component level arguments):
	value: the value of the radio button
	desc: the description of the radio button
	radioName: the name of the radio button
	callback: The onchange event handler
	required: whether or not this input group should be marked as required
*/

export class InputRadio extends React.Component {
  render() {
    return (
      <div className="InputRadio">
        <input
          type="radio"
          id={`radio_${this.props.radioName}_${this.props.value}_${this.props.radioId}`}
          value={this.props.value}
          checked={this.props.value}
          name={this.props.radioName}
          onChange={this.props.callback}
          required={this.props.required}
          disabled={this.props.disabled}
        />
        <label htmlFor={`radio_${this.props.radioName}_${this.props.value}_${this.props.radioId}`}>
          <span>{this.props.desc}</span>
        </label>
      </div>
    );
  }
}

InputRadio.propTypes = {
  desc: PropTypes.string.isRequired,
  radioId: PropTypes.string,
  radioName: PropTypes.string.isRequired,
  callback: PropTypes.func,
  required: PropTypes.bool,
};

InputRadio.defaultProps = {
  required: false,
};

export default InputRadio;
