import BaseModel from './BaseModel';

class Billing extends BaseModel {
  constructor(createdAt) {
    super(createdAt);

    this.billingId = null;
    this.planId = null;
    this.planStart = null;
    this.planEnd = null;
    this.title = null;
    this.description = null;
  }

  getTitle() {
    return this.title;
  }

  setTitle(title) {
    return this.genericSetter(this, 'title', title, this.typeString());
  }

  getDescription() {
    return this.description;
  }

  setDescription(description) {
    return this.genericSetter(this, 'description', description, this.typeString());
  }

  getBillingId() {
    return this.billingId;
  }

  setBillingId(billingId) {
    return this.genericSetter(this, 'billingId', billingId, this.typeString());
  }

  getPlanId() {
    return this.planId;
  }

  setPlanId(planId) {
    return this.genericSetter(this, 'planId', planId, this.typeString());
  }

  getPlanStart() {
    return new Date(this.planStart);
  }

  setPlanStart(planStart) {
    return this.genericSetter(this, 'planStart', planStart, this.typeDate());
  }

  getPlanEnd() {
    return new Date(this.planEnd);
  }

  setPlanEnd(planEnd) {
    return this.genericSetter(this, 'planEnd', planEnd, this.typeDate());
  }

  deserialize(serializedData) {
    return super.deserialize(Billing, serializedData);
  }
}

export default Billing;
