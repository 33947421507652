import { InputHTMLAttributes, forwardRef } from 'react';

type CheckboxFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  label: string;
  name: string;
};

/**
 * CheckboxField, consists of a div with label and an input field
 * forwardRef is used to pass the ref to the input field, since we are using ReactFormHook
 * @props:
 * extends InputHTMLAttributes<HTMLInputElement>
 * - label: the label of the checkbox field (required)
 * - name: the name of the checkbox field (required)
 */
export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>((props, ref) => {
  const { id, name, label, required, className = '', ...rest } = props;

  return (
    <div className={`inline-flex items-center gap-1 ${className}`}>
      <input
        {...rest}
        ref={ref}
        id={id ?? name}
        name={name}
        required={required}
        className="m-0"
        type="checkbox"
      />
      {label && (
        <label className="font-medium text-gray-500" htmlFor={id ?? name}>
          {label}
          {required && ' *'}
        </label>
      )}
    </div>
  );
});

CheckboxField.displayName = 'CheckboxField';
