import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SignGradeForm, useGradeFormLogic } from '@/hooks/use-grade-form-logic';

import { CheckboxField } from '@/components/form/checkbox-field';

type GradeFormSubQuestionsProps = {
  register: UseFormRegister<SignGradeForm>;
  watch: UseFormWatch<SignGradeForm>;
  setValue: UseFormSetValue<SignGradeForm>;
  disabled?: boolean;
};

/**
 * The sub questions for the grading form
 */
export function GradeFormSubQuestions(props: GradeFormSubQuestionsProps) {
  const { register, watch, setValue, disabled } = props;
  const { disabledFields } = useGradeFormLogic({ watch, setValue });
  const { t } = useTranslation();

  const drgrade = props.watch('drgrade_string');
  const levels = ['none', 'mild', 'moderate', 'severe', 'proliferative'];

  // this 4 are repeated in the form, so we need to keep track of their state
  const moderateRhsPlusMildIrmaRegister = register('moderateRhsPlusMildIrma_bool');
  const mildIrmaIn4QuadrantsRegister = register('mildIrmaIn4Quadrants_bool');
  const severeRhIn2To3QuadrantsRegister = register('severeRhIn2To3Quadrants_bool');
  const venousBeadingIn1QuadrantRegister = register('venousBeadingIn1Quadrant_bool');

  return (
    <>
      {Boolean(drgrade) && (
        <h2 className=" text-gray-700 text-lg font-semibold">
          {t('grade-subtitle-question-select')}
        </h2>
      )}
      {/* 10 None  */}
      {drgrade === 'none' && (
        <>
          <h3 className="input-label my-4">{t('grade-subtitle-question-none')}</h3>
          <CheckboxField
            disabled={disabled}
            label={t('grade-subquestion-cnone-no-retinopathy')}
            {...register('noRetinopathy_bool')}
          />
        </>
      )}

      {/* 20 Very Mild - 35 Mild NPDR */}
      {levels.indexOf(drgrade) >= 1 && (
        <>
          <h3 className="input-label mt-6 mb-4">{t('grade-subtitle-question-mild')}</h3>
          <div className="grid grid-cols-3 gap-x-4 gap-y-2">
            <CheckboxField
              label={t('grade-subquestion-cmild-microaneurysms')}
              disabled={disabled}
              {...register('microaneurysms_bool')}
            />
            <CheckboxField
              label={t('grade-subquestion-cmild-hard-exudates')}
              disabled={disabled}
              {...register('hardExudates_bool')}
            />
            <CheckboxField
              label={t('grade-subquestion-cmild-cws')}
              disabled={disabled}
              {...register('cws_bool')}
            />
            <CheckboxField
              label={t('grade-subquestion-cmild-rhs')}
              disabled={disabled}
              {...register('mildRhs_bool')}
            />
          </div>
        </>
      )}

      {/* 43 Moderate NPDR -> 47 Moderately Severe NPDR */}
      {levels.indexOf(drgrade) >= 2 && (
        <>
          <h3 className="input-label mt-6 mb-4">{t('grade-subtitle-question-moderate')}</h3>
          <div className="grid grid-cols-3 gap-x-4 gap-y-2">
            <CheckboxField
              className="col-span-3"
              disabled={disabledFields.includes('mildRhsIn4Quadrants_bool') || disabled}
              label={t('grade-subquestion-cmoderate-rhs-in-4-quadrants')}
              {...register('mildRhsIn4Quadrants_bool')}
            />
            <CheckboxField
              disabled={disabledFields.includes('mildIrmaIn1to3Quadrants_bool') || disabled}
              label={t('grade-subquestion-cmoderate-irma-in-1-to-3')}
              {...register('mildIrmaIn1to3Quadrants_bool')}
            />
          </div>
          <h3 className="input-label mt-6 mb-4">
            {t('grade-subtitle-question-moderately-severe')}
          </h3>
          <div className="grid grid-cols-3 gap-x-4 gap-y-2">
            <CheckboxField
              className="col-span-3"
              disabled={disabledFields.includes('moderateRhsPlusMildIrma_bool') || disabled}
              label={t('grade-subquestion-cmoderate-moderate-rhs-plus-irma')}
              {...moderateRhsPlusMildIrmaRegister}
            />
            <CheckboxField
              disabled={disabledFields.includes('mildIrmaIn4Quadrants_bool') || disabled}
              label={t('grade-subquestion-cmoderate-irma-in-4')}
              {...mildIrmaIn4QuadrantsRegister}
            />
            <CheckboxField
              disabled={disabledFields.includes('severeRhIn2To3Quadrants_bool') || disabled}
              label={t('grade-subquestion-cmoderate-severe-rh-in-2-to-3')}
              {...severeRhIn2To3QuadrantsRegister}
            />
            <CheckboxField
              disabled={disabledFields.includes('venousBeadingIn1Quadrant_bool') || disabled}
              label={t('grade-subquestion-cmoderate-venous-beading')}
              {...venousBeadingIn1QuadrantRegister}
            />
          </div>
        </>
      )}

      {/* 53 Severe NPDR */}
      {levels.indexOf(drgrade) >= 3 && (
        <>
          <h3 className="input-label mt-6 mb-4">{t('grade-subtitle-question-severe')}</h3>
          <div className="grid grid-cols-3 gap-x-4 gap-y-2">
            {/* repeats 47 Moderately Severe NPDR items */}
            <CheckboxField
              className="col-span-3"
              disabled={disabledFields.includes('moderateRhsPlusMildIrma_bool') || disabled}
              label={t('grade-subquestion-cmoderate-moderate-rhs-plus-irma')}
              name="moderateRhsPlusMildIrma_bool"
              onChange={moderateRhsPlusMildIrmaRegister.onChange}
              checked={watch('moderateRhsPlusMildIrma_bool')}
            />
            <CheckboxField
              disabled={disabledFields.includes('mildIrmaIn4Quadrants_bool') || disabled}
              label={t('grade-subquestion-cmoderate-irma-in-4')}
              name="mildIrmaIn4Quadrants_bool"
              onChange={mildIrmaIn4QuadrantsRegister.onChange}
              checked={watch('mildIrmaIn4Quadrants_bool')}
            />
            <CheckboxField
              disabled={disabledFields.includes('severeRhIn2To3Quadrants_bool') || disabled}
              label={t('grade-subquestion-cmoderate-severe-rh-in-2-to-3')}
              name="severeRhIn2To3Quadrants_bool"
              onChange={severeRhIn2To3QuadrantsRegister.onChange}
              checked={watch('severeRhIn2To3Quadrants_bool')}
            />
            <CheckboxField
              disabled={disabledFields.includes('venousBeadingIn1Quadrant_bool') || disabled}
              label={t('grade-subquestion-cmoderate-venous-beading')}
              name="venousBeadingIn1Quadrant_bool"
              onChange={venousBeadingIn1QuadrantRegister.onChange}
              checked={watch('venousBeadingIn1Quadrant_bool')}
            />
            {/* severe */}
            <CheckboxField
              disabled={disabledFields.includes('severeRhIn4Quadrants_bool') || disabled}
              label={t('grade-subquestion-csevere-rh-in-4-quadrants')}
              {...register('severeRhIn4Quadrants_bool')}
            />
            <CheckboxField
              className="col-span-2"
              disabled={
                disabledFields.includes('moderateToSevereIrmaIn1PlusQuadrants_bool') || disabled
              }
              label={t('grade-subquestion-csevere-moderate-to-severe-irma')}
              {...register('moderateToSevereIrmaIn1PlusQuadrants_bool')}
            />
            <CheckboxField
              disabled={disabledFields.includes('venousBeadingIn2PlusQuadrants_bool') || disabled}
              label={t('grade-subquestion-csevere-venous-beading-in-2-plus-quadrants')}
              {...register('venousBeadingIn2PlusQuadrants_bool')}
            />
          </div>
        </>
      )}

      {/* 61 Mild PDR -> 81, 85 Advanced PDR */}
      {levels.indexOf(drgrade) >= 4 && (
        <>
          <h3 className="input-label mt-6 mb-4">{t('grade-subtitle-question-mild-pdr')}</h3>
          <CheckboxField
            disabled={
              disabledFields.includes('nveGreaterThanHalfDaIn1PlusQuadrants_61_bool') || disabled
            }
            label={t('grade-subquestion-cproliferative-nve-greater-than-half-in-1-plus-quadrants')}
            {...register('nveGreaterThanHalfDaIn1PlusQuadrants_61_bool')}
          />

          <h3 className="input-label mt-6 mb-4">{t('grade-subtitle-question-moderate-pdr')}</h3>
          <CheckboxField
            disabled={
              disabledFields.includes('nveGreaterThanHalfDaIn1PlusQuadrants_65_bool') || disabled
            }
            label={t('grade-subquestion-cproliferative-nve-greater-than-half-in-1-plus-quadrants')}
            {...register('nveGreaterThanHalfDaIn1PlusQuadrants_65_bool')}
          />
          <CheckboxField
            disabled={disabledFields.includes('nvdLessThanThreeQuarters_bool') || disabled}
            label={t('grade-subquestion-cproliferative-nvd-lest-than-3-quarters')}
            {...register('nvdLessThanThreeQuarters_bool')}
          />

          <h3 className="input-label mt-6 mb-4">{t('grade-subtitle-question-high-risk-pdr')}</h3>
          <CheckboxField
            disabled={
              disabledFields.includes('largerNvdOrNveGreaterThanOrEqualToHalfDa_bool') || disabled
            }
            label={t('grade-subquestion-cproliferative-larger-nvd-or-nve')}
            {...register('largerNvdOrNveGreaterThanOrEqualToHalfDa_bool')}
          />

          <h3 className="input-label mt-6 mb-4">{t('grade-subtitle-question-advanced-pdr')}</h3>
          <CheckboxField
            disabled={disabledFields.includes('viewPartiallyObscuredByVh_bool') || disabled}
            label={t('grade-subquestion-cproliferative-view-partially-obscured')}
            {...register('viewPartiallyObscuredByVh_bool')}
          />
        </>
      )}
      {drgrade ? <span className="mt-12" /> : null}
    </>
  );
}
