import useSWR from 'swr';

import { ENDPOINTS } from '@/api/endpoints';
import { getCountries } from '@/api/countries';

export function useCountriesOptions() {
  const { data: countries } = useSWR(ENDPOINTS.COUNTRIES, () => getCountries());

  return { COUNTRIES: countries };
}
