/*
StatLineItem, a single line showing user statistics information

A stat line item consists of
	a label (contained directly within this component)
	a StatValue to display the value of the stat
*/

import React from 'react';
import PropTypes from 'prop-types';

import StatValue from './StatValue.jsx';

/*
props (component level arguments):
	label: the label for this line item (as a string)
	value: the value for this line item (numeric)
	extraClasses: (optional) additional classes to include on this component for styling purposes
*/
class StatLineItem extends React.Component {
  render() {
    return (
      <div className={'StatLineItem ' + this.props.extraClasses}>
        <div className="grid-x grid-padding-x">
          <div className="small-9 cell statlineitem-label">{this.props.label}</div>
          <div className="small-3 cell statlineitem-value">
            <StatValue value={this.props.value} />
          </div>
        </div>
      </div>
    );
  }
}

StatLineItem.defaultProps = {
  label: '',
  value: '-',
  extraClasses: '',
};

StatLineItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
};

export default StatLineItem;
