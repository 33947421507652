import {
  API_BASE_ENDPOINT,
  AWS_HOST,
  AWS_REGION_ID,
  AWS_SERVICE,
  AWS_BASE_PATH,
  AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY,
} from '@/api/constants';

// This is an example config.js file for the Vitazi.ai Clinic Portal
// it can be used as a default for a new install running the Vitazi.ai Clinic portal
var config = {
  backend_host: API_BASE_ENDPOINT,

  aws_host: AWS_HOST,
  aws_region_id: AWS_REGION_ID,
  aws_service: AWS_SERVICE,

  // if aws_base_path is set to be /v1 then the v1 endpoints will be used
  aws_base_path: AWS_BASE_PATH, //use v1 for web page

  // these keys can be empty, since session keys are acquired at login
  aws_access_key_id: AWS_ACCESS_KEY_ID,
  aws_secret_access_key: AWS_SECRET_ACCESS_KEY,
};

export default config;
