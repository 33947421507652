/*
FileManager - A display to upload image files as well as view the uploading progress.

A File Manager consists of a FileSelect component as well as an array of FileUpload Components
*/

import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import FileSelect from './FileSelect';
import FileUpload from './FileUpload';

/*
props (component-level arguments):
	label: A title for the component.
	direction: A written direction to the user about how to use this.
	idUniqueIdentifier: a way to differentiate different File Upload Components that are on the same page. This is a required field.
	callback: A handler to inform the parent that the proper number of files have been uploaded
state:
	fileList: An array of FileUpload components to render to the screen
	fileMeta: upload meta information about the files
*/
class FileManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileMeta: [],

      //NOTE: the next file key CANNOT be derived from fileMeta
      //because files can be deleted after they are selected
      nextFileKey: 0,
    };
    this.getFileIdxByName = this.getFileIdxByName.bind(this);
    this.updateFileList = this.updateFileList.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.uploadProgressCallback = this.uploadProgressCallback.bind(this);
    this.uploadCompletedCallback = this.uploadCompletedCallback.bind(this);
    this.uploadErrorCallback = this.uploadErrorCallback.bind(this);
    this.uniqueCheckCallback = this.uniqueCheckCallback.bind(this);
  }

  outputLabel(label) {
    if (!label) {
      return null;
    }
    return <label className="file-manager-label">{label}</label>;
  }

  outputDirection(direction) {
    if (!direction) {
      return null;
    }
    return <p className="file-manager-direction">{direction}</p>;
  }

  //returns the given file's index in the component stat file list
  //if the file is not found, returns -1
  getFileIdxByName(file_name) {
    for (let i = 0; i < this.state.fileMeta.length; i++) {
      if (this.state.fileMeta[i].name === file_name) {
        return i;
      }
    }
    return -1;
  }

  updateFileList(file) {
    let fileMeta = this.state.fileMeta;

    fileMeta.push({
      name: file.name,
      key: this.state.nextFileKey,
      fileID: 'file_' + this.state.nextFileKey,
      fileData: file,
      deleteCallback: this.handleDelete,
      progressPerc: 0,
      uploadCompleted: false,
      uploadError: false,
      uploadErrorMsg: '',
    });

    this.setState({
      fileMeta: fileMeta,
      nextFileKey: this.state.nextFileKey + 1,
    });
  }

  uploadProgressCallback(file_name, e) {
    let progressPerc = (e.loaded / Math.max(e.total, 1)) * 100;
    let f_idx = this.getFileIdxByName(file_name);
    if (f_idx >= 0) {
      let fileMeta = this.state.fileMeta;
      let singleFileMeta = fileMeta[f_idx];
      singleFileMeta.progressPerc = progressPerc;
      fileMeta[f_idx] = singleFileMeta;

      this.setState({
        fileMeta: fileMeta,
      });
    }
  }

  uploadCompletedCallback(file_name) {
    console.log('uploadCompletedCallback called; file_name=', file_name);
    let f_idx = this.getFileIdxByName(file_name);
    if (f_idx >= 0) {
      let fileMeta = this.state.fileMeta;
      let singleFileMeta = fileMeta[f_idx];
      //if the file finished uploading then it's definitely 100% done
      singleFileMeta.progressPerc = 100;
      singleFileMeta.uploadCompleted = true;
      singleFileMeta.uploadError = false;
      singleFileMeta.uploadErrorMsg = '';
      fileMeta[f_idx] = singleFileMeta;

      this.setState({
        fileMeta: fileMeta,
      });
    }

    if (this.props.uploadCallback) {
      this.props.uploadCallback(
        this.state.fileMeta.filter((f) => f.uploadCompleted).map((f) => f.fileData),
        this.state.fileMeta.length
      );
    }
  }

  uploadErrorCallback(file_name, xhr) {
    toast.error(
      'Could not upload image; please ensure the filename does not contain plus (+) or comma (,) and try again.'
    );

    let f_idx = this.getFileIdxByName(file_name);
    if (f_idx >= 0) {
      let fileMeta = this.state.fileMeta;
      let singleFileMeta = fileMeta[f_idx];
      //if the file finished uploading then it's definitely 100% done
      singleFileMeta.progressPerc = 100;
      singleFileMeta.uploadCompleted = true;
      singleFileMeta.uploadError = true;
      try {
        singleFileMeta.uploadErrorMsg = JSON.parse(xhr.response)['message'];
      } catch (e) {
        singleFileMeta.uploadErrorMsg = 'Unknown Upload Error';
      }
      fileMeta[f_idx] = singleFileMeta;

      this.setState({
        fileMeta: fileMeta,
      });
    }

    if (this.props.uploadCallback) {
      this.props.uploadCallback(
        this.state.fileMeta.filter((f) => f.uploadCompleted).map((f) => f.fileData),
        this.state.fileMeta.length
      );
    }
  }

  //returns true if the given file_name is unique (hasn't been used) and false if it's NOT unique (has been used)
  uniqueCheckCallback(file_name) {
    let dup_file_meta = this.state.fileMeta.filter((elem) => elem.name === file_name);
    return dup_file_meta.length === 0;
  }

  handleDelete(id) {
    let singleFileMeta = this.state.fileMeta.filter((elem) => elem.fileID === id)[0];
    if (singleFileMeta.uploadCompleted === false) {
      //deletes are not possible until uploads are complete
      return;
    }

    if (this.props.deleteCallback) {
      this.props.deleteCallback(singleFileMeta);
    }

    //remove the newly-deleted file from the file input list if it is present
    //this will allow re-uploading of the file
    let fileInputId = 'fileInput_' + this.props.idUniqueIdentifier;
    let fileInput = document.getElementById(fileInputId);
    if (fileInput.files.length === 1) {
      fileInput.value = '';
    }

    let newFileMeta = this.state.fileMeta.filter((elem) => elem.fileID !== id);
    this.setState({
      fileMeta: newFileMeta,
      //NOTE: we intentionally do NOT reset nextFileKey when a delete occurs in order to maintain id uniqueness
    });

    //NOTE: after the delete operation is completed the submit button should be updated in accordance with the deleted file
    //so we call the upload callback again to allow for that
    if (this.props.uploadCallback) {
      let valid_files = this.state.fileMeta.filter((f) => f.fileID !== id);
      this.props.uploadCallback(
        valid_files.filter((f) => f.uploadCompleted).map((f) => f.fileData),
        valid_files.length
      );
    }
  }

  render() {
    return (
      <div className={`FileManager `}>
        {this.outputLabel(this.props.label)}
        {this.outputDirection(this.props.direction)}
        <FileSelect
          parentComponent={this}
          idUniqueIdentifier={this.props.idUniqueIdentifier}
          updateFileList={this.updateFileList}
          caseId={this.props.caseId}
          uploadProgressCallback={this.uploadProgressCallback}
          uploadCompletedCallback={this.uploadCompletedCallback}
          uploadErrorCallback={this.uploadErrorCallback}
          uniqueCheckCallback={this.uniqueCheckCallback}
          dropInstructions={this.props.dropInstructions ? this.props.dropInstructions : null}
          browseInstructions={this.props.browseInstructions ? this.props.browseInstructions : null}
        />
        {this.state.fileMeta.map(function (singleFileMeta) {
          return (
            <FileUpload
              key={singleFileMeta.key}
              fileID={singleFileMeta.fileID}
              fileData={singleFileMeta.fileData}
              fileMeta={singleFileMeta}
              deleteCallback={singleFileMeta.deleteCallback}
            />
          );
        })}
      </div>
    );
  }
}

FileManager.defaultProps = {
  caseId: null,
};

FileManager.propTypes = {
  label: PropTypes.string,
  direction: PropTypes.string,
  idUniqueIdentifier: PropTypes.string.isRequired,
  uploadCallback: PropTypes.func,
  caseId: PropTypes.string,
};

export default FileManager;
