import { Trans } from 'react-i18next';

import udi_img2 from '@/assets/images/UniqueDeviceIdentifier.png';

export function AuthSplash() {
  return (
    <div className="cell small-12 large-5 login-splash-panel">
      <div className="title-and-mission-statement">
        <Trans i18nKey="splash-welcome">
          <h1 className="title">
            <span className="pre-title">Welcome to</span> Vitazi.ai
          </h1>
        </Trans>
        <Trans i18nKey="splash-description">
          <p className="mission-statement">Vitazi.ai Telehealth Portal</p>
        </Trans>
        <div className="splash-footer">
          <p>Vitazi.ai Inc.</p>
          <p>3900 Paseo del Sol,</p>
          <p>Santa Fe, NM 87507</p>
          <a className="!text-white" href="https://www.vitazi.ai/" target="_blank" rel="noreferrer">
            Vitazi.ai
          </a>
          <div className="flex-full-center gap-2 text-xs mt-8  px-4 py-2 rounded">
            <span>Rx Only</span>
            <img
              className="splash-footer-logo"
              src={udi_img2}
              alt="Unique Device Identifier Logo"
              width={42}
            />
            <span>00860012221608</span>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * use this as reference when we want to change to the new version of the auth layout flow
<section className="splash-panel-section">
  <div className="splash-panel-content">
    <Trans i18nKey="splash-welcome">
      <h1 className="title">
        <span className="pre-title">Welcome to</span>{' '}
        <a className="" href="https://www.vitazi.ai/">
          Vitazi.ai
        </a>
      </h1>
    </Trans>
    <Trans i18nKey="splash-description">
      <p className="mission-statement">Diabetic Retinopathy Screening</p>
    </Trans>
  </div>
</section>
  // "splash-description": "<0>Diabetic Retinopathy Screening</0>",

 */
