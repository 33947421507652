import {
  useState,
  ReactNode,
  TableHTMLAttributes,
  Fragment,
  ComponentType,
  useCallback,
} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faTrashXmark,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

import { RequestStateValue } from './state-request-value';
import { GradeStateValue } from './state-grade-value';
import { StatusValue } from './status-value';

type TableHeaderBase = {
  key: string;
  label?: string;
  type?: 'value';
  format?: (value: TableValue) => ReactNode;
  tooltip?: (value: TableData[number]) => ReactNode;
  action?: ReactNode;
};

type TableHeaderAction<T> = {
  key: string;
  type: 'actions';
  edit: (item: T) => void;
  delete: (item: T) => void;
};

type TableHeaderState = {
  key: string;
  label?: string;
  type: 'request-state' | 'grade-state' | 'status';
  format?: (value: TableValue) => ReactNode;
  tooltip?: (value: TableData[number]) => ReactNode;
  action?: ReactNode;
};

type TableHeaderCollapsible = {
  key: string;
  type: 'collapsible';
  label?: string;
};

type TableHeader<T> =
  | TableHeaderBase
  | TableHeaderAction<T>
  | TableHeaderState
  | TableHeaderCollapsible;
type TableValue = unknown;
type TableData = Record<string, TableValue>[];

type TableProps<T extends TableData> = TableHTMLAttributes<HTMLTableElement> & {
  headers: TableHeader<T[number]>[];
  data: T;
  bodyClassName?: string;
  onRowClick?: (item: T[number]) => void;
  CollapsibleComponent?: ComponentType<{ item: T[number] }>;
};

const ACTIONS = 'actions';
const COLLAPSIBLE = 'collapsible';

export function Table<T extends TableData>(props: TableProps<T>) {
  const {
    headers = [],
    data = [],
    onRowClick,
    bodyClassName = '',
    className = '',
    CollapsibleComponent,
    ...rest
  } = props;
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const toggleRow = useCallback(
    (index: number) => {
      setExpandedRows((prev) =>
        prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
      );
    },
    [setExpandedRows]
  );

  return (
    <table
      className={`CustomTable table-auto border-collapse min-w-full divide-y divide-gray-300  ${className}`}
      {...rest}
    >
      <thead>
        <tr>
          {headers.map((header) => {
            const key = `table-header-${header.key}`;
            const isActionsColumn = header?.type === ACTIONS;
            const isCollapsibleColumn = header?.type === COLLAPSIBLE;
            return (
              <th
                key={key}
                className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {!isActionsColumn && !isCollapsibleColumn ? (
                  <>
                    {header.label ?? header.key}
                    {header.action ? <span className="ml-1">{header.action}</span> : null}
                  </>
                ) : isCollapsibleColumn ? (
                  header.label
                ) : null}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={`${bodyClassName} divide-y divide-gray-200`}>
        {data.map((row, index) => (
          <Fragment key={`table-row-group-${index}`}>
            <tr
              key={`table-row-${index}`}
              className="bg-white"
              onClick={onRowClick ? () => onRowClick(row) : undefined}
              role={onRowClick ? 'button' : undefined}
              tabIndex={onRowClick ? 0 : undefined}
              style={onRowClick ? { cursor: 'pointer' } : undefined}
            >
              {headers.map((header) => {
                const key = `table-row-${index}-${header.key}`;

                if (header.type === ACTIONS) {
                  return (
                    <td key={key} className="text-gray-600 hover:text-gray-900">
                      <button
                        className="text-gray-600 hover:text-gray-900"
                        onClick={() => {
                          header.edit(row);
                        }}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        className="text-gray-600 hover:text-gray-900 ml-2"
                        onClick={() => {
                          header.delete(row);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashXmark} />
                      </button>
                    </td>
                  );
                }

                if (header.type === COLLAPSIBLE) {
                  return (
                    <td
                      key={key}
                      className="cursor-pointer hover:bg-muted/50"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleRow(index);
                      }}
                    >
                      {expandedRows.includes(index) ? (
                        <FontAwesomeIcon className="inline mr-2" icon={faChevronDown} />
                      ) : (
                        <FontAwesomeIcon className="inline mr-2" icon={faChevronRight} />
                      )}
                      {header.label}
                    </td>
                  );
                }

                const value = header.format ? header.format(row[header.key]) : row[header.key];

                const tooltip = header.tooltip ? (
                  <Tooltip id={key} place="top" clickable>
                    {header.tooltip(row)}
                  </Tooltip>
                ) : null;

                if (header.type === 'status') {
                  return (
                    <td key={key} data-tooltip-id={key}>
                      <span data-tooltip-id={key}>
                        <StatusValue value={value as boolean} />
                      </span>
                      {tooltip}
                    </td>
                  );
                }

                if (header.type === 'request-state') {
                  return (
                    <td key={key} data-tooltip-id={key}>
                      <span data-tooltip-id={key}>
                        <RequestStateValue value={value as string} />
                      </span>
                      {tooltip}
                    </td>
                  );
                }

                if (header.type === 'grade-state') {
                  return (
                    <td key={key} data-tooltip-id={key}>
                      <span data-tooltip-id={key}>
                        <GradeStateValue value={value as number} />
                      </span>
                      {tooltip}
                    </td>
                  );
                }

                const plainValue = (value as ReactNode) ?? '-';

                return (
                  <td key={key} className="whitespace-nowrap">
                    <span data-tooltip-id={key}>
                      {typeof plainValue === 'string' ? String(plainValue) : plainValue}
                    </span>
                    {tooltip}
                  </td>
                );
              })}
            </tr>
            {expandedRows.includes(index) && CollapsibleComponent && (
              <tr className="nested shadow-inner">
                <td colSpan={headers.length} className="pr-6">
                  <div className=" drop-shadow-sm">
                    <CollapsibleComponent item={row} />
                  </div>
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
}
