import { HTMLAttributes, ReactNode } from 'react';

type RadioGroupProps = HTMLAttributes<HTMLDivElement> & {
  label: ReactNode;
  helper?: {
    text: string;
    link: string;
  };
};

export function RadioGroup(props: RadioGroupProps) {
  const { label, className = '', helper, ...rest } = props;

  return (
    <fieldset className={`InputFieldBlock ${className}`}>
      <label className="input-label">
        <span className="mr-1">{label}</span>
        {helper ? (
          <a href={helper.link} target="_blank" rel="noopener noreferrer" className="text-blue-500">
            {helper.text}
          </a>
        ) : null}
      </label>
      <div className="inline-flex items-center flex-wrap gap-2 mt-2" {...rest} />
    </fieldset>
  );
}
