import BaseModel from './BaseModel';

class ClinicUser extends BaseModel {
  constructor(createdAt) {
    super(createdAt);

    this.clinicUserId = null;
    this.clinicId = null;
    this.name = null;
    this.email = null;
    this.stringColorPair = {};
  }

  getStringColorPair() {
    return this.stringColorPair;
  }

  setStringColorPair(stringColorPair) {
    return this.genericSetter(this, 'stringColorPair', stringColorPair, this.typeObject());
  }

  getClinicUserId() {
    return this.clinicUserId;
  }

  setClinicUserId(clinicUserId) {
    return this.genericSetter(this, 'clinicUserId', clinicUserId, this.typeString());
  }

  getClinicId() {
    return this.clinicId;
  }

  setClinicId(clinicId) {
    return this.genericSetter(this, 'clinicId', clinicId, this.typeString());
  }

  getName() {
    return this.name;
  }

  setName(name) {
    return this.genericSetter(this, 'name', name, this.typeString());
  }

  getEmail() {
    return this.email;
  }

  setEmail(email) {
    return this.genericSetter(this, 'email', email, this.typeString());
  }

  deserialize(serializedData) {
    return super.deserialize(ClinicUser, serializedData);
  }
}

export default ClinicUser;
