import React from 'react';
import { toast_error } from '@/libs/toast-wrappers';

import LoadOverlay from '../lib-medical-portal/components/LoadOverlay.jsx';

import { getCurrentBilling } from '@/api/billing';
import moment from 'moment';

import { ERROR_FETCH_BILLING, ERROR_MESSAGE_TRY_AGAIN_EMAIL } from '@/utils/messages';

import { logout } from '@/libs/session-management';

//internationalization and translation support
import i18n from '@/libs/i18n.js';
import { Trans } from 'react-i18next';

const unlimited = 'UNLIMITED';

class Billing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: null,
      desc: null,
      start_date: null,
      days_remaining: null,
      load_complete: false,
      load_error: false,
      load_error_message: '',
    };

    this.populateBillingData = this.populateBillingData.bind(this);
  }

  componentDidMount() {
    this.prepRealData();
  }

  prepRealData() {
    this.setState({
      load_complete: false,
    });

    getCurrentBilling().then((response) => {
      this.populateBillingData(response);
      this.setState({
        load_complete: true,
      });
    });
    // aws_api_call.get_rqst(
    //   '/billing/plans/current',
    //   (function (component) {
    //     return function (xhr) {
    //       let billingObject = JSON.parse(xhr.response);
    //       component.populateBillingData(billingObject);
    //       component.setState({
    //         load_complete: true,
    //       });
    //     };
    //   })(this),
    //   { 'Content-Type': 'application/json' },
    //   (function (component) {
    //     return function (xhr) {
    //       toast_error(ERROR_FETCH_BILLING + ' ' + ERROR_MESSAGE_TRY_AGAIN_EMAIL);
    //       console.log('error - prepRealData() code: ' + xhr.status);
    //       // 403 is the token expired for the eyestar api. log the user out if sessions expires
    //       if (xhr.status === 403) {
    //         logout();
    //       } else {
    //         component.setState({
    //           load_complete: true,
    //           load_error: true,
    //           load_error_message: (
    //             <div>
    //               <h2>{ERROR_FETCH_BILLING}</h2>
    //               <p>{ERROR_MESSAGE_TRY_AGAIN_EMAIL}</p>
    //             </div>
    //           ),
    //         });
    //       }
    //     };
    //   })(this)
    // );
  }

  populateBillingData(billing) {
    let dateCreated = billing.start_date;
    let colonCount = 0;
    let indexLastColon = -1;
    // date returned from server contains milliseconds which is incompatible with moment. Thus, remove milliseconds.
    for (let i = 0; i < dateCreated.length; i++) {
      if (dateCreated.charAt(i) === ':') {
        if (colonCount === 2) {
          indexLastColon = i;
          break;
        } else {
          colonCount++;
        }
      }
    }

    if (indexLastColon !== -1) {
      dateCreated = dateCreated.substring(0, indexLastColon);
    }

    this.setState({
      title: billing.title,
      desc: billing.description,
      start_date: moment(dateCreated).format('MM/DD/YYYY'),
      days_remaining:
        billing.end_date === '?'
          ? unlimited
          : moment.duration(moment(billing.end_date).diff(moment(billing.start_date))).asDays(),
    });
  }

  render() {
    return (
      <div className="Billing">
        <div className="billing-page-left-white-wrapper">
          <div className="billing-page-title">{i18n.t('billing-title')}</div>
          <div className="current-plan">{i18n.t('billing-current-plan')}</div>
          <div className="plan-name">{this.state.title}</div>
          <div className="plan-desc">{this.state.desc}</div>
          <span className="plan-start-title">{i18n.t('billing-plan-start')}</span>
          <span className="plan-start-date">{this.state.start_date}</span>
        </div>

        <div className="billing-page-right-blue-wrapper">
          <div
            className={
              this.state.days_remaining === unlimited ? 'unlimited-days' : 'number-days-remaining'
            }
          >
            {this.state.days_remaining}
          </div>
          <Trans i18nKey="billing-days-left">
            <div className="text-days-remaining">Days Remaining</div>
            <div className="text-on-current-plan">ON CURRENT PLAN</div>
          </Trans>
        </div>
        <LoadOverlay
          show={this.state.load_error || !this.state.load_complete}
          error={this.state.load_error}
          errorMessage={this.state.load_error_message}
        />
      </div>
    );
  }
}

export default Billing;
