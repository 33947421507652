import BaseModel from './BaseModel';

class Plan extends BaseModel {
  constructor(createdAt) {
    super(createdAt);

    this.planId = null;
    this.desc = null;
  }

  getPlanId() {
    return this.planId;
  }

  setPlanId(planId) {
    return this.genericSetter(this, 'planId', planId, this.typeString());
  }

  getDesc() {
    return this.desc;
  }

  setDesc(desc) {
    return this.genericSetter(this, 'desc', desc, this.typeString());
  }

  deserialize(serializedData) {
    return super.deserialize(Plan, serializedData);
  }
}

export default Plan;
