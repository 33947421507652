// All this functions are using when we are fetching data from the server.
// this is part of the first implementation of the project, we had moved to typescript
// refer this file for the first implementation src/react-utils/src/libgetdata.js

//remove the get data from a url
export function url_without_get_data(url: string) {
  const get_start_idx = url.indexOf('?');
  //if there is no get data at all then return the original url
  if (get_start_idx < 0) {
    return url;
  }
  return url.substring(0, get_start_idx);
}

//put all the get data from the url into a javascript object
export function parse_get_data(url: string) {
  const get_data: Record<string, unknown> = {};
  const get_start_idx = url.indexOf('?');
  //if there is no get data at all then return an empty object
  if (get_start_idx < 0) {
    return get_data;
  }
  url = url.substring(get_start_idx + 1);
  //while there is get data
  let eq_idx = url.indexOf('=');
  while (eq_idx >= 0) {
    //determine the name and value of the get data based on the pre-defined delimeters
    const name = url.substring(0, eq_idx);
    let end_idx = url.indexOf('&');
    if (end_idx < 0) {
      end_idx = url.length;
    }

    //strip off any trailing hash data, as that's not part of the GET data,
    //but rather is a separate thing sometimes found at the end of urls
    const value = url.substring(eq_idx + 1, end_idx).split('#')[0];

    //save it
    get_data[name] = value;

    //look for more data
    url = url.substring(end_idx + 1);
    eq_idx = url.indexOf('=');
  }
  //we're now out of data, so return what we found
  return get_data;
}

//store a javascript object as get data for a url
export function store_get_data(js_obj: Record<string, string | number>) {
  let get_string = '?';
  const keys = Object.keys(js_obj);
  for (let i = 0; i < keys.length; i++) {
    if (i > 0) {
      get_string += '&';
    }
    get_string += keys[i] + '=' + js_obj[keys[i]];
  }
  return get_string;
}

export function hex_string(buffer: ArrayBuffer) {
  const byteArray = new Uint8Array(buffer);

  const hexCodes = [...byteArray].map((value) => {
    const hexCode = value.toString(16);
    const paddedHexCode = hexCode.padStart(2, '0');
    return paddedHexCode;
  });

  return hexCodes.join('');
}

export function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer | string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => reader.result && resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.readAsArrayBuffer(file);
  });
}
