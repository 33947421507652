/* eslint-disable */
import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import lang_en from '../lang/en.json';
import lang_es from '../lang/es.json';

i18n
  // passes i18n down to react-i18next
  .use(initReactI18next)
  // auto-detect the language based on previous selections or browser settings
  .use(detector)
  .init({
    resources: {
      en: {
        translation: lang_en,
      },
      es: {
        translation: lang_es,
      },
    },
    //NOTE: "lng" is intentionally not set so that the language is dynamically detected where possible
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      // a valid react element - required before react 16
      defaultTransParent: 'div',

      // what to return for empty Trans
      transEmptyNodeValue: '',

      // allow <br/> and simple html elements in translations
      transSupportBasicHtmlNodes: true,

      // don't convert to <1></1> if simple react elements
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  });

export default i18n;
