//use the react-utils ajax and sigv4 libraries
import { xml_rqst_info, get_backend_host } from './libajax.js';
import { generate_sigv4_headers } from './libsigv4.js';
import { parse_cookies } from './libformat';

//use the global project configuration
import config from '../../../src/config.js';

//whether or not to output verbose (debug) messages to the console
const debug_output = false;

/*
This is a class that allows interaction with a sigv4-enabled backend server
without requiring the calling code to know about how requests are signed or formatted
*/
class aws_api_call {
  static hex_string(buffer) {
    const byteArray = new Uint8Array(buffer);

    const hexCodes = [...byteArray].map((value) => {
      const hexCode = value.toString(16);
      const paddedHexCode = hexCode.padStart(2, '0');
      return paddedHexCode;
    });

    return hexCodes.join('');
  }

  static output_error(error_msg) {
    //TODO: use react-toast so the user can see these messages without having to check the javascript console
    console.log(error_msg);
  }

  static dflt_error_callback(xhr) {
    let error_msg = 'Error ' + xhr.status + ': ';
    if (xhr.status === 401) {
      error_msg += 'Unauthorized';
    } else if (xhr.status === 403) {
      error_msg += 'Forbidden';
    } else if (xhr.status === 404) {
      error_msg += 'Not Found';
    } else if (xhr.status === 405) {
      error_msg += 'Method Not Allowed';
    } else if (xhr.status === 429) {
      error_msg += 'Too Many Requests';
    } else if (xhr.status === 500) {
      error_msg += 'Internal Server Error';
    } else if (xhr.status === 502) {
      error_msg += 'Bad Gateway';
    } else if (xhr.status === 503) {
      error_msg += 'Service Unavailable';
    } else if (xhr.status === 504) {
      error_msg += 'Gateway Timeout';
    }

    aws_api_call.output_error(error_msg);
  }

  //create a generic signed request that can be of any type
  static generic_rqst(
    path,
    method,
    have_data_callback,
    body_data = null,
    headers = {},
    error_callback = aws_api_call.dflt_error_callback,
    no_data_callback = function (xhr) {},
    progress_callback = function (e) {},
    backend_host = get_backend_host(),
    async = true,
    response_type = ''
  ) {
    let x_amz_date = new Date().toISOString();

    let aws_access_key = config.aws_access_key_id;
    let aws_secret_key = config.aws_secret_access_key;
    let aws_security_token = null;

    //if an access key and secret key are present in the cookies
    //then use those and ignore whatever's in the config file
    let cookies = parse_cookies();
    if (cookies.hasOwnProperty('aws-access-key') && cookies.hasOwnProperty('aws-secret-key')) {
      aws_access_key = cookies['aws-access-key'];
      aws_secret_key = cookies['aws-secret-key'];

      //if an access token is in the cookies then add it to the request headers
      if (cookies.hasOwnProperty('aws-security-token')) {
        headers['x-amz-security-token'] = cookies['aws-security-token'];
        aws_security_token = cookies['aws-security-token'];
      }
    }

    if (
      body_data !== null &&
      body_data !== undefined &&
      typeof body_data === 'object' &&
      body_data.constructor === Uint8Array
    ) {
      return window.crypto.subtle.digest('SHA-256', body_data).then((digestValue) => {
        let sha256sum = aws_api_call.hex_string(digestValue);
        let signed_headers = generate_sigv4_headers(
          config.aws_host,
          path,
          aws_access_key,
          config.aws_region_id,
          config.aws_service,
          aws_secret_key,
          x_amz_date,
          method,
          body_data,
          headers,
          sha256sum,
          aws_security_token
        );
        return xml_rqst_info(
          path,
          have_data_callback,
          method,
          body_data,
          signed_headers,
          error_callback,
          no_data_callback,
          progress_callback,
          backend_host,
          async,
          response_type
        );
      });
    } else {
      let signed_headers = generate_sigv4_headers(
        config.aws_host,
        path,
        aws_access_key,
        config.aws_region_id,
        config.aws_service,
        aws_secret_key,
        x_amz_date,
        method,
        body_data,
        headers,
        null,
        aws_security_token
      );

      //debug output; list the request and headers
      if (debug_output) {
        console.log(method + ' ' + path);
        console.log(signed_headers);
      }
      return xml_rqst_info(
        path,
        have_data_callback,
        method,
        body_data,
        signed_headers,
        error_callback,
        no_data_callback,
        progress_callback,
        backend_host,
        async,
        response_type
      );
    }
  }

  static get_rqst(
    path,
    have_data_callback,
    headers = {},
    error_callback = aws_api_call.dflt_error_callback,
    no_data_callback = function (xhr) {},
    progress_callback = function (e) {},
    response_type
  ) {
    return aws_api_call.generic_rqst(
      path,
      'GET',
      have_data_callback,
      null,
      headers,
      error_callback,
      no_data_callback,
      progress_callback,
      get_backend_host(),
      true,
      response_type
    );
  }

  static delete_rqst(
    path,
    have_data_callback,
    headers = {},
    error_callback = aws_api_call.dflt_error_callback,
    no_data_callback = function (xhr) {},
    progress_callback = function (e) {},
    response_type
  ) {
    return aws_api_call.generic_rqst(
      path,
      'DELETE',
      have_data_callback,
      null,
      headers,
      error_callback,
      no_data_callback,
      progress_callback,
      get_backend_host(),
      true,
      response_type
    );
  }

  static post_rqst(
    path,
    body_data,
    have_data_callback,
    headers = {},
    error_callback = aws_api_call.dflt_error_callback,
    no_data_callback = function (xhr) {},
    progress_callback = function (e) {},
    response_type
  ) {
    return aws_api_call.generic_rqst(
      path,
      'POST',
      have_data_callback,
      body_data,
      headers,
      error_callback,
      no_data_callback,
      progress_callback,
      get_backend_host(),
      true,
      response_type
    );
  }

  static put_rqst(
    path,
    body_data,
    have_data_callback,
    headers = {},
    error_callback = aws_api_call.dflt_error_callback,
    no_data_callback = function (xhr) {},
    progress_callback = function (e) {},
    response_type
  ) {
    return aws_api_call.generic_rqst(
      path,
      'PUT',
      have_data_callback,
      body_data,
      headers,
      error_callback,
      no_data_callback,
      progress_callback,
      get_backend_host(),
      true,
      response_type
    );
  }
}

export default aws_api_call;
