import { url_without_get_data, parse_get_data } from './libgetdata.js';

import * as crypto from 'crypto-js';
import { SHA256 } from 'crypto-js';

const aws_algo = 'AWS4-HMAC-SHA256';

//a comparison function for sorting data for canonical requests
function min_sort_name_cmp(a, b) {
  if (a['name'] < b['name']) {
    return -1;
  } else if (a['name'] > b['name']) {
    return 1;
  }
  return 0;
}

function amz_canonical_get_data(path) {
  let data_str = '';
  let i = 0; //loop counter

  //check the path for any GET data and include that as part of the canonical request
  //as that needs to be specially formatted for amazon
  let canonical_get_data = [];
  let get_data = parse_get_data(path);
  let get_data_keys = Object.keys(get_data);
  for (i = 0; i < get_data_keys.length; i++) {
    if (get_data.hasOwnProperty(get_data_keys[i])) {
      canonical_get_data.push({
        name: get_data_keys[i],
        value: get_data[get_data_keys[i]],
      });
    }
  }

  //sort the get data fields in ascending order
  canonical_get_data.sort(min_sort_name_cmp);

  //add the canonical form of the get data request
  if (canonical_get_data.length > 0) {
    for (i = 0; i < canonical_get_data.length; i++) {
      if (i > 0) {
        data_str += '&';
      }
      data_str += canonical_get_data[i]['name'] + '=' + canonical_get_data[i]['value'];
    }
  }
  return data_str;
}

//a helper function for amazon date formatting
export function left_pad(str, pad_char, length) {
  while (str.length < length) {
    str = pad_char + str;
  }
  return str;
}

//from https://docs.aws.amazon.com/general/latest/gr/signature-v4-examples.html#signature-v4-examples-javascript
//modified to use the current version of crypto-js, which requires .toString(crytpo.enc.Hex) to convert from a js object to a string
export function getSignatureKey(key, dateStamp, regionName, serviceName) {
  let kDate = crypto.HmacSHA256(dateStamp, 'AWS4' + key);
  let kRegion = crypto.HmacSHA256(regionName, kDate);
  let kService = crypto.HmacSHA256(serviceName, kRegion);
  let kSigning = crypto.HmacSHA256('aws4_request', kService);

  return kSigning;
}

//get the canonical form of an aws sigv4-signed request
export function canonical_request(
  request_type,
  hostname,
  path,
  x_amz_date,
  request_data,
  headers,
  sha256sum = null,
  x_amz_security_token = null
) {
  let rqst_str = request_type + '\n';

  let canonical_get_str = amz_canonical_get_data(path);

  //remove the get data from the path field
  path = url_without_get_data(path);

  //escape any space characters that have not already been escaped
  path = path.replace(/ /g, '%20');

  //double-escape % characters because aws is dumb
  path = path.replace(/%/g, '%25');

  //and put it in the canonical location
  rqst_str += path + '\n' + canonical_get_str + '\n';

  //set the required headers unconditionally, since we always need those
  let req_headers = [
    { name: 'host', value: hostname },
    { name: 'range', value: '' },
    { name: 'x-amz-date', value: x_amz_date },
  ];
  if (x_amz_security_token !== null) {
    req_headers.push({ name: 'x-amz-security-token', value: x_amz_security_token });
  }

  let canonical_headers = req_headers;

  //sort canonical headers alphabetically by key
  canonical_headers.sort(min_sort_name_cmp);

  //put the headers, listted alphabetically, in the request string
  let hdr_field_list = '';
  for (var i = 0; i < canonical_headers.length; i++) {
    rqst_str += canonical_headers[i]['name'] + ':' + canonical_headers[i]['value'] + '\n';
    if (hdr_field_list.length > 0) {
      hdr_field_list += ';';
    }
    hdr_field_list += canonical_headers[i]['name'];
  }
  rqst_str += '\n';
  rqst_str += hdr_field_list + '\n';

  if (request_data === null) {
    request_data = '';
  }

  if (sha256sum === null) {
    //for POST and PUT requests we have to sign the body content as well
    rqst_str += SHA256(request_data).toString(crypto.enc.Hex);
  } else {
    rqst_str += sha256sum;
  }

  return rqst_str;
}

//generate sigv4 headers for a request
//x_amz_date can be generated using x_amz_date=(new Date()).toISOString()
export function generate_sigv4_headers(
  hostname,
  path,
  access_key_id,
  aws_region_id,
  aws_service,
  secret_access_key,
  x_amz_date,
  request_type = 'GET',
  request_data = '',
  headers = {},
  sha256sum = null,
  x_amz_security_token = null
) {
  let date = new Date(x_amz_date);
  let aws_datestamp =
    date.getUTCFullYear() +
    '' +
    left_pad(date.getUTCMonth() + 1 + '', '0', 2) +
    left_pad(date.getUTCDate() + '', '0', 2);
  let sign_key = getSignatureKey(secret_access_key, aws_datestamp, aws_region_id, aws_service);

  let string_to_sign = aws_algo + '\n';
  //NOTE: amazon expects everything to be UTC, so make we enforce that here
  string_to_sign +=
    aws_datestamp +
    'T' +
    left_pad(date.getUTCHours() + '', '0', 2) +
    left_pad(date.getUTCMinutes() + '', '0', 2) +
    left_pad(date.getUTCSeconds() + '', '0', 2) +
    'Z\n';
  string_to_sign += aws_datestamp + '/' + aws_region_id + '/' + aws_service + '/aws4_request\n';
  string_to_sign += SHA256(
    canonical_request(
      request_type,
      hostname,
      path,
      x_amz_date,
      request_data,
      headers,
      sha256sum,
      x_amz_security_token
    )
  ).toString(crypto.enc.Hex);

  let hdr_authorization = aws_algo;
  hdr_authorization +=
    ', Credential=' +
    access_key_id +
    '/' +
    aws_datestamp +
    '/' +
    aws_region_id +
    '/' +
    aws_service +
    '/aws4_request';
  hdr_authorization += ', SignedHeaders=host;range;x-amz-date';
  if (x_amz_security_token !== null) {
    hdr_authorization += ';x-amz-security-token';
  }
  hdr_authorization +=
    ', Signature=' + crypto.HmacSHA256(string_to_sign, sign_key).toString(crypto.enc.Hex);

  let signed_headers = headers;
  signed_headers['Authorization'] = hdr_authorization;
  signed_headers['X-Amz-Date'] = x_amz_date;
  return signed_headers;
}
